import React from 'react'
import { Box, Container, useMediaQuery } from '@material-ui/core';
import lost from '../../assets/img/lost.jpeg'
import CallOptionComponent from '../../components/CallComponent';
import Footer from '../../components/Footer';
import Text from '../../components/typography/TextTypography';
import SecondaryButtonComponent from '../../components/SecondaryButtonComponent';
import { useLanguage } from '../../hooks/languageContext';
import { useTheme } from '@material-ui/styles';
import { useHistory } from 'react-router';
import NewFooter from '../../components/NewFooter';
const SomethingWentWrong = () => {
    const { langContent } = useLanguage()
    const theme = useTheme()
    const matchesXSDown = useMediaQuery(theme.breakpoints.down("xs"));
    const history = useHistory()
    return (
        <React.Fragment>
            <Container>
                <Box alignItems='center' width="100%">
                    <Box mb={3} mt={matchesXSDown ? 2 : 12}>
                        <Text variant="textlg" color="#02abd6">

                            {langContent.something_wrong_page}
                        </Text>
                        <Box textAlign='center' mt={3}>

                            <SecondaryButtonComponent color="primary" variant='contained' label={langContent.home} onClickHandler={() => history.push('/')} />
                        </Box>
                    </Box>
                    <Box >

                        <img src={lost} alt="" width="100%" />
                    </Box>
                </Box>
            </Container>
            
        </React.Fragment>
    )
}
export default SomethingWentWrong