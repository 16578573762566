import { createTheme, responsiveFontSizes, styled } from "@material-ui/core/styles";
import { compose, style, typography } from "@material-ui/system";
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { Typography } from "@material-ui/core";
const breakpoints = createBreakpoints({})
const variant = style({
    prop: "variant2",
    cssProperty: false,
    themeKey: "typography"
});
const Text = styled(Typography)(
    (
        compose(
            variant,
            typography
        )
    )
);

let theme = createTheme({
    palette: {
        primary: {
            main: "#02abd6",
        },
        secondary: {
            main: '#FF0000',
        },
        errorColor: {
            main: '#FFD600',
        },
    },
    typography: {
        fontSize: 13,
        // fontFamily: "Sans-serif",
        h1: {

            fontSize: "2rem",
        },
        h2: {

            fontSize: "1.7rem",
        },


        subtitle1: {
            color: "#868686",
            fontSize: '.8rem',
            lineHeight: '1.4'
        },
        label: {
            fontSize: "0.6rem",
        },
        subtitle2: {
            fontSize: "1rem"
        },
        button: {
            textTransform: "none",

        },
        overline: {
            fontSize: '14px',
            fontWeight: 300
        },
        h3: {
            fontSize: "1.5rem"
        },
        h4: {
            fontSize: '1.4rem'
        },
        h5: {
            fontSize: '1.2rem'
        },
        h6: {
            fontSiz: '1rem'
        }

    },
    overrides: {
        MuiFormLabel: {
            asterisk: {
                color: "#db3131",
                "&$error": {
                    color: "#db3131",
                },
            },
        },
        MuiCardContent: {
            root: {
                padding: 10,
                "&:last-child": {
                    paddingBottom: 0,
                },
            },
        },
        MuiTypography: {
            h1: {
                fontWeight: 600,
                [breakpoints.down("sm")]: {
                    fontSize: "24px",

                },
            },
            h2: {
                [breakpoints.down("sm")]: {
                    fontSize: "22px",

                },
            },
            h3: {
                [breakpoints.down("sm")]: {
                    fontSize: "20px",

                },
            },

            h4: {

                // fontSize: "1rem",
                // [breakpoints.up("md")]: {
                //     fontSize: "1.4rem"
                // },
                // [breakpoints.up("lg")]: {
                //     fontSize: "1.4rem"
                // },
                [breakpoints.down("sm")]: {
                    fontSize: "18px",

                },


            },
            h5: {

                [breakpoints.down("sm")]: {
                    fontSize: "16px",

                },


            },

            h6: {
                // fontWeight: 400,
                // fontSize: "1rem",
                // [breakpoints.down("lg")]: {
                //     fontSize: "1rem",
                // },
                // [breakpoints.down("md")]: {
                //     fontSize: "1rem"
                // },
                // [breakpoints.down("sm")]: {
                //     fontSize: "1rem"
                // },
                [breakpoints.down("xs")]: {
                    fontSize: "16px"
                },



            },

            subtitle2: {

                [breakpoints.down("md")]: {
                    fontSize: "1rem"
                },
                [breakpoints.down("sm")]: {
                    fontSize: "1rem",


                }

            },


        },



    },
});

theme = responsiveFontSizes(theme);

export default theme;
