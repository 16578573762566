/* eslint-disable no-unused-vars */


import React, { useEffect } from "react";
import { useLanguage } from "../../hooks/languageContext";
import Header from "../Header";
import Footer from "../Footer";
import ThemeLoader from "../../utils/Loader";
import SomethingWentWrong from "../../screens/error/SomethingWentWrong";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import DOBSelect from "../../components/formElements/DOBSelect";

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  optionGender,
  optionGenderVn,
  optionMarital,
  optionMaritalVn,
  yearListEn,
} from "../../static";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addForm1AValidation1, addForm1AValidation2 } from "../../utils/validation";
import { getUserData, getToken } from "../../utils/utils";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import ThemeSelectWithSelect from "../../components/formElements/ThemeSelectWithSelect";
import ThemeInput from "../../components/formElements/ThemeInput";
import ThemeInputMobile from "../../components/formElements/themeInputMobile";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import Text from '../../components/typography/TextTypography';
import { getMemberDetails, updateForm1ADetails,getForm1AUserInfo } from "../../request";
import moment from "moment";
import Stack from '@mui/material/Stack';
import NewFooter from "../NewFooter";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ListItemIcon from '@mui/material/ListItemIcon';
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { alpha, makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Grid,
  FormControl,
  Divider,
  FormHelperText,
  useTheme,
  InputLabel,
  useMediaQuery,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import Alert from '@mui/material/Alert';

function Form1a() {
  const history = useHistory();
  const theme = useTheme();
  const useStyles = makeStyles((theme) => ({
    bgNone:{
      backgroundColor:'none !important'
    }
  }));
  const [requestPending, setRequestPending] = useState(false)
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const memberID = query.get('memberID')
  const redirected = query.get('redirected')
  const { lang, langContent } = useLanguage();
  const matchesSMDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const dateObj = yearListEn(lang);
  const yearArray = dateObj.year;
  const dayArray = dateObj.day;
  const monthArray = dateObj.month;
  const memberId = query.get("member");
  const nextform = query.get("next");
  const userId = getUserData("user_id");
  const token = getToken();
  const [direct_billing_service,setDirectBillingService] = useState('no');
  const[request_for_insurance_claim, setRequestForInsuranceClaim] = useState('')
  const[request_for_converting_traditional_vat, setRequestForConvertingTraditionalVat]=useState('')
  const [request_for_additional_info_vat, setRequestForAdditionalInfoVat] = useState('')
  const[additional_information_on_vat, setadditionalInformationOnVat] = useState('')
  const[additional_information_on_vat_company_name, setadditionalInformationOnVatCompanyName] = useState('')
  const[additional_information_on_vat_address, setadditionalInformationOnVatAddress] = useState('')
  const[additional_information_on_vat_tax_code, setadditionalInformationOnVatTaxCode] = useState('')
  const [request_for_social_certificate, setRequestForSocialCertificate] = useState('')
  const [social_insurance_certificate_details, setSocialInsuranceCertificateDetails] = useState('')
  const [social_insurance_certificate_company_name, setSocialInsuranceCertificateCompanyName] = useState('')
  const [social_insurance_certificate_card_number, setSocialInsuranceCertificateCardNumber] = useState('')
  const [social_insurance_certificate_parent_name, setSocialInsuranceCertificateparentName] = useState('')
  const [receiver_name_and_address, setReceiverNameAndAddress] = useState('')
  const [place_of_receipt, setPlaceOfreceipt] = useState('')
  const [insurance_company_name, setInsuranceCompanyName] = useState('')
  const [social_security_number, setSocialSecurityNumber] = useState('')
  const classes = useStyles();
  const [dob, setDob] = useState('');
  const [dobDay, setDobDay] = useState('');
  const [dobMonth, setDobMonth] = useState('');
  const [dobYear, setDobYear] = useState('');
  const [consentDay, setConsentDay] = useState('');
  const [consentMonth, setConsentMonth] = useState('');
  const [consentYear, setConsentYear] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [patientName, setPatientName] = useState('');
  const [patientParentName, setPatientParentName] = useState('');
  const [memberEmail, setMemberEmail] = useState('');
  const [memberAddress, setMemberAddress] = useState('');
  const [zipcode, setzipcode] = useState('');
  const [marital_status, setMaritalStatus] = useState('');
  const [patientGuardianName, setPatientGuardianName] = useState('');
  const [MemberDetails,setMemberDetails] =useState([])
  const [patientHomePhoneNo,setPatientHomePhoneNo] =useState('')
  const [relationToPatient,setRelationToPatient] =useState('')
  const [subscription_email_address,setSubscriptionEmailAddress] =useState('')
  const [subscription_email_address_verify,setSubscriptionEmailAddressVerify] =useState('')
  const [patient_guardian_cell_phone,setPatientGuardianCellPhone] =useState('')
  const [consentAnsArray,setConsentAnsArray] = useState([1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1])
  const [userConsentAns,setUserConsentAns] =useState([]);
  const [tempArray,setTempArray] =useState([]);
  const [validationSchema, setValidationSchema] = useState(addForm1AValidation1);
  const [date, setDate] = useState(new Date());

  const getDay = () => {
    return date.toLocaleString('en-US', { day: '2-digit' });
  };

  const getMonth = () => {
    return date.toLocaleString('en-US', { month: '2-digit' });
  };

  const getYear = () => {
    return date.getFullYear();
  };
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const handleChange = (ev) => {
    console.log(ev.target.value);
    setDirectBillingService(ev.target.value);
  };
  const additionalInfoOnVatChanged = (ev)=>{
    console.log(ev.target.value);
    setadditionalInformationOnVat(ev.target.value)
  }

  const [consentArray,setConsentArray] = useState([
    {
      content: <ul><li>{langContent.contact_to_confirm_request}</li></ul>,
      isChecked:true,
    },
    {
      content:<ul> <li>{langContent.provide_service_information_upon}</li></ul> ,
      isChecked:true,
    },
    {
      content:<ul><li>{langContent.register_for_my_patient}</li></ul> ,
      isChecked:true,
    },
    {
      content:<ul><li>{langContent.contact_for_other_notifications}</li></ul> ,
      isChecked:true,
    },
    {
      content: <ul><li>{langContent.fulfil_obligations_under}</li></ul>  ,
      isChecked:true,
    },
    {
      content: <ul><li>{langContent.confirm_transactions_and}</li></ul> ,
      isChecked:true,
    },
    {
      content: <ul><li>{langContent.protect_and_enforce}</li></ul> ,
      isChecked:true,
    },
    {
      content: <ul><li>{langContent.answer_my_questions_and_comments}</li></ul> ,
      isChecked:true,
    },
    {
      content: <ul><li>{langContent.analyze_my_medical_needs}</li></ul> ,
      isChecked:true,
    },
    {
      content: <ul><li>{langContent.contact_me_to_support_and_resolve}</li></ul> ,
      isChecked:true,
    },
    {
      content: <ul><li>{langContent.send_information_and_notifiaction}</li></ul> ,
      isChecked:true,
    },
    {
      content: <ul><li>{langContent.detect_investigate_and_prevent}</li></ul> ,
      isChecked:true,
    },
    {
      content: <ul><li>{langContent.send_service_updates}</li> </ul> ,
      isChecked:true,
    },
    {
      content: <ul><li>{langContent.for_the_clinic_to_comply}</li> </ul> ,
      isChecked:true,
    },
    {
      content:"2. "+langContent.at_the_same_time,
      isChecked:true,
    },
    {
      content: "3. "+langContent.i_assure_all_info,
      isChecked:true,
    }
  ]);
  const onSubmit = (data) => {
    setIsLoading(true);
    const memberDob =
      data.member_dob_year +
      "/" +
      data.member_dob_month +
      "/" +
      data.member_dob_day;
    const consent_date =
      data.member_consent_year +
      "/" +
      data.member_consent_month +
      "/" +
      data.member_consent_day;
    data.country_code = "+84";
    const fd = new FormData();

    fd.append("member_id", memberID);
    fd.append("patient_name", data.patient_name);
    fd.append("dob", memberDob ??  '');
    fd.append("age", data.patient_age ??  '');
    fd.append("gender", data.member_gender ??  '');
    fd.append("email", data.member_email ??  '');
    fd.append("marital_status", data.member_married_status ??  '');
    fd.append("patient_cell_phone_no", data.patient_cell_phone_no ??  '');
    fd.append("patient_home_phone_no", data.patient_home_phone_no ??  '');
    fd.append("home_address", data.home_address ??  '');
    fd.append("zip_code", data.zip_code ??  '');
    fd.append("patient_guardian_name", data.name_of_patient_guardian ??  '');
    fd.append("relation_to_patient", data.relation_to_patient ??  '');
    fd.append("patient_guardian_cell_phone", data.patient_guardian_cell_phone_no ??  '');
    fd.append("subscription_email", data.subscription_email_address ??  '');
    fd.append("direct_billing_service", direct_billing_service ??  'no');
    fd.append("insurance_company_name", data.insurance_company_name ?? '');
    fd.append("req_ins_claim_doc", request_for_insurance_claim ?? '');
    fd.append("req_con_trad_vat_inv", request_for_converting_traditional_vat ??  '');
    fd.append("req_additional_info_inv", request_for_additional_info_vat ?? '');
    fd.append("additional_info_vat_inv", data.additional_information_on_vat ?? '');
    fd.append("additional_information_on_vat_company_name", additional_information_on_vat_company_name ?? '');
    fd.append("additional_information_on_vat_address", additional_information_on_vat_address ?? '');
    fd.append("additional_information_on_vat_tax_code", additional_information_on_vat_tax_code ?? '');
    fd.append("req_social_ins_certificate", request_for_social_certificate ?? '');
    fd.append("social_ins_certificate_details", data.social_insurance_certificate_details ?? '');
    fd.append("consent_patient_name", data.consent_patient_name ?? '');
    fd.append("consent_date", consent_date ?? '');
    fd.append("consent_array",consentAnsArray ?? '');
    fd.append("place_of_receipt",place_of_receipt ?? '');
    fd.append("receiver_name_address",receiver_name_and_address ??  '');
    fd.append("social_insurance_certificate_parent_name",social_insurance_certificate_parent_name ??  '');
    fd.append("social_insurance_certificate_company_name",social_insurance_certificate_company_name ??  '');
    fd.append("social_insurance_certificate_card_number",social_insurance_certificate_card_number ??  '');
    fd.append("social_security_number",social_security_number ??  '');
    fd.append("patient_parent_sign_name",patientParentName ??  '');
    fd.append("created_date", moment().format("YYYY-MM-DD") ??  '');
    updateForm1ADetails(fd, token, setIsLoading, langContent, history, setRequestPending,memberID,redirected,nextform)

  };

  const skipForm1a = (ev) => {
    if(redirected=='yes'){
      history.push('/cart');
    }else{
      history.push('/my_forms');
    }
  };
  
    useEffect(()=>{
      // let values =[...consentArray];
      // setConsentAnsArray(values)
      let values =  [
        {
          content: <ul><li>{langContent.contact_to_confirm_request}</li></ul>,
          isChecked:true,
        },
        {
          content:<ul> <li>{langContent.provide_service_information_upon}</li></ul> ,
          isChecked:true,
        },
        {
          content:<ul><li>{langContent.register_for_my_patient}</li></ul> ,
          isChecked:true,
        },
        {
          content:<ul><li>{langContent.contact_for_other_notifications}</li></ul> ,
          isChecked:true,
        },
        {
          content: <ul><li>{langContent.fulfil_obligations_under}</li></ul>  ,
          isChecked:true,
        },
        {
          content: <ul><li>{langContent.confirm_transactions_and}</li></ul> ,
          isChecked:true,
        },
        {
          content: <ul><li>{langContent.protect_and_enforce}</li></ul> ,
          isChecked:true,
        },
        {
          content: <ul><li>{langContent.answer_my_questions_and_comments}</li></ul> ,
          isChecked:true,
        },
        {
          content: <ul><li>{langContent.analyze_my_medical_needs}</li></ul> ,
          isChecked:true,
        },
        {
          content: <ul><li>{langContent.contact_me_to_support_and_resolve}</li></ul> ,
          isChecked:true,
        },
        {
          content: <ul><li>{langContent.send_information_and_notifiaction}</li></ul> ,
          isChecked:true,
        },
        {
          content: <ul><li>{langContent.detect_investigate_and_prevent}</li></ul> ,
          isChecked:true,
        },
        {
          content: <ul><li>{langContent.send_service_updates}</li> </ul> ,
          isChecked:true,
        },
        {
          content: <ul><li>{langContent.for_the_clinic_to_comply}</li> </ul> ,
          isChecked:true,
        },
        {
          content:"2. "+langContent.at_the_same_time,
          isChecked:true,
        },
        {
          content: "3. "+langContent.i_assure_all_info,
          isChecked:true,
        }
        
      ];
      setConsentArray(values)
      getForm1AUserInfo(userId, lang, memberID, token, setMemberDetails, setIsLoading, setResponseError)
    },[lang]);


    useEffect(()=>{
      setDob(MemberDetails?.member_dob)
      setDobDay(MemberDetails?.member_dob_day)
      setDobMonth(MemberDetails?.member_dob_month)
      setDobYear(MemberDetails?.member_dob_year)
      setGender(MemberDetails?.member_gender)
      setPatientName(MemberDetails?.member_fname)
      setPatientParentName(MemberDetails?.patient_parent_sign_name)
      setMemberEmail(MemberDetails?.member_email)
      setMaritalStatus(MemberDetails?.member_married_status)
      setMemberAddress(MemberDetails?.member_address1)
      setzipcode(MemberDetails?.zip_code)
      setPatientGuardianName(MemberDetails?.patient_guardian_name)
      setPatientHomePhoneNo(MemberDetails?.patient_home_phone_no)
      setRelationToPatient(MemberDetails?.relation_to_patient)
      setDirectBillingService(MemberDetails?.direct_billing_service ?? 'no')
      setRequestForInsuranceClaim(MemberDetails?.req_ins_claim_doc)
      setRequestForConvertingTraditionalVat(MemberDetails?.req_con_trad_vat_inv)
      setadditionalInformationOnVat(MemberDetails?.additional_info_vat_inv)
      setadditionalInformationOnVatCompanyName(MemberDetails?.additional_information_on_vat_company_name)
      setadditionalInformationOnVatAddress(MemberDetails?.additional_information_on_vat_address)
      setadditionalInformationOnVatTaxCode(MemberDetails?.additional_information_on_vat_tax_code)
      setRequestForSocialCertificate(MemberDetails?.req_social_ins_certificate)
      setInsuranceCompanyName(MemberDetails?.insurance_company_name)
      setSubscriptionEmailAddress(MemberDetails?.subscription_email)
      setSubscriptionEmailAddressVerify(MemberDetails?.subscription_email)
      setPatientGuardianCellPhone(MemberDetails?.patient_guardian_cell_phone)
      setPlaceOfreceipt(MemberDetails?.place_of_receipt)
      setReceiverNameAndAddress(MemberDetails?.receiver_name_address);
      setSocialInsuranceCertificateCompanyName(MemberDetails?.social_insurance_certificate_company_name);
      setSocialInsuranceCertificateCardNumber(MemberDetails?.social_insurance_certificate_card_number);
      setSocialInsuranceCertificateparentName(MemberDetails?.social_insurance_certificate_parent_name);
      setSocialSecurityNumber(MemberDetails?.social_security_number);
      setRequestForAdditionalInfoVat(MemberDetails?.req_additional_info_inv)
      setConsentDay(MemberDetails?.consent_day ?? getDay());
      setConsentMonth(MemberDetails?.consent_month ?? getMonth());
      setConsentYear(MemberDetails?.consent_year ?? getYear());

      // const temp = [...MemberDetails?.consent_array]
      setTempArray(MemberDetails?.consent_array);
      // setUserConsentAns(MemberDetails?.consent_array);

    },[MemberDetails]);

    useEffect(()=>{
      calculateAge()
    },[dobDay,dobMonth,dobYear])

    // useEffect(()=>{
    //   tempArray?.length && console.log(tempArray);
    //   if(tempArray?.length){
        
    //       let values =[...tempArray].map(Number);

    //       setConsentAnsArray(values)
    //   }
    // },[tempArray])

    const calculateAge = () => {
      let udobDate = (dobYear+'-'+dobMonth+'-'+dobDay).toString();
      const dobDate = new Date(udobDate);
      const today = new Date();
        const birthDateObj = new Date(dobDate);
        let age = today.getFullYear() - birthDateObj.getFullYear();
        const monthDiff = today.getMonth() - birthDateObj.getMonth();

        // If birth month is after current month or birth day is after current day, subtract 1 from age
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
          age--;
        }

        // return age;
      if(age>15){
        setValidationSchema(addForm1AValidation1);
      }else{
        setValidationSchema(addForm1AValidation2)
      }
      setAge(age);
    };

    const handleContentArray = (e,index) =>{
      console.log(e.target.checked);
      console.log(index);
      if(e.target.checked ==true){
        consentAnsArray[index] =1;
      }else{
        consentAnsArray[index]=0;
      }
      console.log(consentAnsArray);
    }

    const handleCheckboxChange=(e,index)=>{
      const values = [...consentArray];
      values[index].isChecked = e.target.checked;
      setConsentArray(values);
      if(e.target.checked ==true){
        const temp = [...consentAnsArray]
        temp[index] =1;
        setConsentAnsArray(temp)
      }else{
        const temp = [...consentAnsArray]
        temp[index] =0;
        setConsentAnsArray(temp)
      }
    }

    useEffect(()=>{
      if(consentAnsArray?.length){
        consentArray.forEach((item,index)=>{
          if(consentAnsArray[index]==1){
            item.isChecked = true
          }else{
            item.isChecked=false;
          }
        });
        const values = [...consentArray];
        setConsentArray(values)
      }
    },[consentAnsArray])

    useEffect(()=>{
      if(userConsentAns?.length){
        consentArray.forEach((item,index)=>{
          if(userConsentAns[index]==1){
            item.isChecked = true
          }else{
            item.isChecked=false;
          }
        })
      }

    },[userConsentAns])

    const userConsentCheck =(index)=>{
      if(userConsentAns?.length && userConsentAns?.[index]=='1')
      return true;
    }

  return (
    <>
      <React.Fragment>
        <Header shouldHaveBackButton={true} shouldHaveSearchBar={false} />
        {isLoading ? (
          <ThemeLoader />
        ) : responseError === true ? (
          <SomethingWentWrong />
        ) : (
          <Container>
            <Box textAlign="center" mt={6}>
             <Typography variant="h2" component="h2">
                {langContent.form1a}
              </Typography>
            </Box>
            <Box>
              <Grid container>
                <Grid item md={12} sm={12} xs={12}>
                <Button variant="contained" color="primary" onClick={skipForm1a} style={{ color: "white",width:'95px',marginBottom:'1rem' }} >
                  {langContent.skip}
                </Button>
                <Alert style={{backgroundColor: 'rgb(229, 246, 253)',}}  severity="info">{langContent.if_you_skip}</Alert>
                </Grid>
              </Grid>
            </Box>
            <Box
              pt={matchesSMDown ? 2 : 4.7}
            >
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Accordion expanded={true}>
                  <AccordionSummary
                    expandIcon={""}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{padding:'0 16px'}}
                  >
                    <Typography variant="h4">
                      I. {langContent.patient_inforamtion}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{padding:'15px'}}>
                    <ol style={{ fontSize: "15px", paddingLeft: "13px",fontWeight:'bold' }}>
                      <li>{langContent.your_provided_inforamtion}</li>
                      <li>{langContent.fill_in_capital_letters}</li>
                    </ol>
                    <Box>
                      <Grid container>
                        <Grid item md={12} sm={12} xs={12}>
                        <Grid item md={6} style={{ marginTop: '.5rem', marginBottom: '.3rem' }}>
                            <InputLabel htmlFor="patient_name">{langContent.patient_name}</InputLabel>
                        </Grid>
                        <FormControl fullWidth style={{ marginTop: '.5rem', marginBottom: '.3rem' }}>
                            <ThemeInput onChange={e => setPatientName(e.target.value)} size="small" type="text" value={patientName ?? ""} name='patient_name' id="patient_name" register={register} errors={errors} fullWidth />
                        </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Grid container spacing={matchesSMDown ? 3 : 1}>
                        <Grid item  md={6} sm={12} xs={12}>
                          <Grid
                            item
                            md={6}
                            sm={12}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel htmlFor="user_dob">
                              {langContent.date_of_birth}{""}
                              <span style={{ color: "tomato" }}>*</span>
                            </InputLabel>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item md={4} sm={4} xs={4}>
                              <FormControl fullWidth>
                                <DOBSelect
                                  size="small"
                                  name="member_dob_day"
                                  label={langContent.day}
                                  register={register}
                                  errors={errors}
                                  valueKey="key"
                                  fullWidth
                                  optionObj={dayArray}
                                  labelKey="label"
                                  selectLabel="select"
                                  value={dobDay ?? ""}
                                  onChange={e=>{setDobDay(e.target.value)}}

                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={4} sm={4} xs={4}>
                              <FormControl fullWidth>
                                <ThemeSelectWithSelect
                                  size="small"
                                  name="member_dob_month"
                                  label={langContent.month}
                                  register={register}
                                  errors={errors}
                                  valueKey="key"
                                  fullWidth
                                  optionObj={monthArray}
                                  labelKey="label"
                                  value={dobMonth ?? ""}
                                  onChange={e=>{setDobMonth(e.target.value)}}

                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={4} sm={4} xs={4}>
                              <FormControl fullWidth>
                                <ThemeSelectWithSelect
                                  size="small"
                                  name="member_dob_year"
                                  label={langContent.year}
                                  register={register}
                                  errors={errors}
                                  valueKey="key"
                                  fullWidth
                                  optionObj={yearArray}
                                  labelKey="label"
                                  value={dobYear ?? ""}
                                  onChange={e=>{setDobYear(e.target.value)}}

                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item sm={3} md={3} xs={12}>
                          <Grid
                            item
                            md={6}
                            sm={12}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel htmlFor="patient_age">
                              {langContent.age}{""}
                              <span style={{ color: "tomato" }}>*</span>
                            </InputLabel>
                          </Grid>
                          <FormControl fullWidth>
                            <ThemeInput
                              size="small"
                              id="patient_age"
                              name="patient_age"
                              register={register}
                              errors={errors}
                              fullWidth
                              isRequired={true}
                              value={age ?? ""}
                              InputProps={{
                                readOnly: true,
                              }}
                              onChange ={(e)=>{setAge(e.target.value)}}
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          md={3}
                          sm={12}
                          xs={12}
                          style={{ marginTop: ".6rem", marginBottom: ".3rem" }}
                        >
                          <Grid item md={6} sm={12}>
                            <InputLabel htmlFor="member_gender">
                              {langContent.gender}{""}
                              <span style={{ color: "tomato" }}>*</span>
                            </InputLabel>
                          </Grid>
                          <FormControl fullWidth>
                            <ThemeSelectWithSelect
                              name="member_gender"
                              id="member_gender"
                              register={register}
                              size="small"
                              errors={errors}
                              fullWidth
                              optionObj={
                                lang === "en" ? optionGender : optionGenderVn
                              }
                              value={gender ?? ""}
                              onChange={e=>{setGender(e.target.value)}}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Grid container spacing={matchesSMDown ? 3 : 1} >
                        <Grid item md={6} sm={12} xs={12}>
                          <Grid
                            item
                            md={6}
                            sm={12}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel htmlFor="social_security_number">
                              {langContent.social_security_number}
                              <span style={{ color: "tomato" }}>*</span>
                            </InputLabel>
                          </Grid>
                          <FormControl fullWidth>
                            <ThemeInput
                              size="small"
                              name="social_security_number"
                              id="social_security_number"
                              value={social_security_number ?? ""}
                              register={register}
                              errors={errors}
                              fullWidth
                              isRequired={true}
                              onChange={(e)=>{setSocialSecurityNumber(e.target.value)}}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Grid
                            item
                            md={12}
                            sm={12}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel htmlFor="member_married_status">
                              {langContent.marital_status}{""}<span style={{ color: "tomato" }}>*</span>
                            </InputLabel>
                          </Grid>
                          <FormControl fullWidth>
                            <ThemeSelectWithSelect
                              name="member_married_status"
                              id="member_married_status"
                              size="small"
                              register={register}
                              errors={errors}
                              fullWidth
                              optionObj={
                                lang === "en" ? optionMarital : optionMaritalVn
                              }
                              value={marital_status ?? ""}
                              onChange={e=>{setMaritalStatus(e.target.value)}}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Grid container spacing={matchesSMDown ? 3 : 1}>
                        <Grid item md={6} sm={12} xs={12}>
                          <Grid
                            item
                            md={6}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel htmlFor="patient_cell_phone_no">
                              {langContent.patient_Cell_phone_no ?? ""} 
                              <span style={{ color: "tomato" }}>*</span>
                            </InputLabel>
                          </Grid>
                          <FormControl fullWidth>
                            <ThemeInputMobile
                              name="patient_cell_phone_no"
                              id="patient_cell_phone_no"
                              register={register}
                              size="small"
                              value={MemberDetails?.member_mobile ?? ""}
                              errors={errors}
                              fullWidth
                              maxLength ={9}
                              minLength ={9}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Grid
                            item
                            md={6}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel htmlFor="patient_home_phone_no">
                              {langContent.patient_home_phone_no}
                            </InputLabel>
                          </Grid>
                          <FormControl fullWidth>
                            <ThemeInputMobile
                              name="patient_home_phone_no"
                              id="patient_home_phone_no"
                              register={register}
                              size="small"
                              value={patientHomePhoneNo ?? ""}
                              errors={errors}
                              onChange = {(e)=>{setPatientHomePhoneNo(e.target.value)}}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Grid container spacing={matchesSMDown ? 3 : 1}>
                        <Grid item sm={12} md={9} xs={12}>
                          <Grid
                            item
                            md={6}
                            sm={12}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel htmlFor="home_address">
                              {langContent.home_address}
                              <span style={{ color: "tomato" }}>*</span>
                            </InputLabel>
                          </Grid>
                          <FormControl fullWidth>
                            <ThemeInput
                              name="home_address"
                              id="home_address"
                              register={register}
                              size="small"
                              errors={errors}
                              fullWidth
                              value={memberAddress ?? ""}
                              onChange ={(e)=>{setMemberAddress(e.target.value)}}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={3} md={3} xs={12}>
                          <Grid
                            item
                            md={6}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel htmlFor="zip_code">
                              {langContent.zip_code}
                            </InputLabel>
                          </Grid>
                          <FormControl fullWidth>
                            <ThemeInput
                              name="zip_code"
                              id="zip_code"
                              register={register}
                              size="small"
                              errors={errors}
                              fullWidth
                              value={zipcode ?? ""}
                              onChange ={(e)=>{setzipcode(e.target.value)}}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Grid container spacing={matchesSMDown ? 3 : 1}>
                        <Grid item sm={12}>
                          <Grid
                            item
                            md={12}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel htmlFor="name_of_patient_guardian">
                              {langContent.name_of_patient_guardian}
                            </InputLabel>
                          </Grid>
                          <FormControl fullWidth>
                            <ThemeInput
                              name="name_of_patient_guardian"
                              id="name_of_patient_guardian"
                              register={register}
                              size="small"
                              errors={errors}
                              fullWidth
                              value={patientGuardianName ?? ""}
                              onChange={(e)=>{setPatientGuardianName(e.target.value)}}
                            />
                          </FormControl>
                        </Grid>
                      
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Grid container spacing={matchesSMDown ? 3 : 1}>
                        <Grid item md={6} sm={12} xs={12}>
                          <Grid
                            item
                            md={6}
                            sm={12}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel htmlFor="patient_guardian_cell_phone_no">
                              {langContent.patient_guardian_cell_phone_no}
                            </InputLabel>
                          </Grid>
                          <FormControl fullWidth>
                            <ThemeInputMobile
                              name="patient_guardian_cell_phone_no"
                              id="patient_guardian_cell_phone_no"
                              register={register}
                              size="small"
                              errors={errors}
                              value={patient_guardian_cell_phone ?? ""}
                              onChange={e=>{setPatientGuardianCellPhone(e.target.value)}}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Grid
                            item
                            md={6}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel htmlFor="relation_to_patient">
                              {langContent.relation_to_patient}
                            </InputLabel>
                          </Grid>
                          <FormControl fullWidth>
                            <ThemeInput
                              name="relation_to_patient"
                              id="relation_to_patient"
                              register={register}
                              size="small"
                              errors={errors}
                              fullWidth
                              value={relationToPatient ?? ""}
                              onChange={(e)=>{setRelationToPatient(e.target.value)}}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Typography variant="body2" gutterBottom>
                        <CheckRoundedIcon style={{ marginBottom: "-7px" }} />
                        {langContent.subscrie_your_private_email_account}
                      </Typography>
                      <Grid container spacing={matchesSMDown ? 3 : 1}>
                        <Grid item md={6} sm={12} xs={12}>
                          <Grid
                            item
                            md={6}
                            sm={12}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel htmlFor="subscription_email_address">
                              {langContent.email_address}
                            </InputLabel>
                          </Grid>
                          <FormControl fullWidth>
                            <ThemeInput
                              size="small"
                              name="subscription_email_address"
                              id="subscription_email_address"
                              register={register}
                              errors={errors}
                              fullWidth
                              value={subscription_email_address ?? ""}
                              onChange ={(e)=>setSubscriptionEmailAddress(e.target.value)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Grid
                            item
                            md={6}
                            style={{
                              marginTop: ".5rem",
                              marginBottom: ".3rem",
                            }}
                          >
                            <InputLabel htmlFor="subscription_email_address">
                              {langContent.rewrite_email_address}
                            </InputLabel>
                          </Grid>
                          <FormControl fullWidth>
                            <ThemeInput
                              size="small"
                              name="subscription_email_address_verify"
                              id="subscription_email_address_verify"
                              register={register}
                              errors={errors}
                              fullWidth
                              isRequired={true}
                              value={subscription_email_address_verify ?? ""}
                              onChange ={(e)=>setSubscriptionEmailAddressVerify(e.target.value)}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </AccordionDetails>
                </Accordion>

                {/* Second Part */}
                <Accordion style={{ marginTop: "1rem",padding:  matchesSMDown ? 0 :'15px' }} expanded={true}>
                  <AccordionSummary
                    expandIcon={""}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h4" style={{fontWeight:500}}>
                      II. {langContent.invoice_and_insurance_info}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box>
                      <Grid container>
                        <Grid item sm={9} md={9} xs={12}>
                          <Typography variant="body1" gutterBottom>
                            <TaskAltIcon
                              style={{
                                marginBottom: "-7px",
                                paddingRight: "10px",
                              }}
                            />
                            {langContent.use_direct_billing}
                          </Typography>
                        </Grid>
                        <Grid item sm={3} md={3} xs={12}>
                        <FormControl component="fieldset" >
                          <RadioGroup
                              row
                              aria-labelledby="demo-radio-buttons-group-label"
                              value={direct_billing_service ?? ''}
                              name="direct_billing_service"
                              onChange={e=>{setDirectBillingService(e.target.value)}}
                              style={{paddingLeft:matchesSMDown ? '1.9rem':'0px'}}
                            >
                            <FormControlLabel
                              value="yes"
                              control={<Radio size="small" />}
                              label={langContent.yes}
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio  size="small" />}
                              label={langContent.no}
                            />
                          </RadioGroup>
                        </FormControl>
                        {errors[direct_billing_service] ? <div style={{ display: 'flex', justifyContent: 'flex-start', color: 'tomato', marginTop: '.5rem', textTransform: 'capitalize' }}>
                          {/* <small>{errors[name]?.message}</small> */}
                          <FormHelperText style={{ color: 'tomato' }}>{errors[direct_billing_service]?.message}</FormHelperText>
                      </div> : ''}
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Grid container>
                        <Grid item sm={7} xs={12} md={7}>
                          <Typography variant="body1" gutterBottom>
                            {/* <TaskAltIcon
                              style={{
                                marginBottom: "-7px",
                                paddingRight: "10px",
                              }}
                            /> */}
                            <span style={{paddingLeft:'2rem'}}>{langContent.indicate_insurance_company}</span>
                          </Typography>
                        </Grid>
                        <Grid item sm={5} xs={12} md={5} style={{marginTop:matchesSMDown ? '1rem':0}}>
                          <FormControl fullWidth>
                            <ThemeInput
                              size="small"
                              name="insurance_company_name"
                              id="insurance_company_name"
                              register={register}
                              errors={errors}
                              fullWidth
                              value={insurance_company_name ?? ""}
                              onChange={(e)=>{setInsuranceCompanyName(e.target.value)}}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Grid container>
                        <Grid item sm={9}>
                          <Typography variant="body1" gutterBottom>
                            <TaskAltIcon
                              style={{
                                marginBottom: "-7px",
                                paddingRight: "10px",
                              }}
                            />
                            {langContent.request_for_insurance_claim}
                          </Typography>
                        </Grid>
                        <Grid item sm={3}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="request_for_insurance_claim"
                            value={request_for_insurance_claim ?? ""}
                            onChange={ev=> setRequestForInsuranceClaim(ev.target.value)}
                            style={{paddingLeft:matchesSMDown ? '1.9rem':'0px'}}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio size="small" />}
                              label={langContent.yes}
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio size="small" />}
                              label={langContent.no}
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Grid container>
                        <Grid item sm={9}>
                          <Typography variant="body1" gutterBottom>
                            <TaskAltIcon
                              style={{
                                marginBottom: "-7px",
                                paddingRight: "10px",
                              }}
                            />
                            {langContent.request_for_converting_traditional_vat}
                          </Typography>
                        </Grid>
                        <Grid item sm={3}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="request_for_converting_traditional_vat"
                            value={request_for_converting_traditional_vat ?? ""}
                            onChange={ev=> setRequestForConvertingTraditionalVat(ev.target.value)}
                            style={{paddingLeft:matchesSMDown ? '1.9rem':'0px'}}

                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio size="small" />}
                              label={langContent.yes}
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio size="small" />}
                              label={langContent.no}
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Grid container>
                        <Grid item sm={9} md={9} xs={12}>
                          <Typography variant="body1" gutterBottom>
                            <TaskAltIcon
                              style={{
                                marginBottom: "-7px",
                                paddingRight: "10px",
                              }}
                            />
                            {langContent.request_for_additional_info_vat}
                          </Typography>
                        </Grid>
                        <Grid item sm={3} md={3} xs={12}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="req_additional_info_inv"
                            value={request_for_additional_info_vat ?? ""}
                            onChange={ev=>setRequestForAdditionalInfoVat(ev.target.value)}
                            style={{paddingLeft:matchesSMDown ? '1.9rem':'0px'}}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio size="small" />}
                              label={langContent.yes}
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio size="small" />}
                              label={langContent.no}
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item sm={12} md={6} xs={12} style={{padding:'10px'}}>
                          <FormControl fullWidth>
                            <TextField
                              size="small"
                              variant="outlined"
                              id="additional_information_on_vat_company_name"
                              label={langContent.company_name}
                              // multiline
                              // maxRows={1}
                              inputRef={register}
                              name="additional_information_on_vat_company_name"
                              value={additional_information_on_vat_company_name ?? ""}
                              onChange={e=>{setadditionalInformationOnVatCompanyName(e.target.value)}}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} md={6} xs={12} style={{padding:'10px'}}>
                          <FormControl fullWidth>
                            <TextField
                              size="small"
                              id="additional_information_on_vat_address"
                              label={langContent.company_address}
                              // multiline
                              // maxRows={1}
                              inputRef={register}
                              name="additional_information_on_vat_address"
                              value={additional_information_on_vat_address ?? ""}
                              onChange={e=>{setadditionalInformationOnVatAddress(e.target.value)}}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} md={6} xs={12} style={{padding:'10px'}}>
                          <FormControl fullWidth>
                            <TextField
                              size="small"
                              id="additional_information_on_vat_tax_code"
                              label={langContent.tax_code}
                              // multiline
                              // maxRows={1}
                              inputRef={register}
                              name="additional_information_on_vat_tax_code"
                              value={additional_information_on_vat_tax_code ?? ""}
                              onChange={e=>{setadditionalInformationOnVatTaxCode(e.target.value)}}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Grid container>
                        <Grid item sm={9} md={9} xs={12}>
                          <Typography variant="body1" gutterBottom>
                            <TaskAltIcon
                              style={{
                                marginBottom: "-7px",
                                paddingRight: "10px",
                              }}
                            />{""}
                            {langContent.request_for_social_certificate}
                          </Typography>
                        </Grid>
                        <Grid item sm={3} md={3} xs={12}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="''"
                            name="request_for_social_certificate"
                            value={request_for_social_certificate ?? ""}
                            onChange={event =>{setRequestForSocialCertificate(event.target.value)}}
                            style={{paddingLeft:matchesSMDown ? '1.9rem':'0px'}}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio size="small" />}
                              label={langContent.yes}
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio size="small"/>}
                              label={langContent.no}
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item sm={12} md={6} xs={12} style={{padding:'10px'}}>
                          <FormControl fullWidth>
                            <TextField
                              size="small"
                              id="social_insurance_certificate_company_name"
                              label={langContent?.company_name}
                              multiline
                              inputRef={register}
                              maxRows={4}
                              name="social_insurance_certificate_company_name"
                              value={social_insurance_certificate_company_name ?? ""}
                              onChange={event=>setSocialInsuranceCertificateCompanyName(event.target.value)}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} md={6} xs={12} style={{padding:'10px'}}>
                          <FormControl fullWidth>
                            <TextField
                              size="small"
                              id="social_insurance_certificate_card_number"
                              label={langContent?.social_insurance_card_number}
                              multiline
                              inputRef={register}
                              maxRows={4}
                              name="social_insurance_certificate_card_number"
                              value={social_insurance_certificate_card_number ?? ""}
                              onChange={event=>setSocialInsuranceCertificateCardNumber(event.target.value)}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={12} md={6} xs={12} style={{padding:'10px'}}>
                          <FormControl fullWidth>
                            <TextField
                              size="small"
                              id="social_insurance_certificate_parents_name"
                              label={langContent.parent_name_if_patient_under_7}
                              multiline
                              inputRef={register}
                              maxRows={4}
                              name="social_insurance_certificate_parents_name"
                              value={social_insurance_certificate_parent_name ?? ""}
                              onChange={event=>setSocialInsuranceCertificateparentName(event.target.value)}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={4}>
                      <Grid container>
                            <Grid item md={12} sm={12}>
                              <h4>{langContent?.place_of_receipt}</h4>
                            </Grid>
                            <Grid container>
                              <Grid item md={12}>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue="''"
                                  name="place_of_receipt"
                                  value={place_of_receipt ?? ""}
                                  onChange={event =>{setPlaceOfreceipt(event.target.value)}}
                                >
                                  <FormControlLabel
                                    value="home_address"
                                    control={<Radio size="small" />}
                                    label={langContent.my_home_address}
                                  />
                                  <FormControlLabel
                                    value="clinic_pickup"
                                    control={<Radio size="small"/>}
                                    label={langContent.pickup_at_the_clinic}
                                  />
                                  <FormControlLabel
                                    value="company"
                                    control={<Radio size="small"/>}
                                    label={langContent.my_company}
                                  />
                                </RadioGroup>
                              </Grid>
                              
                            </Grid>
                            <Grid item md={12} sm={12}>
                              <h5>{langContent.note_below_full_name}</h5>
                            </Grid>
                            <Grid item sm={12} md={12} xs={12}>
                          <FormControl fullWidth>
                            <TextField
                              size="small"
                              id="receiver_name_and_address"
                              label={langContent.receiver_name__and_address}
                              multiline
                              inputRef={register}
                              maxRows={4}
                              name="receiver_name_and_address"
                              value={receiver_name_and_address ?? ""}
                              onChange={event=>setReceiverNameAndAddress(event.target.value)}
                              InputLabelProps={{ shrink: true }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Box mt={2}>
                  <Accordion expanded={true} style={{padding:matchesSMDown ? 0 : '15px'}}>
                    <AccordionSummary
                      expandIcon={""}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h4">
                        III. {langContent.consent}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails >
                        <Container>
                          <BrowserView>
                            <Grid item md={12} xs={12}>
                              <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell style={{width:'85%',textAlign:'center'}}> <h3>{langContent.Contents}</h3> </TableCell>
                                      <TableCell align="center">{langContent.check_the_box_if_agree}</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                  <TableRow key={'11'}>
                                        <TableCell component="th" scope="row">
                                          {"1. "+langContent.i_here_by_agree_to_permit}
                                        </TableCell>
                                        <TableCell align="center">
                                        </TableCell>
                                      </TableRow>
                                  {consentArray.map((row,index) => (
                                    <TableRow
                                        key={index}
                                      >
                                        <TableCell component="th" scope="row">
                                          {row.content}
                                        </TableCell>
                                        <TableCell align="center">
                                          <Checkbox key={index} value={row.isChecked} checked={row.isChecked} onChange={e=>handleCheckboxChange(e,index)}   />
                                        </TableCell>
                                      </TableRow>
                                  ))}

                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </BrowserView>
                         <MobileView>
                            <Grid item md={12} xs={12}>
                              <List component="nav" dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                  <ListItem
                                      disablePadding
                                      disableGutters={true}
                                    >
                                      <ListItemButton>
                                        <ListItemText  primary={ "1. "+langContent.i_here_by_agree_to_permit} />
                                      </ListItemButton>
                                    </ListItem>
                                {consentArray.map((row,index) => {
                                  const labelId = `checkbox-list-secondary-label-${index}`;
                                  return (
                                    <ListItem
                                      key={index}
                                      secondaryAction={
                                        <Checkbox key={index} value={row.isChecked} checked={row.isChecked} onChange={e=>handleCheckboxChange(e,index)}   />

                                      }
                                      disablePadding
                                      disableGutters={true}
                                    >
                                      <ListItemButton>
                                        <ListItemText id={labelId} primary={row.content} />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })}
                              </List>
                            </Grid>
                         </MobileView>
                         <Box mt={3}>
                          <FormGroup>
                            <FormControlLabel control={<Checkbox defaultChecked />} label={langContent.i_have_read_and_agree_to_entire_doc} />
                          </FormGroup>
                         </Box>
                        </Container>

                      <Box mt={2}>
                        <Grid container spacing={matchesSMDown ? 3 : 1} style={{ padding: matchesSMDown ? 0:'1rem'}}>
                          <Grid item sm={6} xs={12}>
                            <Grid
                              item
                              md={12}
                              xs={12}
                              sm={12}
                              style={{
                                marginTop: ".5rem",
                                marginBottom: ".3rem",
                              }}
                            >
                              <InputLabel htmlFor="consent_patient_name">
                                {langContent.patient_name_}{""}
                                <span style={{ color: "tomato" }}>*</span>
                              </InputLabel>
                            </Grid>
                            <FormControl fullWidth>
                              <ThemeInput
                                size="small"
                                id="consent_patient_name"
                                name="consent_patient_name"
                                register={register}
                                errors={errors}
                                fullWidth
                                value={patientName ?? ""}
                                onChange={e => setPatientName(e.target.value)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item container md={6}>
                            <Grid
                              item
                              md={12}
                              style={{
                                marginTop: ".5rem",
                                marginBottom: ".3rem",
                              }}
                            >
                              <InputLabel htmlFor="user_dob">
                                {langContent.date}{""}
                                <span style={{ color: "tomato" }}>*</span>
                              </InputLabel>
                            </Grid>
                            <Grid container spacing={1}>
                              <Grid item md={4} sm={4} xs={4}>
                                <FormControl fullWidth>
                                  <DOBSelect
                                    size="small"
                                    name="member_consent_day"
                                    label={langContent.day}
                                    register={register}
                                    errors={errors}
                                    valueKey="key"
                                    fullWidth
                                    optionObj={dayArray}
                                    labelKey="label"
                                    selectLabel="select"
                                    value={consentDay ?? ''}
                                    onChange={e=>{setConsentDay(e.target.value)}}
                                    
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item md={4} sm={4} xs={4}>
                                <FormControl fullWidth>
                                  <ThemeSelectWithSelect
                                    size="small"
                                    name="member_consent_month"
                                    label={langContent.month}
                                    register={register}
                                    errors={errors}
                                    valueKey="key"
                                    fullWidth
                                    optionObj={monthArray}
                                    labelKey="label"
                                    value={consentMonth ?? ''}
                                    onChange={e=>{setConsentMonth(e.target.value)}}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item md={4} sm={4} xs={4}>
                                <FormControl fullWidth>
                                  <ThemeSelectWithSelect
                                    size="small"
                                    name="member_consent_year"
                                    label={langContent.year}
                                    register={register}
                                    errors={errors}
                                    valueKey="key"
                                    fullWidth
                                    optionObj={yearArray}
                                    labelKey="label"
                                    value={consentYear ?? ''}
                                    onChange={e=>{setConsentYear(e.target.value)}}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box mt={2}>
                        <Grid container spacing={matchesSMDown ? 3 : 1} style={{ padding: matchesSMDown ? 0:'1rem'}}>
                          <Grid item sm={6} xs={12}>
                            <Grid
                              item
                              style={{
                                marginTop: ".5rem",
                                marginBottom: ".3rem",
                              }}
                            >
                              <InputLabel htmlFor="consent_patient_name">
                                {langContent.sign_and_full_name_of_patient_guardian}{""}
                              </InputLabel>
                            </Grid>
                            <FormControl fullWidth>
                              <ThemeInput
                                size="small"
                                id="patient_parent_name"
                                name="patient_parent_name"
                                register={register}
                                errors={errors}
                                fullWidth
                                value={patientParentName ?? ""}
                                onChange={e => setPatientParentName(e.target.value)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item md={6} lg={6} sm={6} xs={12}  container>
                            <Grid
                              item
                              md={6}
                              sm={6}
                              xs={12}
                              style={{
                                marginTop: ".5rem",
                                marginBottom: ".3rem",
                              }}
                            >
                              <InputLabel htmlFor="user_dob">
                                {langContent.date}{""}
                              </InputLabel>
                            </Grid>
                            <Grid container spacing={1}>
                              <Grid item md={4} sm={4} xs={4}>
                                <FormControl fullWidth>
                                  <DOBSelect
                                    size="small"
                                    name="member_consent_day"
                                    label={langContent.day}
                                    register={register}
                                    errors={errors}
                                    valueKey="key"
                                    fullWidth
                                    optionObj={dayArray}
                                    labelKey="label"
                                    selectLabel="select"
                                    value={consentDay ?? ''}
                                    onChange={e=>{setConsentDay(e.target.value)}}
                                    
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item md={4} sm={4} xs={4}>
                                <FormControl fullWidth>
                                  <ThemeSelectWithSelect
                                    size="small"
                                    name="member_consent_month"
                                    label={langContent.month}
                                    register={register}
                                    errors={errors}
                                    valueKey="key"
                                    fullWidth
                                    optionObj={monthArray}
                                    labelKey="label"
                                    value={consentMonth ?? ''}
                                    onChange={e=>{setConsentMonth(e.target.value)}}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item md={4} sm={4} xs={4}>
                                <FormControl fullWidth>
                                  <ThemeSelectWithSelect
                                    size="small"
                                    name="member_consent_year"
                                    label={langContent.year}
                                    register={register}
                                    errors={errors}
                                    valueKey="key"
                                    fullWidth
                                    optionObj={yearArray}
                                    labelKey="label"
                                    value={consentYear ?? ''}
                                    onChange={e=>{setConsentYear(e.target.value)}}
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      mt={7.8}
                      mb={2.6}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={requestPending ? true : false}
                        color="primary"
                        style={{ padding: "8px 22px", color: "white" }}
                      >
                        {requestPending && (
                          <>
                            <CircularProgress color="primary" size={22} />
                            <Text
                              variant="textxs"
                              color="#02abd6"
                              style={{ marginLeft: "10px" }}
                            >
                              {langContent.loading}..
                            </Text>
                          </>
                        )}
                        {!requestPending && langContent.submit_form}
                      </Button>
                    </Box>
                  </Grid>
                </Box>
              </form>
            </Box>
          </Container>
        )}
        {!isLoading && <NewFooter />}
      </React.Fragment>
    </>
  );
}

export default Form1a;
