let content = {
  English: {
    signup: "Signup",
    doctor: "Doctor",
    my_wallet: "My Wallet",
    order_id: "Order Id",
    transaction_status: "Transaction Status",
    transaction: "Transaction",
    amount: "Amount",
    order_cancelled: "Order Cancelled",
    added_by_admin: "Added By Admin",
    order_processed: "Order Processed",
    speciality: "Speciality",
    experience: "Experience",
    education: "Education",
    research_publication_rewards: "Research, Publication & Rewards",
    phone: "Phone",
    //new entry
    home: "Home",
    members: "Members / Patient Portal",
    my_appointments: "My Appointments",
    my_prescription: "My Prescription",
    reports: "Lab Reports",
    orders_history: "Orders History",
    cart: "Cart",
    wallet: "Wallet",
    logout: "Logout",
    login: "Patient login",
    forgot_password: "Forgot Password?",
    password: "Password",
    login_otp: "Login With OTP",
    mobile_number: "Mobile Number",
    name: "Name",
    email: "Email",
    preferred_language: "ngôn ngữ ưa thích",
    date_of_birth: "Date of Birth",
    gender: "Gender",
    marital_status: "Marital Status",
    ward: "Ward",
    city: "City / Province",
    district: "District",
    zip: "Zip",
    address: "Address",
    edit_profile: "Edit Profile",
    update_password: "Update Password",
    deactivate_account: "Deactivate  Account",
    country: "Country",
    agree_terms_condition: "I agree to the Terms and Condition",
    save: "Save",
    upload_photo: "Upload Photo",
    choose_file: "Choose File",
    new_password: "New Password",
    confirm_new_password: "Confirm New Password",
    deactivate_your_account: "Deactivate your account",
    add_member: "Add Member",
    view_details: "View Details",
    member_details: "Member Details",
    cmnd: "CMND Number",
    passport: "Passport",
    edit: "Edit",
    type: "Type",
    consultation_room: "Consultation room",
    upcoming_appointments: "Upcoming Appointments",
    past_appointments: "Past Appointments",
    consultation_summary: "Consultation Summary",
    patient: "Patient",
    download_consultation_summary: "Download Consultation Summary",
    my_lab_report: "My Lab Report",
    download_report: "Download Report",
    my_orders: "My Orders",
    download: "Download",
    my_cart: "My Cart",
    order_summary: "Order Summary",
    book_appointment: "Book Appointment",
    health_services: "Health Services",
    health_checkup: "Health Checkup",
    general_physician: "General Physician",
    ivf: "IVF",
    mother_child_care: "Mother &  Child Care",
    choose_speciality: "Choose Speciality",
    select_patient: "Select Patient",
    add_patient_symptoms: "Add Patient Symptoms",
    book_slot: "Book Slot",
    schedule_appointment: "Schedule Appointment",
    appointment_date: "Appointment Date",
    appointment_time_slot: "Appointment Time Slot",
    consultancy: "Consultancy",
    add_to_cart: "Add To Cart",
    price: "Price",
    total: "Total",
    apply_discount_code: "Apply Discount Code",
    cart_total: "Cart Total",
    cart_subtotal: "Cart Subtotal",
    discount_amount: "Discount Amount",
    admin_fee: "Admin Fee",
    order_total: "Order Total",
    proceed_to_payment: "Proceed to payment",
    order_details: "Order Details",
    billing_address: "Billing Address",
    payment: "Payment",
    company_name: "Company name",
    company_address: "Company address",
    tax_code: "Tax code",
    continue_to_checkout: "Continue to checkout",
    wallet_balance: "Wallet Balance",
    email_address: "Email Address",
    vaccine_registration: "Vaccine Registration",
    vaccination_center: "Vaccination Center",
    id_passport_cmmd: "ID (Passport/CMMD)",
    select_id: "Select ID",
    select_date_vaccination: "Select Date For Vaccination",
    select_dose: "Select Dose(First/Second)",
    vaccine_brand: "Vaccine Brand",
    priority_number: "Priority Number(if any)",
    or_fill_new_details: "Or Fill New details",
    select_vaccination_center: "Select Vaccination Center",
    //Google translate Entries
    online_appointment: "Online Appointment",
    offline_appointment: "Offline Appointment",
    appointment_with: "Appointment With",
    labtest: "Health Package",
    apply: "Apply",
    promo_code: "Promo Code",
    relation: "Relation",
    id_proof: "ID Proof",
    edit_details: "Edit Details",
    upload_medical_history: "Upload Medical History",
    upload_document: "Upload Document",
    medical_history: "Medical History",
    cmnd_proof: "CMND Proof",
    full_name: "Full Name",
    upload_image: "Upload Image",
    relationship: "Relationship",
    educational_qualification: "Educational Qualification",
    allergies: "Allergies",
    vaccine: "Vaccine",
    vacine: "Vaccine",
    member_added_successfully: "Member Added Successfully",
    something_went_wrong: "Something went wrong",
    required_field: "Required Field",
    consultation: "Consultation",
    pickup_address: "Pick up address",
    or: "Or",
    top_nearby_hospitals: "Top NearBy Hospitals",
    see_all: "See All",
    nothing_here: "Nothing Here",
    order_initiated: "Order Initiated",
    paid: "Paid",
    select_hospital: "Select Hospital",
    collection_time_slot: "Time Slot",
    select_member: "Select Member",
    morning: "Morning",
    afternoon: "Afternoon",
    choose_address_or_hospital: "Choose Pickup address or Select Hospital",
    added_to_cart_successfully: "Added to cart successfully",
    member_added_please_select_member:
      "Member Added Successfully, Please select member to book!",
    book_package_for_covid: "Book a package for Covid Testing",
    hospital: "Hospital",
    profile: "Profile",
    select_doctor: "Select Doctor",
    doctor_details: "Doctor Details",
    health_checkup_packages: "Health Checkup And Packages",
    select_time: "Select Time",
    select_mode: "Select Mode",
    symptoms: "Symptoms",
    appointment_booked: "Appointment Booked",
    online: "Online",
    offline: "Offline",
    remove_coupan: "Remove Coupan",
    customer_name: "Customer name",
    tax_information: "Tax Information",
    order_confirmation: "Order Confirmation",
    transaction_details: "Transaction Details",
    shipping_address: "Shipping Address",
    quantity: "Quantity",
    labtest_date: "Labtest Date",
    appointment_type: "Appointment Type",
    sample_collection_time: "Sample Collection Time",
    patient_name: "Patient Full Name",
    checkin_code: "Check-In Code",
    total_items_amount: "Total Item(s) Amount",
    items_amount: "Item(s) Amount",
    use_wallet: "Use Wallet",
    user_details: "User Details",
    document_uploaded_successfully: "Document Uploaded successfully",
    upload: "Upload",
    choose_profile_image: "Choose Profile Image",
    order_number: "Order Number",
    transaction_source: "Transaction Source",
    wallet_amount_used: "Wallet Amount Used",
    order_amount: "Order Amount",
    please_enter_otp: "Please Enter OTP",
    mobile_number_validation: "Must be between 9 and 10 characters",
    health_packages: "Health Packages",
    search_place_holder: "Clinic / Doctor / Speciality / Package",
    my_wellness: "My Wellness",
    thankyou_message:
      "Thank you for your booking request. We will contact for confirmation asap",
    empty_cart: "Your Cart is empty",
    coupan_applied_successfully: "Coupon Code Applied Successfully",
    collection_date: "Collection Date",
    member_name: "Member Name",
    select: "Select",
    report_delivery: "Report Delivery",
    highlight_packages: "Highlight Packages",
    search_package: "Search Package",
    order_not_exist: "Order Does Not Exist",
    order_already_processed: "Order Already Processed",
    invalid_amount:
      "Your Order Could Not be Processed Because Amount is Invalid",
    order_cancelled_by_user: "Transaction Cancelled by User",
    invalid_number: "Invalid Number",
    mobile_no: "Please Enter Mobile Number",
    invalid_otp: "Invalid OTP",
    member_updated: "Member Updated Successfully",
    terms_conditions: "By Logging in or signing up, you agree to the ",
    privacy_policy: "Privacy policy",
    operation_policy: "Operation policy",
    incorrect_password: "Password Incorrect",
    submit: "Submit",
    covid19_test: "COVID-19 Test",
    update_member_details: "Update Member Details",
    hospital_contact_no: "Hospital Mobile No",
    hospital_login: "Hospital Login",
    // new entry for member's medical history
    member_medical_history: "Member Medical History",
    hospital_name: "Hospital Name",
    patient_id: "Patient ID",
    doctor_name: "Doctor Name",
    report_date: "Date",
    upload_report:
      "Upload Document (File format: jpg, jpeg, png, pdf | Max Size: 2mb)",
    member_medical_history_added_successfully:
      "Member medical history added successfully",
    report: "Document",
    doctor_login: "Doctor login",
    sorry_no_doctor:
      "So sorry ! No Doctor, Please try changing your search keyword",
    description: "Description",
    we_will_connect_soon: "We will Connect Soon",
    partner_with_us: "Partner with us",
    vaccine_dose: "Vaccine Dose",
    registration: "Registration",
    date_for_vaccination: "Select Date For Vaccination",
    registered_successfully: "Registered Successfully",
    do_not_have_member: "Do Not Have Member ?",
    vaccine_date_time: "Vaccine date & time",
    consultancy_fees: "Consultancy Fees",
    consultant: "Consultant",
    time: "Time",
    account_details: "Account Details",
    vaccination_details: "Vaccination Details",
    register_vaccine: "Register Vaccine",
    pay_online: "Pay Online",
    pay_by_cash: "Pay By Cash",
    payment_mode: "Payment Mode",
    book_date: "Book Date",
    coming_soon: "Coming Soon",
    video_consult: "Video Consultation for Covid-19",
    covid_symptoms: "COVID-19 Symptoms",
    book_covid_consultation: "Book Covid-19 Consultation",
    covid_consult: "Covid-19 Consultation",
    cmnd_passport: "ID Card",
    scheduled_on: "Scheduled On",
    qty: "Quantity",
    login_first: "You have to login to add item in cart",
    cancel_vaccination_appointment: "Cancel Vaccination Appointment",
    yes: "Yes",
    no: "No",
    days_left: "Days Left",
    certificate: "Certificate",
    not_vaccinated: "Not Vaccinated",
    partially_vaccinated: "Partially Vaccinated",
    vaccinated: "Vaccinated",
    cancel: "Cancel",
    schedule: "Schedule",
    due_date: "Due Date",
    year_of_birth: "Year Of Birth",
    years: "Years",
    vat_invoice: "VAT invoice",
    shipping_same_as_billing: "Shipping Address same as Billing Address",
    covid_19_kit: "Covid-19 Test kit",
    vaccination: "Vaccination",
    nutrition_fitness: "Nutrition & Fitness",
    no_result: "No Result",
    ok: "Ok",
    no_file_chosen: "No file chosen",
    high_to_low: "High to low",
    low_to_high: "Low to high",
    all: "All",
    clinic: "Clinic",
    hospitals: "Hospitals",
    search_by_city: "Search by City",
    near_me: "Near Me",
    search_by: "Search By",
    near_here: "Near here",
    no_vicinity_result: "No results. Try changing your search or city.",
    featured_doctors: "Featured Doctors",
    footer: " Empowering Community Healthcare",
    view_on_map: "View on map",
    about_us: "About us",
    know_about_health: "Know About Health",
    view_all_health_packages: "View all health packages",
    test_date: "Appointment date",
    test_time: "Test time",
    covid_19_consultancy: "Covid 19 Consultancy",
    sorry_no_packages:
      "Sorry ! No Health packages Found. Please Try Changing your Search or filter preferences.",
    not_available: "Not Available",
    chosen_file_name: "Chosen file name",
    select_document_type: "Select Document Type",
    document_type: "Document Type",
    select_date_time: "Select Date and Time",
    no_slots_available: "No Slots Available",
    appointment_time: "Appointment Time",
    shop_now: "Shop Now",
    consultation_date: "Consultation date",
    date: "Date",
    my_consultations: "My Consultations",
    update_member: "Update Member",
    please_select_time: "Please Select Time",
    paid_online: "Paid Online",
    view_all_hospitals: "View All Hospitals",
    covid_19_consultation: "Covid 19 Consultation",
    covid_19_consultation_1: "Covid 19",
    covid_19_consultation_2: "Consultation",
    covid_19_testing: "Covid 19 Testing",
    covid_19_testing_1: "Covid 19",
    covid_19_testing_2: "Testing",
    covid_19_coming_soon: "Covid 19 Vaccination Coming Soon",
    some_appointment_slots_not_available:
      "Time slots for appointment are not available, please remove them",
    go_to_cart: "Go to Cart",
    user_updated: "User updated successfully",
    selected_doctor: "Selected Doctor",
    coronavirus_19: "Coronavirus (Covid 19)",
    virtual_consultation: "Virtual Consultation",
    only: "Only",
    save_time_money:
      "Save time & money.Skip the wait at urgent care & treat at home.",
    concerned_have_cororna: "Concerned you have been exposed to Cornovirus?",
    get_an_immediate:
      "Get an immediate on-demand video consult, screening diagnosis, and treatment plan for coronavirus. Board-certified medical professionals make an assessment of your condition and offer treatment based on CDC guidelines and best practices.",
    dont_waste_time: "Don’t waste time in the germ-infested waiting room",
    receive_high_quality: "Receive high-quality care from your couch",
    medical_professionals:
      "Medical professionals available on-call, 7am -10pm local time, every day of the year subject to availability",
    easily_manage:
      "Easily manage your electronic medical records Prescriptions sent to you right after the call",
    prescription_sent: "Prescriptions sent to you right after the call",
    information_related: " Information related to Coronavirus (Covid 19)",
    corona_symptoms: "Coronavirus (Covid-19) symptoms",
    if_you_have_any:
      " If you have any of these symptoms or tested positive, please consult for medication, precautions and nutrition. DO NOT get any Risks by self-medication practices.",
    long_coronavirus: "Long Coronavirus (Covid-19)",
    symptoms_of_long_covid:
      "Symptoms of long COVID are like COVID-19 itself, because it affects all organ systems.",
    related_news_covid: "Related news about Coronavirus (COVID-19)",
    if_you_have_any_of:
      "If you have any of these symptoms or tested positive, please consult for medication, precautions and nutrition. DO NOT get any Risks by self-medication practices.",
    view_all: "View All",
    rapid_covid_19: "Rapid Coivd-19 Test",
    results_in_15_minutes: "Results in 15 minutes",
    standard_pcr: "Standard PCR Covid19 Test",
    high_accuracy: "High Accuracy",
    read_top_articles: "Read top articles from our Health Experts",
    our_services: "Our Services",
    private_online_consultation:
      "Private online consultations & testing with verified doctors",
    covid_19_at_hospital: "COVID-19 Test AT HOSPITAL",
    covid_19_at_home: "Covid-19 Test at Home",
    we_offer_covid_19_test_home: "We offer COVID-19 tests in homes,",
    test_office: "offices, and on-site business locations.",
    available_in_ho_chi:
      "(Available in District 7, JaMi Clinic, Ho Chi Minh area)",
    we_got_you_covered:
      "We are here to connect you with our certified doctors!",
    corona_virus_symptoms: "What are Coronavirus (COVID-19) symptoms?",
    according_to_center:
      "According to the Center for Disease Control and Prevention (CDC), COVID-19 affects different people in different ways. Infected people have had a wide range of symptoms reported – from mild symptoms to severe illness.",
    symptoms_may_appear:
      "Symptoms may appear 2-14 days after exposure to the virus. People with these symptoms may have COVID-19:",
    fever_chills: "Fever or Chills",
    cough: "Cough",
    shortness_breath: "Shortness of breath or difficulty breathing",
    fatigue: "Fatigue",
    muscle_ache: "Muscle or body aches",
    headache: "Headache",
    new_loss_of_taste: "New loss of taste or smell",
    sore_threat: "Sore throat",
    congestion: "Congestion or runny nose",
    nausea: "Nausea or vomiting",
    diarrhea: "Diarrhea",
    look_for_emergency_warning:
      "Look for emergency warning signs for COVID-19. If someone is showing any of these signs, seek emergency medical care immediately:",
    trouble_breathing: "Trouble breathing",
    persistent_pain: "Persistent pain or pressure in the chest",
    new_confusion: "New confusion",
    inability_to_wake: "Inability to wake or stay awake",
    pale_grey:
      "Pale, gray, or blue-colored skin, lips, or nail beds, depending on skin tone",
    for_information:
      "For informational purposes only. Consult your primary care provider or local medical authority for advice.",
    call_your_medical:
      "Call your medical provider for any other symptoms that are severe or concerning to you.",
    long_covid_heading:
      "What is Long COVID and How Do You Know If You Have It?",
    long_covid_subheading:
      "Although most people with COVID-19 get better within weeks of illness, some people experience post-COVID conditions. Post-COVID conditions are a wide range of new, returning, or ongoing health problems people can experience four or more weeks after first being infected with the virus that causes COVID-19. Even people who did not have COVID-19 symptoms in the days or weeks after they were infected can have post-COVID conditions. These conditions can present as different types and combinations of health problems for different lengths of time. These post-COVID conditions may also be known as long COVID, long-haul COVID, post-acute COVID-19, long-term effects of COVID, or chronic COVID. (According to the CDC)",
    new_or_ongoing: "New or Ongoing Symptoms",
    difficulty_breathing: "Difficulty breathing or shortness of breath",
    tiredness: "Tiredness or fatigue",
    symptoms_get_worse:
      "Symptoms that get worse after physical or mental activities (also known as post-exertional malaise)",
    difficulty_thinking:
      "Difficulty thinking or concentrating (sometimes referred to as “brain fog”)",
    chest_stomach_pain: "Chest or stomach pain",
    fast_beating_heart:
      "Fast-beating or pounding heart (also known as heart palpitations)",
    joint_muscle_pain: "Joint or muscle pain",
    pins_needles_feeling: "Pins-and-needles feeling",
    sleep_problems: "Sleep problems",
    fever: "Fever",
    dizziness: "Dizziness on standing (lightheadedness)",
    rash: "Rash",
    mood_changes: "Mood changes",
    smell_change: "Change in smell or taste",
    change_menstrual: "Changes in menstrual period cycles",
    day: "Day",
    year: "Year",
    month: "Month",
    member_allergies: "Allergies (if any)",
    covid_19_testing_at_your: "COVID-19 testing at your",
    doorstep: "Doorstep!",
    consultants: "Consultants",
    return_and_refund_policy: "Return and refund policy",
    payment_policy: "Payment policy",
    home_testing_is_available:
      "Home Testing is available in District 7, HCMC. Supported by JaMi Clinic",
    nurse_medical_staff:
      "Nurse/ Medical staff will visit your home and collect samples for PCR Covid 19 Test ",
    test_result_will_be_available:
      "Test results will be available in 36-48 hours ",
    home_testing_can_only_booked:
      "Home testing can be booked only for the next day",
    home_standard_pcr_test: "Home Standard PCR Covid19 Test 1,500,000 VD",
    telemedicine_consultation: "Telemedicine consultation",
    hospital_visit: "Hospital visit",
    about_iqi:
      "IQI creates a technology platform in the medical industry, bringing customers to hospitals and doctors with just one click on a mobile phone or computer, making connection between patients and providers faster than ever",
    nearby_hospitals: "Nearby Hospitals",
    for_doctors: "For doctors",
    register: "Register",
    company: "Company",
    terms_and_conditions: "Terms & conditions",
    payment_options: "Payment options",
    appointments: "Appointments",
    for_patients: "For patients",
    disclaimer: "Disclaimer",
    cancellation_policy: "Cancellation policy",
    contact: "Contact number",
    covid_19_test_hospitals:
      "Testing available at Vanh Hanh Hospital, JaMi Clinic and Diag Labs",
    rt_covid_19_test: "Rapid Covid-19 Test",
    pcr_covid_19_test: "Standard PCR Covid-19 Test",
    book_sample_collection: "Book sample collection",
    featured_doctor_footer: "Featured doctors",
    health_packages_footer: "Health packages",
    address_type: "Address type",
    edit_address: "Edit Address",
    add_new_address: "Add New Address",
    address_updated: "Address Updated",
    my_addresses: "My Address(es)",
    remove: "Remove",
    select_delivery_address: "Select Delivery Address",
    copyright: "© 2021 IQI Health. All rights reserved.",
    select_billing_address: "Select Billing Address",
    my_address: "My Address",
    covid_19_treatment: "Covid-19 Treatment",
    home_line_1: "Best and timely diagnosis",
    home_line_2: " Seamless Telemedicine",
    home_line_3: "Expert Clinical Advice",
    home_heading: "Get the best treatment with IQI Health!",
    my_account: "My Account",
    what_our_patients_say: "What Our Patients Say About Us",
    deactive_msg_1:
      "Once I delete the application, iQi will delete all my profile, purchase history and all relevant data from the database.",
    deactive_msg_2:
      "I understand iQi will never share my data with any third party.",
    account_deletion_alet_msg:
      "Do you really want to deactivate your account ?",
    invoice_and_insurance_info: "Invoices & insurance information",
    know_more: "Know more",
    contact_us: "Contact Us",
    contact_footer: "Contact",
    my_rating: "My Rating",
    feedback: "Feedback",
    add_comment_optional: "Add comment (Optional)",
    overall_rating: "Overall Rating",
    rate_doctor_consultation: "Rate Doctor consultation",
    rate_health_package: "Rate Health Check Package",
    rate_iqi_app: "Rate iQi Application",
    about_test: "About test",
    pre_test_info: "Pre-test Info",
    diagnostic_powered_by_diag: "Diagnostic Powered by DIAG",
    thanyou_for_feedback: "Thank you for your feedback",
    login_register: "Patient Login / Register",
    please_check_internet_connection: "Please check your internet connection.",
    get_covid_consultation_easily: "Get COVID-19 Consultation",
    get_covid_19_tested: "Get COVID-19 tested",
    select_healthcare_packages: "Select Health care packages",
    loading: "Loading",
    something_wrong_page:
      "Opps! Something is not right. Please go back to the Home page and try again.",
    consultaion_room_will_be_active_10_minutes_prior:
      " Consulting room will be activated 10 minutes prior appointment time only",
    uploading: "Uploading",
    recommendations: "Recommendations",
    download_prescription: "Download Prescription",
    recommendation_by_doctor: "Recommendations by doctor",
    recommended_labtests: "Recommended Labtests",
    recommended_appointments: "Recommended Appointments",
    appointment_number: "Appointment Number",
    please_enter_valid_email: "Please enter valid Email Id",
    email_already_exists: "Email Already Exists",
    corporate_packages: "Corporate Packages",
    select_corporate_packages: "Select corporate packages",
    sorry_no_corporate_packages:
      "Sorry ! No corporate packages found. Please Try Changing your Search or filter preferences.",
    corporate_executive_health_packages: "Corporate Executive Health Packages",
    pay_instalment: "Pay by Instalments",
    re_schedule: "Reschedule",
    confirm_order: "Confirm Order",
    book_now: "Book Now",
    sample_collection: "Sample Collection",
    clinic_visit: "Clinic Visit",
    medical_report: "Medical Report",
    select_doctor: "Select Doctor",
    any_doctor: "Any Doctor",
    you_have_booked: "You have booked",
    out_of_3_order:
      "out of 3 orders. In case Maximum order limit reached please contact Via Hotline  028 3910 4545",
    age: "Age",
    social_security_number: "ID Number",
    patient_inforamtion: "Patient Information",
    patient_Cell_phone_no: "Patient Cell Phone Number",
    patient_home_phone_no: "Patient's Home Phone Number",
    home_address: "Home Address",
    zip_code: "Zip Code",
    name_of_patient_guardian:
      "Name of Patient's Guardian (required in case the patient is under 15 years old)",
    relation_to_patient: "Relationship to Patient",
    patient_guardian_cell_phone_no: "Patient's Guardian contact phone number",
    your_provided_inforamtion:
      "The information you provided below will be kept confidential and used for medical purposes only.",
    fill_in_capital_letters: "complete the information in CAPITAL LETTERS",
    subscrie_your_private_email_account:
      "Subscribe your private E-mail account and contact number to register for Online Patient Portal (E-Medical Account) and receive other information and notification from Clinic.",
    email_address: "Email Address",
    rewrite_email_address: "Re-confirm the E-Mail address",
    consent: "Consent",
    i_hereby_declare:
      "I hereby agree to permit Victoria Healthcare using my email address and the personal information provided in this form to register for my patient  ID profile, e-medical account, and contact for other notifications of medical examination results, health consulting, healthnewsletter and customer care purposes. I totally understand and agree to take responsibility for security and confidentiality of my personal health information by changing password upon receipt.",
    i_assure_all_info:
      "I hereby certify thatl the information provided in this form is complete, true, accurate and verified by myself.",
    form1a: "PATIENT REGISTRATION FORM",
    use_direct_billing: "Request for Direct billing service",
    indicate_insurance_company: "Please provide your insurance company name",
    request_for_insurance_claim: "Request for Insurance Claim documents",
    request_for_converting_traditional_vat:
      "Request for Digital VAT invoice Certification",
    request_for_additional_info_vat:
      "Request for additional information on VAT invoice, provide details below (if yes)",
    request_for_social_certificate:
      "Request for Social insurance certificate, provide details below (if yes)",
    submit_form: "Submit form",
    form1b: "Form 1B",
    insurance_info: "Insurance Information",
    primary_insurance: "Primary Insurance",
    pri_insurance_card_no: "Primary Insurance Card Number",
    pri_card_validity: "Primary Card Validity",
    patient_is_subscriber: "Patient is Subscriber/Policy Holder",
    terms_and_conditions: "Terms and Conditions",
    in_case_of_req_for_direct_billing:
      "ln case of request for Direct Billing, I totally accept the current procedures applying at VHC and that is obligated by my Insurance Company, which including providing needed valid insurance documents (valid insurance card), identifications. I agree to pay the deposit before VHC receiving the confirmation from my Insurance Company.",
    i_fully_understand:
      " I fully understand and commit to be responsible for paying all the expenses that is out of my insurance coverage (deductible/ co-pay  amount or expenses incurred for pre-existing conditions/diagnosis/medicine which is on the Health insurance list of exclusions, costs out of coverage, uncovered fee by Insurance Company)",
    by_signing_below:
      " By signing below, I hereby acknowledge that I have completely read and fully understand the terms and conditions listed above as well as the purposes of this Patient registration form. 1 hereby give the permission to VHC to save the signature signing below as my sample signature which is to prevent/against manipulated intentions/behaviors to my personal data for insurance fraud.",
    i_assure_that:
      "I assure that 1 have completely read and fully understand and accepted the terms and conditions listed above.",
    patient_parent_guardian_name: "(Patient/Parent or Guardian Signature)",
    secondary_insurance: "Secondary Insurance (If any)",
    sec_insurance_card_no: "Secondary Insurance Card Number",
    sec_card_validity: "Secondary Card Validity",
    fill_form_1a_1b: "Fill Forms",
    fill_form1a: "1A. First Visit Registration Form",
    fill_form1b: "1B. Direct Billing Registration Form",
    patient_report_url: "Patient Web Portal",
    view: "View",
    your_order_is_confirmed:
      "Your appointment is confirmed. Please check booking information in <My account/ My orders>",
    contact_information: "CONTACT INFORMATION",
    vic_health_int_clinic: "VICTORIA HEALTHCARE INTERNATIONAL CLINIC",
    victoria_address_1:
      "Branch 1: 37-39 Luong Dinh Cua, An Khanh Ward, Thu Duc City",
    victoria_address_2:
      "Branch 2: 20-20Bis-22 Dinh Tien Hoang, Da Kao Ward, District 1",
    victoria_address_3:
      "Branch 3: 135A Nguyen Van Troi, Ward 11, Phu Nhuan District",
    victoria_address_4:
      "Branch 4: 1056 Nguyen Van Linh, Sky Garden 1, Phu My Hung, District 7",
    about_us_cap: "ABOUT US",
    about_us_para1:
      "Victoria Healthcare was established in 2005 and has long term experience in the field of high-quality health care and has created a high reputation to customers in HCMC and surrounding areas.",
    about_us_para2:
      "Victoria Healthcare has many branches at convenient locations in the city, full of specialties for out-patient visit. Our Clinics’ equipment is state-of-the-art, up-to-date, clean, safe, and accurate. Our facilities are the finest in HCMC and comparable to what is found in the United States. Our physicians have frequently gained overseas training. Several in the USA, Australia, and Japan. Here at Victoria Healthcare, our doctors commit to evidence-based-medicine practice.",
    about_us_para3: "“Patients First” – “Tất cả vì Bệnh nhân",
    about_us_para4: "Victoria Healthcare’s Motto is “Patients First”.",
    vision_mission: "VISION, MISSION,",
    core_values: "CORE VALUES",
    Care_C: "C - Continuous Quality Improvement process",
    Care_A: "A - Advanced and updated healthcare technologies and knowledge",
    Care_R: "R - Respectful care: Promote patient-centered - team based care",
    Care_E: "E - Efficient treatment through evidence-based- medical practice",
    VALUES: "VALUES",
    top10_health_packages:
      "TOP 10 MOST CHOSEN HEALTH CHECK-UP PACKAGES AT VICTORIA HEALTHCARE",
    VISION: "VISION",
    to_be_the_standard: "To be the Standard in Healthcare for Vietnam.",
    to_provide_quality:
      "To provide quality medical services with compassion at affordable prices to the people of Vietnam.",
    MISSION: "MISSION",
    feature_doctors: "FEATURED DOCTORS",
    anywhere_easy:
      "Anywhere – Easy – Convenient to get the best health advice for yourself and family from well-experienced doctors of Victoria Healthcare.",
    modern_tech_and_state:
      "MODERN TECHNOLOGY AND STATE-OF-THE-ART MEDICAL EQUIPMENT ACCURATE DIAGNOSTIC SUPPORT",
    clinic_list: "OUR CLINICS",
    clinic_list_: "Our Clinics / Bookings",
    do_you_want_to_select_doctor:
      "Do you want to select doctor or continue with any doctor?",
    patinet_parent_or_guardian_sign:
      "Verified and signed by Patient aged from 7 years and above or the Parent/Guardian ",
    delete_account: "Deactivate Account",
    Telehealth: "Telehealth",
    Contents: "Contents",
    check_the_box_if_agree: "Check the box if agree",
    i_here_by_agree_to_permit:
      "I hereby agree to permit Victoria Healthcare Clinic (the Clinic) using my email address and the personal information provided in this form to:",
    contact_to_confirm_request:
      "Contact to confirm,  support on service information from my registration or request",
    provide_service_information_upon:
      "Provide service information from my request and/or recommend services that may be of my interests, recognize my interests, and personalize my experience with the Service",
    register_for_my_patient:
      "Registration for patient record ID, user account of online patient portal, managing, updating and archiving of medical records, fixing software errors and operational failures, conducting data analysis, testing and research, monitoring and analyzing user trends and activities",
    contact_for_other_notifications:
      "Contact for other notifications of medical examination results, health consulting, health newsletter and customer care purposes;",
    fulfil_obligations_under:
      "Fulfill obligations under the medical services contracts and/or agreements between me (or my authorized representative) and the Clinic;",
    confirm_transactions_and: "Confirm and/or conduct payment transactions",
    protect_and_enforce:
      "Protect and enforce the legitimate rights, interests and obligations of the Clinic as a medical service provider  according to the agreement between me (or my authorized representative) and the Clinic and according to the law;",
    answer_my_questions_and_comments:
      "Response to my questions, and/or comments related to using services provided by the Clinic ;",
    analyze_my_medical_needs: "Analyze my medical needs;",
    contact_me_to_support_and_resolve:
      "Contact me to support and resolve other administrative matters, including but not limited to: implementing medical fee guarantees in term of direct billing, implementing insurance claim procedures;",
    send_information_and_notifiaction:
      "Send information and notifications including: confirmations, invoices, technical notices, updates, security alerts, support, administration and documents related to medical examination and treatment services that The clinic considers it necessary;",
    detect_investigate_and_prevent:
      "Detect, investigate and prevent fraudulent activities and violations of the law within the scope of the Clinic's rights and obligations;",
    send_service_updates:
      "Send notifications to me in regard to service information updates, promotional programs, customer care policy via postal, phone call, text message, online message, text notification or email;",
    for_the_clinic_to_comply:
      "For the Clinic to comply with its legal obligations: collect, use or store my personal data in certain circumstances, including when requested, advised, recommended or required by legal advice or any legal regulations or requirements of local or foreign government or authorities. Requirements of competent state agencies include but are not limited to, obligations to disclose information, report according to legal regulations on promotions, record keeping, auditing, investigation and resolution of complaints or disputes, comply with court orders or other legal, regulatory or governmental requirements; or other agreements; and protect the Clinic's rights or property in the event of a claim or dispute",
    at_the_same_time:
      "At the same time, I fully understand and assume the responsibility to secure the confidentiality of my personal or authorized dependents’ medical information by changing the password for access to the medical portal as soon as provided by the Clinic.",
    i_have_read_and_agree:
      "I have read and agree to the entire content of the Personal Data Protection Policy provided to me by the Clinic.",
    in_case_of_request:
      "1. In case of request for Direct Billing, I accept the administrative policies and work procedures regulated by the Insurance and Clinic which including providing valid insurance proof (Ex: valid insurance card, Payment Guarantee Letter…), personal identification proof and I agree to pay upfront the deposit amount for medical check-up while the guarantee of  payment is confirmed by my Insurance Company.",
    i_fully_understand_and_commit:
      "2. I fully understand and commit to be responsible for paying all the expenses that is out of my insurance coverage (deductible/ co-pay amount or expenses incurred for pre-existing conditions/diagnosis/medicine which is on the Health insurance list of exclusions, costs out of coverage, uncovered fee by Insurance Company)",
    by_signing_below_i_hereby:
      "3. By signing below, I hereby acknowledge that I have completely read and fully understand the terms and conditions listed above as well as the purposes of this registration form. I hereby give the permission to VHC to save the signature I am signing below as the sample signature which is used in the purposes of insurance fraud prevention and/ or in case of information verification.",
    i_assure_that_i_have_:
      "I assure that I have completely read, fully understood and accepted the terms and conditions listed above",
    terms_conditions_listed_below:
      "Terms and conditions listed below are only applied in case the patient request for Direct Billing service at clinic or post service claims support",
    place_of_receipt: "Confirmation of document delivery:",
    my_home_address: "My home address",
    pickup_at_the_clinic: "Pick up at clinic",
    my_company: "My company",
    note_below_full_name:
      "Provide recipient’s information & postal address (in case you choose delivery documents to home/ company address via shipping agency)",
    parent_name_if_patient_under_7:
      "Parents name (if patient under 7 years old)",
    social_insurance_card_number: "Social Insurance card number",
    signature_of_the_patient_7yr:
      "(Signature of the patient who is a child aged 7 years or older)",
    contact_clinic: "Contact Clinic",
    you_have_reached_login_otp:
      "Your have reached login by OTP limit. Please contact clinic Via Hotline",
    form_1b_submitted: "Form 1B submitted, to be confirmed at the clinic",
    only_one_booking_allowed_per_day:
      "Please contact hotline 083 910 4545 for support",
    my_medical_history: "My Medical History",
    member_medical_history_details: "Member Medical History Reports",
    shared_Clinic_list: "Shared Clinic List",
    medical_history_form: "Patient Medical History Form",
    in_order_to_let_your:
      "In order to let your doctor be well informed of your health condition, please kindly complete this form before the encounter with your doctor",
    do_you_now_or_ever_had: "Do you now or have you ever had",
    Stroke: "Stroke",
    Hypertension: "Hypertension",
    heart_disease: "Heart disease, HTN",
    stomach_ulcers: "Stomach ulcers",
    Hepatitis: "Hepatitis",
    Diabetes: "Diabetes",
    lung_disease: "Lung disease",
    Tuberculosis: "Tuberculosis",
    thyroid_disease: "Thyroid disease",
    Epilepsy: "Epilepsy",
    kidney_disease: "Kidney disease",
    Cancer: "Cancer",
    other_condition_or_more_information:
      "Other conditions or more information above",
    abnormal_findings_in_previous:
      "Abnormal findings in previous imaging studies (X-ray, US, CT-Scan, MRI, endoscopy, biopsy, pathology)",
    have_you_ever_had_any:
      "Have you ever had any major surgeries before? If yes, which one?",
    drug_allergies: "Drug allergies? If yes, which one?",
    current_medication: "Current medication",
    Smoking: "Smoking",
    Never: "Never",
    Quitted: "Quitted",
    Current: "Current",
    PPY: "PPY",
    Years: "Years",
    alcohol: "Alcohol",
    Married: "Married",
    Single: "Single",
    women_reproductive_history: "Women’s reproductive history",
    pregnancies: "pregnancies",
    miscarriages: "miscarriages",
    abortions: "abortions",
    regular_periods: "Regular periods?",
    are_you_pregnant: "Are you pregnant?",
    have_you_reached_menopause: "Have you reached menopause? At what age?",
    describe_briefly_your_present_symptoms:
      "Describe briefly your present symptoms",
    internal_medicine_heart:
      "Internal medicine: heart, lung, GI, orthopedics, neurology",
    ENT: "ENT",
    Eye: "Eye",
    dental_care: "Dental care",
    Dermatology: "Dermatology",
    family_medical_conditions:
      "Family medical conditions: Premature heart disease, Diabetes, Cancer of any type, Prostate, Breast, Ovarian problems, Intestinal issues; if deceased, please indicate age",
    Grandparents: "Grandparents",
    Parents: "Parents",
    Siblings: "Siblings",
    signature_and_full_name: "signature and full name",
    fill_medical_history_form: "Medical History Form",
    if_you_skip:
      "If you skip, you will have to fill the form manually in the clinic.",
    once_i_delete_the_application:
      "Once I delete the application, VIC will delete all my profile, purchase history and all relevant data from the database. I understand VIC will never share my data with any third party",
    my_forms: "My Forms",
    medical_history_removed: "Medical history removed successfully",
    i_have_read_and_agree_to_entire_doc:
      "I have read and agree to the entire content of the Personal Data Protection Policy provided to me by the Clinic.",
    sign_and_full_name_of_patient_guardian:
      "Signature and Full Name of Patient’s Parent or Guardian",
    upload_ID_proof: "Upload ID proof",
    comments: "Comments",
    share_medical_history: "Share Medical history",
    upload_insurance_card: "Upload Insurance Card",
    my_documents: "My Documents",
    delete: "Delete",
    skip: "Skip",
    receiver_name__and_address: "Receiver name and address",
    patient_name_: "Signature of patient from the age of 7 or above",
    for_the_clinic_to_comply:
      "For the Clinic to comply with its legal obligations: collect, use or store my personal data in certain circumstances, including when requested, advised, recommended or required by legal advice or any legal regulations or requirements of local or foreign government or authorities. Requirements of competent state agencies include but are not limited to, obligations to disclose information, report according to legal regulations on promotions, record keeping, auditing, investigation and resolution of complaints or disputes, comply with court orders or other legal, regulatory or governmental requirements; or other agreements; and protect the Clinic's rights or property in the event of a claim or dispute.",
    signature_of_patient_7_year_or_above:
      "Signature of patient from the age of 7 or above",
    upload_front_side: "Upload front side",
    upload_back_side: "Upload back side",
    expiry_date: "Expiry date",
    insurance_card: "Insurance Card",
    select_clinic: "Select Clinic",
    share: "Share",
    edit_member_details: "Edit Member Details",
    save_member: "Add Member",
    front: "Front",
    back: "Back",
    notifications: "Notifications",
    this_document_is_not_shared:
      "This document is not shared yet with any clinic",
    are_you_sure_you_want_to_delete_this_medical_report:
      "Are you sure, you want to delete this medical report?",
    confrim: "Confrim",
    idproof_number: "ID proof number",
    you_shared_this_medical_history_to_clinic:
      "Your shared this medical history to clinic",
    medical_history_removed_from_shared_list:
      "Medical history removed from shared list",
    please_select_clinic: "Please Select Clinic",
    your_account_has_been_deactivated:
      "Your account has been deactivated. Please call 028 3910 4545 for reactivation request or further assistance.",
    deactivate_member: "Deactivate Member",
    deactivate_member_msg: "Do you really want to deactivate this member?",
    activate_member_msg: "Do you really want to activate this member?",
    active: "Active",
    inactive: "Inactive",
    activate: "Activate",
    member_activated_msg: "Member activated succesfully",
    member_deactivated_msg: "Member deactivated succesfully",
    no_medical_history_found: "No medical history found",
    remarks: "Remarks",
    confirm: "Confirm",
    the_file_is_too_large: "The file should be less than 2MB",
    file_has_invalid_extension: "This file is not supported.",
    insurance_card_no: "Insurance Card Number",
    change_password: "Change Password",
    your_password_has_been_changed:
      "Your password has been changed successfully",
    you_have_entered_wrong_current_password:
      "You have entered wrong current password",
    current_password: "Current Password",
    new_password: "New Password",
    confirm_password: "Confirm  New Password",
    old_password_and_new_password:
      "Old password and new password couldn't be same. ",
    new_password_and_confirm_password:
      "New password and confirm password do not match.",
    form1a_submitted_successfully: "Form1A submitted successfully",
    locality: "Locality",
    are_you_sure_you_want_to_delete_this_document:
      "Are you sure, you want to delete this document?",
    document_deleted_succesfully: "Document deleted successfully",
    camera: "Camera",
    gallery: "Gallery",
    forgot_password: "Forgot Password",
    send_otp: "Send OTP",
    otp_sent_successfuly: "OTP sent successfully",
    verifying: "Verifying",
    password_reset_info:
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number And One Special Case Character",
    forgot_password: "Forgot Password",
    medical_history_removed_from_shared_list:
      "Medical history removed from shared list",
    no_appointments: "No Appointments",
    medical_history_form_submitted:
      "Medical history form submitted successfully",
    your_provided_info_below_will:
      "Your provided information below will be kept confidentially and used for the medical purposes only.",
    please_complete_the_info_in_capital_letter:
      "Please complete the information in CAPITAL LETTER",
    sex: "Sex",
    health_insurance_information: "Health Insurance Information",
    signature1: "Signature 1",
    signature2: "Signature 2",
    appointment_details_already_added_to_cart:
      "Appointment Details already added to cart",
    memeber_under_18:"You cannot add members aged 18 and above. Please contact the hotline at 028 3910 4545 for assistance",
    survey_form: "Survey Form",
    survey_thank_you: "You have completed this survey. Thank you",
    valid_password:"Valid password",
    fill_form:"Fill Form",
    password_reset_info: 'Password must contain at least 8 characters, including one letter, one number, and one special character.',
    passwords_do_not_match: 'New password and confirm password do not match',  
    abortions:"abortions",
    date_month_year:"Date/ Month/ Year"
  },
  vn: {
    date_month_year:"Ngày/ tháng/ năm",
    abortions:"Phá thai",
    password_reset_info: 'Password must contain at least 8 characters, including one letter, one number, and one special character.',
    passwords_do_not_match: 'New password and confirm password do not match',  
    valid_password:"Valid password",
    fill_form:"Fill Form",
    appointment_details_already_added_to_cart:
      "Appointment Details already added to cart",
    signature1: "Ký tên lần 1",
    signature2: "Ký tên lần 2",
    secondary_insurance: "Bảo hiểm phụ (nếu có)",
    primary_insurance: "Bảo hiểm chính",
    health_insurance_information: "THÔNG TIN BẢO HIỂM",
    sex: "Giới tính",
    please_complete_the_info_in_capital_letter:
      "Vui lòng điền đầy đủ thông tin bằng chữ IN HOA. ",
    your_provided_info_below_will:
      "Thông tin Quý khách cung cấp sau đây sẽ được bảo mật và chỉ sử dụng cho mục đích khám chữa bệnh tại phòng khám.",
    medical_history_form_submitted: "Tiền sử bệnh đã được gửi thành công",
    no_appointments: "Không có lịch hẹn",
    medical_history_removed_from_shared_list: "Lịch sử y tế đã được xóa",
    forgot_password: "Quên mật khẩu",
    password_reset_info:
      "Phải chứa 8 ký tự, bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt",
    verifying: "Verifying",
    otp_sent_successfuly: "OTP đã được gửi thành công",
    send_otp: "Gửi OTP",
    gallery: "Thư viện",
    camera: "Máy ảnh",
    document_deleted_succesfully: "Đã xóa tài liệu thành công",
    are_you_sure_you_want_to_delete_this_document:
      "Bạn có chắc chắn muốn xóa tài liệu này?",
    locality: "địa phương",
    form1a_submitted_successfully:
      "Đã gửi thành công 1A - Phiếu đăng ký thông tin bệnh nhân (lần đầu)",
    new_password_and_confirm_password: "",
    old_password_and_new_password:
      "Mật khẩu cũ và mật khẩu mới không thể giống nhau",
    confirm_password: "Nhập lại mật khẩu",
    new_password: "Mật khẩu mới",
    current_password: "Mật khẩu cũ",
    you_have_entered_wrong_current_password:
      "Bạn đã nhập sai mật khẩu. Vui lòng thử lại",
    your_password_has_been_changed:
      "Mật khẩu của bạn đã được thay đổi thành công",
    change_password: "Thay đổi mật khẩu",
    insurance_card_no: "Số thẻ bảo hiểm",
    file_has_invalid_extension: "tập tin này không được hỗ trợ",
    the_file_is_too_large: "Kích thước tệp đính kèm tối đa: 2mb",
    remarks: "Ghi chú",
    no_medical_history_found: "Không tìm thấy tài liệu",
    member_activated_msg: "Đã kích hoạt thành viên",
    member_deactivated_msg: "Đã hủy kích hoạt thành viên",
    activate: "Kích hoạt",
    inactive: "Hủy kích hoạt",
    active: "Đang hoạt động",
    deactivate_member_msg: "Bạn có muốn hủy kích hoạt thành viên này không?",
    activate_member_msg: "Bạn có muốn kích hoạt thành viên này không?",
    deactivate_member: "Hủy kích hoạt thành viên",
    your_account_has_been_deactivated:
      "Tài khoản của bạn đã tạm ngưng hoạt động. Vui lòng gọi 028 3910 4545 để yêu cầu kích hoạt lại hoặc nếu cần hỗ trợ thêm.",
    please_select_clinic: "Vui lòng chọn phòng khám",
    medical_history_removed_from_shared_list: "Lịch sử y tế xóa rồi",
    you_shared_this_medical_history_to_clinic:
      "Bạn đã chia sẻ thông tin bệnh sử này cho phòng khám",
    idproof_number: "CCCD/ Mã định danh",
    confirm: "Xác nhận",
    are_you_sure_you_want_to_delete_this_medical_report:
      "Bạn có chắc chắn muốn xóa hồ sơ bệnh án này không?",
    this_document_is_not_shared:
      "Tài liệu này chưa được chia sẻ với bất kỳ phòng khám nào",
    notifications: "Thông báo",
    front: "front",
    back: "back",
    save_member: "Thêm thành viên",
    edit_member_details: "Thông tin thành viên",
    share: "Chia sẻ",
    select_clinic: "Chọn phòng khám",
    expiry_date: "ngày hết hạn",
    upload_front_side: "Tải lên mặt trước",
    upload_back_side: "Tải lên mặt sau",
    signature_of_patient_7_year_or_above:
      "Chữ ký bệnh nhân từ đủ 07 tuổi trở lên",
    for_the_clinic_to_comply:
      "Để Phòng khám tuân thủ nghĩa vụ pháp lý: thu thập, sử dụng hoặc lưu trữ dữ liệu cá nhân của tôi trong một số trường hợp nhất định, bao gồm khi được yêu cầu, được tư vấn, đề nghị hoặc đòi hỏi bởi các cố vấn pháp lý hoặc bất kỳ quy định pháp luật hoặc yêu cầu của chính phủ hay các cơ quan có thẩm quyền tại địa phương hoặc nước ngoài. Yêu cầu của cơ quan nhà nước có thẩm quyền bao gồm nhưng không giới hạn nghĩa vụ công bố thông tin, báo cáo theo quy định pháp luật về khuyến mại, lưu trữ hồ sơ, kiểm toán, điều tra và giải quyết khiếu nại hoặc tranh chấp, tuân thủ các lệnh của tòa án hoặc các yêu cầu pháp lý, văn bản hoặc yêu cầu của chính phủ hoặc quy định khác; hoặc các thỏa thuận khác; và bảo vệ quyền hoặc tài sản của Phòng khám trong trường hợp khiếu nại hoặc tranh chấp.",
    patient_name_: "Chữ ký bệnh nhân từ đủ 07 tuổi trở lên",
    receiver_name__and_address: "Tên và địa chỉ người nhận",
    social_security_number: "Số CCCD",
    skip: "Bỏ qua",
    delete: "Delete",
    my_documents: "Tài liệu của tôi",
    upload_insurance_card: "Tải lên thẻ bảo hiểm",
    insurance_card: "Thẻ bảo hiểm",
    share_medical_history: "Chia sẻ bệnh sử",
    comments: "Ghi chú",
    upload_ID_proof: "Tải lên thẻ CMND",
    sign_and_full_name_of_patient_guardian:
      "Chữ ký xác nhận của người giám hộ (Vui lòng ký và ghi rõ họ tên)",
    i_have_read_and_agree_to_entire_doc:
      "Tôi đã đọc, hiểu rõ và đồng ý với toàn bộ nội dung của Chính sách bảo vệ dữ liệu cá nhân do Phòng khám cung cấp cho tôi.",
    medical_history_removed: "Đã xóa lịch sử y tế thành công",
    my_forms: "Mẫu của tôi",
    once_i_delete_the_application:
      "Sau khi tôi xóa ứng dụng, VIC sẽ xóa tất cả hồ sơ, lịch sử mua hàng và tất cả dữ liệu liên quan của tôi khỏi cơ sở dữ liệu. Tôi hiểu VIC sẽ không bao giờ chia sẻ dữ liệu của tôi với bất kỳ bên thứ ba nào",
    if_you_skip:
      "Nếu bỏ qua, bạn sẽ phải điền thủ công vào mẫu đơn tại phòng khám",
    fill_medical_history_form: "Khai báo bệnh sử",
    signature_and_full_name: "Ký tên và ghi rõ họ tên",
    Siblings: "Anh, chị, em",
    Parents: "Cha, mẹ",
    Grandparents: "Ông, bà",
    family_medical_conditions:
      "Tiền sử bệnh lý của gia đình: các bệnh lý cần chú ý: bệnh tim xuất hiện sớm, tiểu đường, ung thư, bệnh lý tiền liệt tuyến, tuyến vú, buồng trứng, đường ruột. Nếu người thân đã mất, vui lòng ghi rõ độ tuổi ",
    Dermatology: "Da liễu",
    dental_care: "Răng hàm mặt",
    Eye: "Mắt",
    ENT: "Tai mũi họng",
    internal_medicine_heart:
      "Nội khoa: tim mạch, phổi, tiêu hóa, cơ xương khớp, thần kinh",
    describe_briefly_your_present_symptoms:
      "Vấn đề sức khỏe quý khách đang quan tâm",
    have_you_reached_menopause: "Đã mãn kinh? Độ tuổi?",
    are_you_pregnant: "Hiện tại quý khách đang có thai hay không?",
    regular_periods: "Kinh nguyệt có đều không?",
    abortions: "Phá thai",
    miscarriages: "Sẩy thai",
    pregnancies: "Mang thai",
    women_reproductive_history: "Tiền sử sản phụ khoa",
    Single: "Độc thân",
    Married: "Kết hôn",
    alcohol: "Rượu bia",
    Years: "Năm",
    PPY: "Điếu/ngày",
    Current: "Đang hút",
    Quitted: "Đã ngưng hút",
    Never: "Không",
    Smoking: "Hút thuốc lá",
    current_medication: "Những loại thuốc đang sử dụng",
    drug_allergies: "Dị ứng thuốc? Nếu có, loại nào? ",
    have_you_ever_had_any:
      "Bạn có từng trải qua một cuộc phẫu thuật nào hay không? Nếu có, loại nào? ",
    abnormal_findings_in_previous:
      "Kết quả bất thường khi thực hiện các khảo sát hình ảnh trước đây (X-Quang, siêu âm, CT-Scan, MRI, nội soi, sinh thiết, giải phẫu bệnh) ",
    other_condition_or_more_information: "Khác, hoặc mô tả thêm các bệnh trên",
    Cancer: "Ung thư",
    kidney_disease: "Bệnh thận",
    Epilepsy: "Động Kinh",
    thyroid_disease: "Bệnh tuyến giáp",
    Tuberculosis: "Lao",
    lung_disease: "Bệnh phổi",
    Diabetes: "Tiểu Đường",
    Hepatitis: "Viêm Gan",
    stomach_ulcers: "Loét dạ dày",
    heart_disease: "Bệnh Tim, HA",
    Hypertension: "Tăng Huyết Áp",
    Stroke: "Đột Quỵ",
    do_you_now_or_ever_had:
      "Bản thân của quý khách đang hoặc có bao giờ mắc phải bệnh nào sau đây",
    in_order_to_let_your:
      "Vui lòng trả lời các câu hỏi sau về tình trạng bệnh lý trước đây của quý khách. Các thông tin này sẽ giúp cho bác sĩ hiểu rõ hơn về tình trạng sức khỏe của bạn.",
    age: "Tuổi",
    medical_history_form: "TỜ KHAI BỆNH SỬ",
    shared_Clinic_list: "Chia sẻ tài liệu đến danh sách Phòng khám:",
    member_medical_history_details: "Member Medical History Reports",
    my_medical_history: "My Medical History",
    only_one_booking_allowed_per_day:
      "Vui lòng liên hệ hotline 083 910 4545 để được hỗ trợ",
    form_1b_submitted: "Đã nộp mẫu 1B, sẽ được xác nhận tại phòng khám",
    you_have_reached_login_otp:
      "Bạn đã đến giới hạn đăng nhập bằng OTP. Vui lòng liên hệ phòng khám qua Hotline",
    contact_clinic: "Liên hệ phòng khám",
    signature_of_the_patient_7yr:
      "Chữ ký xác nhận của bệnh nhân là trẻ em từ đủ 07 tuổi trở lên",
    social_insurance_card_number: "Số thẻ BHYT/BHXH",
    parent_name_if_patient_under_7:
      "Họ và tên ba/mẹ (nếu bệnh nhân dưới 7 tuổi):",
    note_below_full_name:
      "Cung cấp thông tin người nhận và địa chỉ nhận hồ sơ (nếu chọn nhận hồ sơ tại nhà riêng/ cơ quan qua đơn vị chuyển phát",
    my_company: "Cơ quan",
    pickup_at_the_clinic: "Nhận tại Phòng khám",
    my_home_address: "Nhà riêng",
    place_of_receipt: "Xác nhận hình thức chuyển phát hồ sơ:",
    terms_conditions_listed_below:
      "Điều khoản và điều kiện sau đây chỉ áp dụng đối với/ trong trường hợp bệnh nhân đăng ký sử dụng dịch vụ Bảo Lãnh Viện Phí tại phòng khám hoặc yêu cầu hồ sơ thanh toán Bảo hiểm sau dịch vụ.",
    i_assure_that_i_have_:
      "Tôi cam kết đã đọc, hiểu và chấp nhận các điều khoản trên.",
    by_signing_below_i_hereby:
      "3. Bằng viêc ký tên vào phần chữ ký mẫu dưới đây, tôi xác nhận đã đọc, hiểu và đã được tạo điều kiện giải đáp các thắc mắc liên quan đến điều khoản nêu trên cũng như mục đích sử dụng thông tin hồ sơ đăng ký khám bệnh này. Đồng thời, tôi đồng ý chấp thuận cho Phòng khám được sử dụng chữ ký xác nhận của tôi dưới đây làm chữ ký mẫu đã được xác thực bởi cá nhân tôi làm cơ sở đối chiếu cho trường hợp cần thiết sau này nhằm loại trừ các ý đồ/ hành vi cố tình giả mạo thông tin cá nhân của tôi để trục lợi bảo hiểm hoặc vì mục đích trục lợi không chính đáng khác.",
    i_fully_understand_and_commit:
      "2. Tôi hoàn toàn hiểu và cam kết chịu trách nhiệm thanh toán các khoản chi phí phát sinh đã sử dụng ngoài Phạm vi Bảo hiểm (mức miễn thường/ khoản phí đồng chi trả hoặc chi phí phát sinh từ danh mục loại trừ do Bảo hiểm quy định, chi phí không thuộc/ vượt quyền lợi bảo hiểm hoặc bất cứ khoản chi phí nào Công ty Bảo Hiểm của tôi từ chối thanh toán/ bảo lãnh)",
    in_case_of_request:
      "1. Trong trường hợp yêu cầu dịch vụ Bảo Lãnh Viện Phí hoặc hồ sơ thanh toán bảo hiểm, tôi đồng ý thực hiện theo quy trình, thủ tục hành chính do Công ty Bảo Hiểm và Phòng khám quy định, bao gồm: cung cấp đầy đủ chứng từ Bảo hiểm hợp lệ (thẻ Bảo hiểm còn hiệu lưc, thư bảo lãnh…), giấy tờ tùy thân và đồng ý thanh toán các khoản phí tạm thu dịch vụ khám chữa bệnh cho phòng khám trong thời gian chờ xác nhận bảo lãnh từ phía Bảo Hiểm của tôi.",
    i_have_read_and_agree:
      "Tôi đã đọc, hiểu rõ và đồng ý với toàn bộ nội dung của Chính sách bảo vệ dữ liệu cá nhân do Phòng khám cung cấp cho tôi",
    at_the_same_time:
      "Đồng thời, tôi hoàn toàn hiểu và chịu trách nhiệm bảo mật thông tin bệnh án của cá nhân tôi hoặc người phụ thuộc được uỷ quyền bằng cách thay đổi mật khẩu truy cập cổng thông tin bệnh án ngay sau khi được cung cấp bởi Phòng khám.",
    for_the_clinic_to_comply:
      "Để Phòng khám tuân thủ nghĩa vụ pháp lý: thu thập, sử dụng hoặc lưu trữ dữ liệu cá nhân của tôi trong một số trường hợp nhất định, bao gồm khi được yêu cầu, được tư vấn, đề nghị hoặc đòi hỏi bởi các cố vấn pháp lý hoặc bất kỳ quy định pháp luật hoặc yêu cầu của chính phủ hay các cơ quan có thẩm quyền tại địa phương hoặc nước ngoài. Yêu cầu của cơ quan nhà nước có thẩm quyền bao gồm nhưng không giới hạn nghĩa vụ công bố thông tin, báo cáo theo quy định pháp luật về khuyến mại, lưu trữ hồ sơ, kiểm toán, điều tra và giải quyết khiếu nại hoặc tranh chấp, tuân thủ các lệnh của tòa án hoặc các yêu cầu pháp lý, văn bản hoặc yêu cầu của chính phủ hoặc quy định khác; hoặc các thỏa thuận khác; và bảo vệ quyền hoặc tài sản của  Phòng khám trong trường hợp khiếu nại hoặc tranh chấp.",
    send_service_updates:
      "Gửi các thông báo cập nhật đến tôi các Dịch vụ, chương trình ưu đãi, chăm sóc khách hàng liên quan đến các Dịch Vụ được cung cấp bởi Phòng khám qua các hình thức: thư bưu điện, gọi điện thoại, dịch vụ tin nhắn, dịch vụ nhắn tin trực tuyến, văn bản thông báo hoặc email;",
    detect_investigate_and_prevent:
      "Phát hiện, điều tra và ngăn chặn các hoạt động gian lận, vi phạm pháp luật trong phạm vi quyền và nghĩa vụ của Phòng khám; ",
    send_information_and_notifiaction:
      "Gửi thông tin, thông báo bao gồm: xác nhận, hóa đơn, thông báo kỹ thuật, cập nhật, cảnh báo bảo mật, hỗ trợ, quản trị và các tài liệu liên quan đến dịch vụ khám chữa bệnh mà  Phòng khám cho là cần thiết;",
    contact_me_to_support_and_resolve:
      "Liên lạc với tôi để hỗ trợ và giải quyết các vấn đề hành chính khác, bao gồm nhưng không giới hạn: thực hiện bảo lãnh viện phí, thực hiện thủ tục yêu cầu bồi thường bảo hiểm;",
    analyze_my_medical_needs: "Phân tích nhu cầu khám chữa bệnh của tôi;",
    answer_my_questions_and_comments:
      "Trả lời các câu hỏi, bình luận của tôi liên quan đến việc sử dụng dịch vụ của phòng khám;",
    protect_and_enforce:
      "Bảo vệ và thực thi các quyền, lợi ích hợp pháp và nghĩa vụ của Phòng khám với tư cách là bên cung cấp dịch vụ khám chữa bệnh theo thỏa thuận giữa tôi (hoặc bên đại diện hợp tác của tôi) với Phòng khám và theo quy định pháp luật;",
    confirm_transactions_and:
      "Xác nhận các giao dịch và thực hiện các khoản thanh toán;",
    fulfil_obligations_under:
      "Thực hiện các nghĩa vụ theo Hợp đồng dịch vụ khám chữa bệnh, các thỏa thuận giữa tôi (hoặc bên đại diện hợp pháp của tôi) và Phòng khám;",
    contact_for_other_notifications:
      "Gửi thông báo kết quả khám bệnh, tư vấn sức khoẻ, cung cấp thông tin sức khoẻ và chương trình chăm sóc khách hàng;",
    register_for_my_patient:
      "Đăng ký cấp mã hồ sơ bệnh nhân, tài khoản bệnh án điện tử, quản lý, cập nhật và lưu trữ hồ sơ bệnh án, khắc phục các lỗi phần mềm và sự cố vận hành, tiến hành phân tích dữ liệu, thử nghiệm và nghiên cứu, giám sát và phân tích xu hướng sử dụng và hoạt động;",
    provide_service_information_upon:
      "Cung cấp thông tin Dịch vụ khi có yêu cầu từ tôi và/ hoặc đề xuất Dịch vụ mà tôi có thể quan tâm, nhận diện sở thích của tôi và cá nhân hoá trải nghiệm của tôi với Dịch vụ;",
    contact_to_confirm_request:
      "Liên hệ xác nhận hỗ trợ thông tin dịch vụ khi nhận được đăng ký, yêu cầu hỗ trợ của tôi",
    i_here_by_agree_to_permit:
      "Tôi đồng ý cho phép Phòng khám Victoria Healthcare (Phòng khám) sử dụng tài khoản hộp thư điện tử (email address) và các thông tin cá nhân đã cung cấp trên đây vào các mục đích sau:",
    check_the_box_if_agree: "Tích vào ô nếu đồng ý",
    Contents: "Nội dung",
    Telehealth: "Khám từ xa",
    clinic_visit: "Khám tại phòng khám",
    delete_account: "Xóa bỏ",
    patinet_parent_or_guardian_sign:
      "CHỮ KÝ XÁC NHẬN CỦA BỆNH NHÂN TỪ ĐỦ 07 TUỔI TRỞ LÊN HOẶC NGƯỜI GIÁM HỘ",
    do_you_want_to_select_doctor:
      "Bạn muốn chọn bác sĩ hay tiếp tục với bác sĩ nào",
    clinic_list: "HỆ THỐNG PHÒNG KHÁM",
    clinic_list_: "Thông tin Phòng khám / Đặt hẹn",
    modern_tech_and_state:
      "KỸ THUẬT HIỆN ĐẠI VÀ TRANG THIẾT BỊ Y KHOA TIÊN TIẾN HỖ TRỢ CHẨN ĐOÁN CHÍNH XÁC",
    view: "Xem",
    book_now: "ĐẶT HẸN",
    view_details: "XEM CHI TIẾT",
    anywhere_easy:
      "Ở bất cứ Nơi đâu – Dễ dàng – Tiện lợi để có được lời khuyên tốt nhất cho sức khỏe của bạn và gia đình từ đội ngũ bác sĩ giàu kinh nghiệm của Victoria Healthcare",
    feature_doctors: "BÁC SĨ CỦA CHÚNG TÔI",
    VISION: "TẦM NHÌN",
    to_be_the_standard:
      "Trở thành nhà tiên phong chăm sóc sức khỏe cho người Việt.",
    to_provide_quality:
      "Mang đến dịch vụ chăm sóc y tế tận tâm, chất lượng cao với chi phí hợp lý cho người Việt.",
    MISSION: "SỨ MỆNH",
    top10_health_packages:
      " TOP 10 GÓI KHÁM ĐƯỢC LỰA CHỌN NHIỀU NHẤT TẠI VICTORIA HEALTHCARE",
    VALUES: "GIÁ TRỊ DỊCH VỤ",
    Care_C: "C - Cải tiến không ngừng quy trình và chất lượng chăm sóc y tế.",
    Care_A: "A - Áp dụng kỹ thuật và kiến thức chăm sóc y tế tiến bộ.",
    Care_R:
      "R - Rèn luyện văn hóa chăm sóc bệnh nhân bằng sự thấu hiểu, đồng cảm.",
    Care_E:
      "E - Ekip bác sĩ được đào tạo liên tục - cam kết thực hành y khoa trên nền tảng y học chứng cứ.",
    vision_mission: " TẦM NHÌN, SỨ MỆNH,",
    core_values: "GIÁ TRỊ CỐT LÕI",
    about_us_para4:
      "Phương châm hoạt động của Victoria Healthcare là “Tất cả vì Bệnh nhân”.",
    about_us_para3: "“Patients First” – “Tất cả vì Bệnh nhân",
    about_us_para2:
      " Hệ thống phòng khám Victoria Healthcare có nhiều chi nhánh tại các vị trí giao thông thuận tiện trong thành phố, đầy đủ các chuyên khoa, phục vụ chức năng thăm khám và điều trị ngoại trú. Cơ sở được đầu tư trang thiết bị hiện đại theo tiêu chuẩn Mỹ, Châu Âu, Nhật Bản được nhập khẩu từ Mỹ, Nhật, Anh, Đức… Đội ngũ y bác sĩ năng động, tâm huyết, nhiều năm kinh nghiệm, được liên tục đào tạo tại Mỹ, Úc, Nhật… và cam kết thực hành y khoa trên nền tảng Y học chứng cứ.",
    about_us_cap: "VỀ CHÚNG TÔI",
    about_us_para1:
      "Victoria Healthcare thành lập từ năm 2005 đến nay đã trải qua chiều dài kinh nghiệm trong lĩnh vực chăm sóc sức khỏe y tế cao cấp và tạo được sự tín nhiệm cao đối với khách hàng trong khu vực TP Hồ Chí Minh cũng như các tỉnh lân cận.",
    victoria_address_1:
      "Cơ sở 1: 37-39 Lương Định Của, P. An Khánh, TP Thủ Đức, TP.HCM",
    victoria_address_2:
      "Cơ sở 2: 20-20Bis-22 Đinh Tiên Hoàng, P. Đa Kao, Quận 1, TP.HCM",
    victoria_address_3:
      "Cơ sở 3: 135A Nguyễn Văn Trỗi, Phường 11, Q. Phú Nhuận, TP.HCM",
    victoria_address_4:
      "Cơ sở 4: 1056 Nguyễn Văn Linh, Sky Garden 1, Phú Mỹ Hưng, Quận 7, TP.HCM",
    vic_health_int_clinic: "PHÒNG KHÁM QUỐC TẾ VICTORIA HEALTHCARE",
    contact_information: "THÔNG TIN LIÊN HỆ",
    your_order_is_confirmed:
      "Cuộc hẹn của bạn đã được xác nhận, vui lòng kiểm tra thông tin tại ” tài khoản của tôi/ đơn hàng của tôi",
    patient_report_url: "Bệnh án điện tử",
    fill_form_1a_1b: "Đăng ký khám bệnh",
    fill_form1a: "1A - Phiếu đăng ký thông tin bệnh nhân (lần đầu)",
    fill_form1b: "1B - Phiếu đăng ký thông tin bảo hiểm sức khỏe",
    sec_card_validity: "Secondary Card Validity",
    sec_insurance_card_no: "Secondary Insurance Card Number",
    patient_parent_guardian_name: "CHỮ KÝ MẪU CỦA BỆNH NHÂN/NGƯỜI GIÁM HỘ",
    i_assure_that:
      "Tôi cam kết đã đọc, hiểu và chấp nhận các điều khoản nêu trên",
    by_signing_below:
      "Bằng việc ký tên vào ô chữ ký mẫu dưới đây, tôi xác nhận đã đọc, hiểu và đã được tạo điều kiện giải đáp các thắc mắc liê quan đến các điều khoản nêu trên cũng như mục đích sử dụng thông tin hô sơ đăng ký khám bệnh này. Đồng thời, tôi đồng ý chấp thuận cho Phòng khám được sử dụng chữ ký xác nhận của tôi dưới đây làm chữ ký mẫu đã được xác thực bởi cá nhân tôi làm cơ sở đối chiếu cho các trường hợp cằn thiết sau này nhằm loại trừ các ý đồ/ hành vi cố tình giả mạo thông tin cá nhân của tôi để trục lợi bảo hiểm hoặc vì mục đích trục lợi không chính đáng khác.",
    i_fully_understand:
      "2.Tôi hoàn toàn hiểu và cam kết chịu trách nhiệm thanh toán các khoản chi phí phát sinh đã sử dụng ngoài phạm vi Bảo hiếm (mức miễn thường, khoản phí đồng chi trả hoặc chi phí phát sinh do bệnh có sẵn, tình trạng/ bệnh/thuốc thuộc danh mục loại trừ Bảo hiểm, chi phí không thuộc quyền lợi bảo hiểm hoặc chi phí do Công ty Bảo hiểm từ chối thanh toán/ BLVP",
    in_case_of_req_for_direct_billing:
      "1.Trong trường hợp thực hiện thủ tục thanh toán Bảo hiểm hoặc sử dụng dịch vụ Bảo Lãnh Viện Phí tại VHC, tôi hoàn toàn đồng ý chấp thuận quy trình, thủ tục hiện hành của Phòng khám và yêu cầu chung của Công ty Bảo Hiểm, bao gồm: cung  cấp đầy đủ chứng từ Bảo hiểm hợp lệ (thẻ Bảo hiểm còn hiệu lực), giấy tờ tùy thân và chấp nhận đóng các khoản phí tạm thu khi Phòng khám chưa nhận được xác nhận BLVP từ Công ty Bảo hiếm của tôi.",
    terms_and_conditions: "CÁC ĐIỀU KHOẢN VÀ ĐIỀU KIỆN",
    patient_is_subscriber: "Bệnh nhân là người đứng tên bảo hiểm",
    pri_card_validity: "Primary Card Validity",
    re_schedule: "Sắp xếp lại",
    signup: "Weird",
    doctor: "Bác sĩ",
    my_wallet: "Ví của tôi",
    order_id: "Mã đơn hàng",
    transaction_status: "Trạng thái giao dịch",
    transaction: "Trạng thái",
    amount: "Số tiền",
    order_cancelled: "Đặt hàng đã hủy",
    added_by_admin: "Thêm bởi quản trị viên",
    order_processed: "Đặt hàng thành công",
    experience: "Kinh nghiệm",
    education: "Giáo dục",
    research_publication_rewards: "Nghiên cứu, xuất bản và giải thưởng",
    phone: "Điện thoại",
    //New Entry Form
    home: "Trang chủ",
    members: "Các thành viên/ Bệnh án điện tử",
    my_appointments: "Cuộc hẹn của tôi",
    my_prescription: "Tư vấn",
    reports: "Báo cáo",
    orders_history: "Lịch sử đơn hàng",
    cart: "Giỏ hàng",
    wallet: "Ví của tôi",
    logout: "Đăng xuất",
    login: "Đăng nhập",
    forgot_password: "Quên mật khẩu?",
    password: "Mật khẩu",
    login_otp: "Đăng nhập bằng OTP",
    mobile_number: "Điện thoại",
    name: "Họ và tên",
    email: "Email",
    preferred_language: "ngôn ngữ ưa thích",
    date_of_birth: "Ngày sinh",
    gender: "Giới tính",
    marital_status: "Tình trạng hôn nhân",
    ward: "Khu vực",
    city: "Thành phố/Tỉnh thành",
    district: "Quận/ Huyện",
    zip: "Zip",
    address: "Địa chỉ",
    edit_profile: "Chỉnh sửa hồ sơ",
    update_password: "Cập nhật mật khẩu",
    deactivate_account: "Hủy kích hoạt tài khoản",
    country: "Quốc gia",
    agree_terms_condition: "Iôi đồng ý với Điều khoản và điều kiện",
    save: "Lưu",
    upload_photo: "Tải hình ảnh",
    choose_file: "Chọn tập tin",
    new_password: "Mật khẩu mới",
    confirm_new_password: "Xác nhận mật khẩu mới",
    deactivate_your_account: "Hủy kích hoạt tài khoản của bạn",
    add_member: "Thêm thành viên",
    view_details: "Xem chi tiết",
    member_details: "Chi tiết thành viên",
    cmnd: "Số CMND",
    passport: "Tài liệu quốc tịch",
    edit: "Chỉnh sửa thông tin",
    past_appointments: "Lịch sử cuộc hẹn",
    type: "Loại",
    consultation_room: "Tư vấn khám bệnh trực tuyến",
    upcoming_appointments: "Các cuộc hẹn sắp tới",
    consultation_summary: "Tóm tắt tư vấn khám bệnh",
    patient: "Tên bệnh nhân",
    download_consultation_summary: "Tải xuống Tóm tắt tư vấn khám bệnh",
    my_lab_report: "Báo cáo phòng thí nghiệm của tôi",
    download_report: "Tải xuống báo cáo",
    my_orders: "Đơn hàng của tôi",
    download: "Tải xuống",
    my_cart: "Giỏ hàng",
    book_appointment: "Đặt lịch hẹn",
    health_services: "Gói dịch vụ",
    health_checkup: "Kiểm tra sức khỏe",
    general_physician: "Bác sĩ đa khoa",
    ivf: "IVF",
    mother_child_care: "Chăm sóc bà mẹ và trẻ em",
    choose_speciality: "Chọn chuyên khoa",
    select_patient: "Chọn bệnh nhân",
    add_patient_symptoms: "THÊM CÁC TRIỆU CHỨNG CỦA BỆNH NHÂN",
    book_slot: "Đặt chỗ",
    schedule_appointment: "lịch hẹn",
    appointment_date: "Ngày hẹn",
    appointment_time_slot: "Khoảng thời gian cuộc hẹn",
    consultancy: "Trực tuyến",
    add_to_cart: "Thêm vào giỏ hàng",
    price: "Đơn giá",
    total: "Toàn bộ",
    apply_discount_code: "Áp dụng mã giảm giá",
    cart_total: "Tổng cộng",
    cart_subtotal: "Tổng tiền hàng",
    discount_amount: "Giảm giá",
    admin_fee: "Phí quản trị",
    order_total: "Tổng tiền thanh toán",
    proceed_to_payment: "Tiếp tục thanh toán",
    order_details: "Chi tiết thanh toán",
    billing_address: "Địa chỉ trên hóa đơn",
    payment: "Thanh toán",
    company_name: "Tên công ty",
    company_address: "Địa chỉ",
    tax_code: "Mã số thuế",
    continue_to_checkout: "Tiếp tục thanh toán",
    wallet_balance: "Số dư ví",
    hospital_contact_no: "số liên lạc của bệnh viện",
    //new google transalate entries
    online_appointment: "Cuộc hẹn trực tuyến",
    offline_appointment: "Cuộc hẹn ngoại tuyến",
    appointment_with: "Hẹn với",
    labtest: "Khám sức khỏe",
    apply: "Sử dụng",
    promo_code: "Mã khuyến mại",
    relation: "Quan hệ",
    id_proof: "Chứng minh thư",
    edit_details: "Chỉnh sửa chi tiết",
    upload_medical_history: "Tải lên lịch sử y tế",
    upload_document: "Tải tài liệu",
    medical_history: "Tiền sử bệnh",
    cmnd_proof: "Chứng minh thư",
    full_name: "Họ và tên",
    upload_image: "Tải hình ảnh",
    relationship: "Mối quan hệ",
    educational_qualification: "Mối quan hệ",
    allergies: "Dị ứng (nếu có)",
    vaccine: "Vắc xin",
    vacine: "Vắc xin",
    member_added_successfully: "Đã thêm thành viên thành công",
    something_went_wrong: "Đã xảy ra sự cố",
    required_field: "Phần bắt buộc",
    speciality: "Chọn chuyên khoa",
    consultation: "Đơn thuốc",
    pickup_address: "Chọn địa chỉ",
    or: "Hoặc là",
    top_nearby_hospitals: "Bệnh viện hàng đầu lân cận",
    see_all: "Xem tất cả",
    nothing_here: "dữ liệu trống",
    order_initiated: "Đơn hàng đã bắt đầu",
    paid: "Đã thanh toán",
    select_hospital: "Chọn bệnh viện",
    collection_time_slot: "Khung giờ hẹn",
    select_member: "Chọn thành viên",
    morning: "Buổi sáng",
    afternoon: "Buổi chiều",
    choose_address_or_hospital: "Chọn địa chỉ hoặc bệnh viện",
    added_to_cart_successfully: "Thêm vào giỏ hàng thành công",
    member_added_please_select_member:
      "Đã thêm thành viên thành công, vui lòng chọn thành viên để đặt!",
    book_package_for_covid: "Đặt một gói để Kiểm tra Covid",
    hospital: "Phòng khám",
    profile: "Hồ sơ",
    select_doctor: "Chọn bác sĩ",
    doctor_details: "Thông tin chi tiết về bác sĩ",
    health_checkup_packages: "Gói khám và kiểm tra sức khoẻ",
    select_time: "Chọn thời gian",
    select_mode: "Chọn chế độ",
    symptoms: "Các triệu chứng",
    appointment_booked: "Đã đặt lịch hẹn",
    online: "Trực tuyến",
    offline: "Ngoại tuyến",
    remove_coupan: "Xóa phiếu giảm giá",
    customer_name: "tên khách hàng",
    tax_information: "Thông tin về thuế",
    order_confirmation: "Xác nhận đơn hàng",
    transaction_details: "chi tiết giao dịch",
    shipping_address: "Địa chỉ giao hàng",
    quantity: "Số lượng",
    labtest_date: "Ngày kiểm tra phòng thí nghiệm",
    appointment_type: "Loại cuộc hẹn",
    sample_collection_time: "Thời gian thu thập mẫu",
    patient_name: "Họ và tên",
    checkin_code: "Mã CheckIn",
    total_items_amount: "Tổng số Khoản mục",
    items_amount: "Số lượng mặt hàng",
    use_wallet: "Sử dụng ví",
    user_details: "Thông tin người dùng",
    document_uploaded_successfully: "Tài liệu được tải lên thành công",
    upload: "Tải lên",
    choose_profile_image: "Chọn hình ảnh hồ sơ",
    order_number: "Số đơn hàng",
    transaction_source: "Nguồn giao dịch",
    wallet_amount_used: "Số tiền trên Wallet đã sử dụng",
    order_amount: "Số lượng đơn đặt hàng",
    please_enter_otp: "Vui lòng nhập OTP",
    mobile_number_validation: "Phải có từ 9 đến 10 ký tự",
    health_packages: "Gói sức khỏe",
    search_place_holder: "Phòng khám / Bác sĩ / Chuyên khoa / Gói",
    my_wellness: "Sức khỏe của tôi",
    thankyou_message:
      "Cám ơn yêu cầu đặt hẹn của bạn. Chúng tôi sẽ liên hệ để xác nhận trong thời gian sớm nhất",
    empty_cart: "Giỏ hàng trống",
    coupan_applied_successfully: "Mã phiếu giảm giá đã được áp dụng thành công",
    collection_date: "Ngày thực hiện",
    member_name: "Tên thành viên",
    select: "Chọn",
    report_delivery: "Gửi báo cáo",
    highlight_packages: "Gói nổi bật",
    search_package: "Gói Tìm kiếm",
    order_not_exist: "Đơn hàng không tồn tại",
    order_already_processed: "Đơn hàng đã được xử lý",
    invalid_amount:
      "Đơn đặt hàng của bạn không thể được xử lý vì số tiền không hợp lệ",
    order_cancelled_by_user: "Người dùng đã hủy giao dịch",
    invalid_number: "Số không hợp lệ",
    mobile_no: "Vui lòng nhập số điện thoại di động",
    invalid_otp: "OTP không hợp lệ",
    member_updated: "Đã cập nhật thành công thành viên",
    terms_conditions: "Bằng cách đăng nhập hoặc đăng ký, bạn đồng ý với",
    privacy_policy: "Chính sách bảo mật",
    operation_policy: "Chính sách bảo mật",
    incorrect_password: "Mật khẩu không đúng",
    submit: "Gửi đi",
    covid19_test: "Kiểm tra COVID-19",
    update_member_details: "Cập nhật chi tiết thành viên",
    email_address: "Địa chỉ email",
    invalid_email: "Email không hợp lệ",
    consultant: "Bác sĩ tư vấn",
    vat_invoice: "Hoá đơn thuế GTGT",
    invalid_date: "Ngày không hợp lệ",
    doctor_login: "Đăng nhập bác sĩ",
    hospital_login: "Đăng nhập bệnh viện",
    // new entry for member's medical history
    member_medical_history: "Lịch sử y tế thành viên",
    hospital_name: "Ghi chú",
    patient_id: "Mã bệnh nhân",
    doctor_name: "Tên bác sĩ",
    report_date: "Ngày",
    upload_report:
      "Tải tài liệu (Định dạng tệp: jpg, jpeg, png, pdf | Kích thước tối đa: 2mb)",
    member_medical_history_added_successfully:
      "Đã thêm thành công lịch sử y tế của thành viên",
    sorry_no_doctor:
      "Rất xin lỗi ! Không Bác sĩ, Vui lòng thử thay đổi từ khóa tìm kiếm của bạn",
    description: "Sự miêu tả",
    we_will_connect_soon: "Chúng tôi sẽ kết nối sớm",
    partner_with_us: "Hợp tác với chúng tôi",
    vaccine_dose: "Liều lượng vắc xin",
    do_not_have_member: "Không có một thành viên?",
    date_for_vaccination: "Chọn Ngày tiêm chủng",
    vaccine_brand: "Nhãn hiệu vắc xin",
    select_dose: "Chọn liều lượng",
    vaccination_center: "Trung tâm tiêm chủng",
    consultancy_fees: "Phí tư vấn",
    vaccine_date_time: "Ngày và giờ tiêm vắc xin",
    time: "Thời gian",
    vaccination_details: "Chi tiết tiêm chủng",
    registration: "Đăng ký",
    register_vaccine: "Đăng ký vắc xin",
    pay_online: "Thanh toán trực tuyến",
    pay_by_cash: "Thanh toán bằng tiền mặt",
    payment_mode: "Phương thức thanh toán",
    PayByCash: "Trả bằng tiền mặt",
    book_date: "Ngày hẹn",
    coming_soon: "Sắp có",
    video_consult: "Tư vấn video cho Covid-19",
    covid_symptoms: "Các triệu chứng của Covid-19:",
    book_covid_consultation: "Đặt lịch tư vấn Covid-19",
    covid_consult: "Tham vấn Covid 19",
    cmnd_passport: "CCCD/ Mã định danh",
    scheduled_on: "Đã lên lịch vào",
    qty: "Số lượng",
    login_first: "Bạn phải đăng nhập để thêm mặt hàng vào giỏ hàng",
    cancel_vaccination_appointment: "Hủy tiêm chủng",
    yes: "Có",
    no: "Không",
    days_left: "Ngày còn lại",
    certificate: "Chứng chỉ",
    not_vaccinated: "Không được tiêm chủng",
    partially_vaccinated: "Đã được tiêm phòng một phần",
    vaccinated: "Đã tiêm phòng",
    cancel: "Hủy",
    schedule: "Lịch trình",
    due_date: "Ngày đáo hạn",
    year_of_birth: "Năm sinh",
    years: "năm",
    shipping_same_as_billing: "Địa chỉ giao hàng giống địa chỉ thanh toán",
    covid_19_kit: "Bộ xét nghiệm Covid-19",
    vaccination: "Tiêm chủng",
    nutrition_fitness: "Dinh dưỡng & thể hình",
    no_result: "Ko có kết quả",
    ok: "Được",
    no_file_chosen: "Không có tập tin nào được chọn",
    high_to_low: "Cao đến thấp",
    low_to_high: "Thấp đến cao",
    clinic: "Phòng khám y tế",
    all: "Tất cả các",
    hospitals: "Phòng khám",
    search_by_city: "Tìm kiếm theo Thành phố",
    near_me: "Gần tôi",
    search_by: "Tìm kiếm theo",
    near_here: "Gần đây",
    no_vicinity_result:
      "Ko có kết quả. Vui lòng thay đổi tìm kiếm hoặc thành phố của bạn",
    featured_doctors: "Bác sĩ nổi bật",
    footer: "Nâng Tầm Y Tế Cộng Đồng",
    view_on_map: "Xem trên bản đồ",
    about_us: "Về chúng tôi",
    know_about_health: "Biết về sức khỏe",
    view_all_health_packages: "Xem tất cả các gói sức khỏe",
    test_date: "Ngày đặt hẹn",
    test_time: "Thời gian kiểm tra",
    covid_19_consultancy: "Tư vấn Covid 19",
    sorry_no_packages:
      "Xin lỗi ! Không tìm thấy gói Sức khỏe nào. Hãy thử thay đổi tùy chọn tìm kiếm hoặc bộ lọc của bạn.",
    report: "Tài liệu",
    not_available: "Không có",
    chosen_file_name: "Chosen file name",
    select_document_type: "Chọn loại tài liệu",
    document_type: "loại tài liệu",
    select_date_time: "Chọn ngày và giờ",
    no_slots_available: "Không có chỗ trống",
    appointment_time: "Giờ hẹn",
    consultation_date: "Ngày tham vấn",
    date: "Ngày",
    my_consultations: "Tư vấn của tôi",
    update_member: "Thêm thành viên",
    please_select_time: "Vui lòng chọn thời gian",
    paid_online: "Thanh toán trực tuyến",
    view_all_hospitals: "Xem tất cả các bệnh viện",
    covid_19_consultation: "Tham vấn Covid 19",
    covid_19_testing: "Xét nghiệm Covid 19",
    covid_19_coming_soon: "Tiêm chủng Covid 19 Sắp ra mắt",
    covid_19_consultation_1: "Tham vấn ",
    covid_19_consultation_2: "Covid 19",
    covid_19_testing_1: "Xét nghiệm ",
    covid_19_testing_2: "Covid 19",
    some_appointment_slots_not_available:
      "Các khe thời gian cho cuộc hẹn không khả dụng, vui lòng xóa chúng",
    go_to_cart: "Đi tới xe đẩy hàng",
    user_updated: "Đã cập nhật người dùng thành công",
    selected_doctor: "Bác sĩ được chọn",
    coronavirus_19: "Tư vấn Coronavirus (COVID-19)",
    virtual_consultation: "từ xa",
    only: "CHỈ",
    save_time_money:
      "Tiết kiệm thời gian và tiền bạc - Không cần chờ đợi - Được chăm sóc, điều trị khẩn cấp tại nhà!",
    concerned_have_cororna:
      "Bạn lo lắng hoặc nghi ngờ rằng bạn đã lây nhiễm Covid-19?",
    get_an_immediate:
      "Chúng tôi nhận tư vấn từ xa theo yêu cầu ngay lập tức, chẩn đoán sàng lọc và lên kế hoạch điều trị Covid-19. Các chuyên gia y tế sẽ đánh giá tình trạng của bạn và đưa ra phương pháp điều trị dựa trên các hướng dẫn của CDC và các phương pháp hay nhất.",
    dont_waste_time: "Đừng lãng phí thời gian trong phòng chờ đợi đầy vi trùng",
    receive_high_quality:
      "Hãy nhận dịch vụ chăm sóc chất lượng cao ngay tại nhà",
    medical_professionals:
      "Đội ngũ chuyên gia y tế sẵn sàng phục vụ bạn từ 7 giờ sáng - 10 giờ tối, các ngày trong tuần dựa vào lịch trình còn trống.",
    easily_manage: "Dễ dàng quản lý hồ sơ bệnh án điện tử của bạn",
    prescription_sent: "Đơn thuốc được gửi cho bạn ngay sau cuộc gọi",
    information_related: "Thông tin liên quan đến COVID-19",
    corona_symptoms: "Triệu chứng COVID-19",
    if_you_have_any:
      "Nếu bạn có bất kỳ triệu chứng nào sau đây hoặc xét nghiệm dương tính, vui lòng tham vấn ý kiến bác sĩ NGAY về thuốc, biện pháp phòng ngừa, cách điều trị và chế độ dinh dưỡng. KHÔNG NÊN TỰ Ý điều trị khi chưa tham khảo ý kiến bác sĩ.",
    long_coronavirus: "Hội chứng COVID-19 kéo dài",
    symptoms_of_long_covid:
      "Các triệu chứng của hội chứng COVID kéo dài giống như COVID-19, vì nó ảnh hưởng đến tất cả các hệ thống cơ quan",
    related_news_covid: "Related news about Coronavirus (COVID-19)",
    if_you_have_any_of:
      "If you have any of these symptoms or tested positive, please consult for medication, precautions and nutrition. DO NOT get any Risks by self-medication practices.",
    view_all: "Xem tất cả",
    rapid_covid_19: "Kiểm tra nhanh Coivd-19",
    results_in_15_minutes: "Có kết quả sau 15 phút",
    standard_pcr: "Kiểm tra PCR Covid19 tiêu chuẩn",
    high_accuracy: "Độ chính xác cao",
    read_top_articles:
      "Đọc các bài báo hàng đầu từ các Chuyên gia Y tế của chúng tôi",
    our_services: "Dịch vụ của chúng tôi",
    private_online_consulation:
      "Tư vấn trực tuyến riêng và xét nghiệm với các bác sĩ đã được xác minh",
    covid_19_at_hospital: "Xét nghiệm COVID-19 TẠI BỆNH VIỆN",
    covid_19_at_home: "Xét nghiệm Covid-19 tại Nhà",
    we_offer_covid_19_test_home:
      "Chúng tôi cung cấp các mẫu xét nghiệm Covid-19 tại nhà,",
    test_office: "văn phòng, những cơ sở kinh doanh phục vụ tại chỗ.",
    available_in_ho_chi:
      "(Có sẵn tại khu vực quận 7, Phòng khám JaMi, Tp. Hồ Chí Minh)",
    we_got_you_covered:
      "Hãy để chúng tôi kết nối bạn với đội ngũ bác sĩ nhiều năm kinh nghiệm!",
    corona_virus_symptoms: "Các triệu chứng của COVID-19 là gì?",
    according_to_center:
      "Theo Trung tâm Kiểm soát và Phòng ngừa Dịch bệnh (CDC), COVID-19 ảnh hưởng đến mỗi người theo những cách khác nhau. Những người bị nhiễm đã có một loạt các triệu chứng được báo cáo sau đây - các triệu chứng từ nhẹ đến nặng",
    symptoms_may_appear:
      "Các triệu chứng có thể xuất hiện từ 2-14 ngày sau khi phơi nhiễm vi-rút. Những người có các triệu chứng này có thể nhiễm COVID-19",
    fever_chills: "Sốt hoặc ớn lạnh",
    cough: "Ho",
    shortness_breath: "Hụt hơi hoặc khó thở",
    fatigue: "Mệt mỏi",
    muscle_ache: "Đau cơ hoặc đau người",
    headache: "Đau đầu",
    new_loss_of_taste: "Mới mất vị giác hoặc khứu giác",
    sore_threat: "Đau họng",
    congestion: "Ngạt mũi hoặc chảy nước mũi",
    nausea: "Buồn nôn hoặc nôn mửa",
    diarrhea: "Tiêu chảy",
    look_for_emergency_warning:
      "Các dấu hiệu cảnh báo khẩn cấp của COVID-19. Nếu có bất cứ dấu hiệu nào trong số này, hãy liên hệ cơ sở y tế cấp cứu ngay lập tức:",
    trouble_breathing: "Khó thở",
    persistent_pain: "Đau hoặc tức ngực thường xuyên",
    new_confusion: "Rối loạn tâm lý, mất phương hướng, mê sảng",
    inability_to_wake: "Không thể thức dậy hay duy trì sự tỉnh táo",
    pale_grey:
      "Da, móng tay hoặc môi nhợt nhạt, xám hoặc có màu xanh, tùy vào tông da.",
    for_information:
      "Chỉ dành cho mục đích thông tin. Tham khảo ý kiến ​​của nhà cung cấp dịch vụ chăm sóc của bạn  hoặc cơ quan y tế địa phương để được tư vấn.",
    call_your_medical:
      "Gọi cho nhà cung cấp dịch vụ y tế của bạn nếu có bất kỳ triệu chứng nghiêm trọng hoặc liên quan đến bạn.",
    long_covid_heading: "Hội chứng Hậu Covid-19 là gì? Dấu hiệu nhận biết?",
    long_covid_subheading:
      "Sau thời gian điều trị mắc COVID-19, mặc dù người bệnh đã phục hồi và có kết quả âm tính với SARS-CoV-2, nhưng có thể vẫn bị tác động từ những di chứng, biến chứng, sự tổn thương mà COVID-19 để lại. Theo Trung tâm Kiểm soát và Phòng ngừa Dịch bệnh (CDC), hội chứng COVID kéo dài hay hậu COVID-19 được hiểu là tình trạng bệnh nhân mặc dù đã hồi phục khỏi COVID-19 cấp tính nhưng các triệu chứng sau khi mắc COVID là một loạt các vấn đề mới về sức khỏe, đang tái phát hoặc đang diễn ra mà mọi người có thể gặp phải  trong khoảng bốn tuần trở lên sau lần đầu tiên bị lây nhiễm COVID-19. Thậm chí những người không có các triệu chứng COVID-19 trong nhiều ngày hoặc nhiều tuần sau khi bị lây nhiễm vẫn có thể mắc COVID-19 kéo dài. Những tình trạng này có thể xuất hiện ở các dạng khác nhau và có thể bị một vài bệnh cùng lúc trong khoảng thời gian khác nhau:",
    new_or_ongoing:
      " Các triệu chứng mới hoặc đang xảy ra của chứng hậu Covid-19 kéo dài:",
    difficulty_breathing: "Khó thở hoặc hụt hơi",
    tiredness: "Mệt mỏi hay chóng mặt",
    symptoms_get_worse:
      "Các triệu chứng sẽ trầm trọng hơn sau các hoạt động thể chất hoặc tinh thần (hay còn gọi là tình trạng khó chịu sau khi gắng sức)",
    difficulty_thinking:
      'Khó suy nghĩ hay tập trung (đôi khi còn được gọi là "sương mù não")',
    chest_stomach_pain: "Đau ngực hoặc đau dạ dày",
    fast_beating_heart:
      "Tim đập nhanh hoặc đập thình thịch (còn được gọi là trống ngực)",
    joint_muscle_pain: "Đau cơ hay khớp",
    pins_needles_feeling: "Cảm giác tê râm ran",
    sleep_problems: "Gặp vấn đề về giấc ngủ",
    fever: "Sốt",
    dizziness: "Chóng mặt khi đứng dậy (choáng váng)",
    rash: "Phát ban",
    mood_changes: "Thay đổi tâm trạng",
    smell_change: "Thay đổi về vị giác và khứu giác",
    change_menstrual: "Thay đổi về chu kỳ kinh nguyệt",
    day: "Ngày",
    year: "Năm",
    month: "Tháng",
    member_allergies: "Dị ứng (Nếu có)",
    covid_19_testing_at_your: "Xét nghiệm Covid-19 ngay",
    doorstep: "tại nhà!",
    private_online_consultation:
      "Tư vấn và xét nghiệm trực tuyến riêng với các bác sĩ đã được xác minh",
    consultants: "Tư vấn",
    return_and_refund_policy: "Chính sách trả hàng và hoàn tiền",
    payment_policy: "Chính sách thanh toán",
    home_testing_is_available:
      "Xét nghiệm Covid-19 tại nhà phục vụ trong khu vực quận 7, thành phố HCM. Phòng khám Jami hỗ trợ xét nghiệm.",
    nurse_medical_staff:
      "Các y tá hoặc nhân viên y tế sẽ đến ngay nhà bạn để lấy mẫu xét nghiệm PCR",
    test_result_will_be_available:
      "Có kết quả xét nghiệm trong vòng 36 - 48 giờ.",
    home_testing_can_only_booked:
      "Do như cầu tăng cao, chỉ hỗ trợ đặt xét nghiệm Covid-19 tại nhà cho ngày kế tiếp.",
    home_standard_pcr_test: "Xét nghiệm tiêu chuẩn PCR tại nhà 1,500,000VNĐ",
    telemedicine_consultation: "tư vấn khám bệnh từ xa",
    hospital_visit: "khám tại bệnh viện",
    about_iqi:
      "iQi kiến tạo nền tảng công nghệ trong ngành y tế, đưa khách hàng đến với bệnh viện và bác sĩ chỉ bằng một click trên điện thoại di động hoặc máy tính, giúp kết nối giữa bệnh nhân và nhà cung cấp nhanh hơn bao giờ hết",
    nearby_hospitals: "Bệnh viện gần đây",
    for_doctors: "Dành cho bác sĩ",
    register: "Đăng ký",
    company: "Công ty",
    payment_options: "Phương thức thanh toán",
    appointments: "Các cuộc hẹn",
    for_patients: "Dành cho bệnh nhân",
    disclaimer: "Tuyên bố từ chối trách nhiệm",
    cancellation_policy: "Chính sách huỷ",
    contact: "Số liên lạc",
    covid_19_test_hospitals:
      "Xét nghiệm hiện có tại Bệnh viện Vạn Hạnh, Phòng khám JaMi và Phòng Xét Nghiệm Y Khoa Diag",
    rt_covid_19_test: "Xét nghiệm nhanh Covid",
    pcr_covid_19_test: "Xét nghiệm PCR Covid",
    book_sample_collection: "Đặt lấy mẫu xét nghiệm",
    featured_doctor_footer: "Bác sĩ nổi bật",
    health_packages_footer: "Gói sức khỏe",
    address_type: "Loại địa chỉ",
    edit_address: "sửa địa chỉ",
    add_new_address: "Thêm địa chỉ mới",
    address_updated: "Cập nhật địa chỉ",
    my_addresses: "(Các) Địa chỉ của tôi",
    remove: "Xóa",
    select_delivery_address: "Chọn địa chỉ giao nhận",
    copyright: "© 2021 IQI Health. Bảo lưu mọi quyền.",
    select_billing_address: "Chọn địa chỉ trên hóa đơn",
    my_address: "Địa chỉ của tôi",
    covid_19_treatment: "Điều trị Covid -19",
    home_line_1: "Chẩn đoán kịp thời và tốt nhất",
    home_line_2: "Kết nối trực tiếp với bác sĩ của bạn",
    home_line_3: "Chuyên gia tư vấn y tế",
    home_heading: "Nhận giải pháp điều trị tốt nhất với IQI HEALTH",
    my_account: "Tài khoản của tôi",
    what_our_patients_say: "Bệnh nhân nói về chúng tôi",
    deactive_msg_1:
      "Sau khi tôi xóa ứng dụng, VIC sẽ xóa tất cả hồ sơ, lịch sử mua hàng và tất cả dữ liệu liên quan của tôi khỏi cơ sở dữ liệu. Tôi hiểu VIC sẽ không bao giờ chia sẻ dữ liệu của tôi với bất kỳ bên thứ ba nào",
    deactive_msg_2:
      "I understand iQi will never share my data with any third party.",
    account_deletion_alet_msg: "Bạn có thực sự muốn hủy tài khoản?",
    know_more: "Xem thêm",
    contact_us: "Liên hệ chúng tôi",
    contact_footer: "Số liên lạc",
    my_rating: "My Rating",
    feedback: "Phản hồi",
    add_comment_optional: "Nhập bình luận (Tùy chọn)",
    overall_rating: "Đánh giá tổng thể",
    rate_doctor_consultation: "Đánh giá cho bác sĩ",
    rate_health_package: "Đánh giá cho Gói kiểm tra sức khỏe",
    rate_iqi_app: "Đánh giá cho ứng dụng IQI",
    about_test: "Thông tin về xét nghiệm",
    pre_test_info: "Thông tin trước khi thực hiện xét nghiệm",
    diagnostic_powered_by_diag: "Kiểm tra xét nghiệm cung cấp bởi DIAG",
    thanyou_for_feedback: "Cảm ơn bạn đã đưa ra phản hồi",
    login_register: "Đăng nhập/Đăng ký",
    please_check_internet_connection:
      "Vui lòng kiểm tra kết nối Internet của bạn",
    get_covid_consultation_easily:
      "Thực hiện tư vấn Covid -19 một cách dễ dàng",
    get_covid_19_tested: "Kiểm tra Covid -19",
    select_healthcare_packages: "Chọn gói kiểm tra sức khỏe",
    loading: "Đang tải",
    something_wrong_page:
      "Rất tiếc! Có gì đó không đúng. Vui lòng quay lại trang chủ và thử lại.",
    consultaion_room_will_be_active_10_minutes_prior:
      "Cuộc gọi tư vấn sẽ chỉ được kích hoạt trước giờ hẹn 10 phút",
    uploading: "Đang tải",
    recommendations: "Gợi ý dành cho bạn",
    download_prescription: "Download Prescription",
    recommendation_by_doctor: "Do Bác sĩ giới thiệu",
    recommended_labtests: "Các Trung Tâm Xét Nghiệm được đề xuất",
    recommended_appointments: "Các Xét nghiệm được đề xuất",
    appointment_number: "Số cuộc hẹn",
    please_enter_valid_email: "Vui lòng cập nhật địa chỉ email hợp lệ",
    email_already_exists: "Email Already Exists",
    corporate_packages: "Gói khám sức khoẻ doanh nghiệp",
    select_corporate_packages: "Chọn gói công ty",
    sorry_no_corporate_packages:
      "Xin lỗi ! Không tìm thấy gói công ty nào. Hãy thử thay đổi tùy chọn tìm kiếm hoặc bộ lọc của bạn.",
    corporate_executive_health_packages:
      "Gói khám sức khoẻ dành cho doanh nghiệp",
    pay_instalment: "Trả góp qua thẻ tín dụng",
    book_now: "Đặt ngay",
    order_summary: "Tóm Tắt Đặt Hẹn",
    confirm_order: "Xác Nhận",
    sample_collection: "Mẫu đã được thu thập",
    medical_report: "Hồ sơ bệnh án",
    select_doctor: "Chọn bác sĩ",
    any_doctor: "Bác sĩ bất kỳ",
    you_have_booked: "Bạn đã thực hiện",
    out_of_3_order:
      " trong số 3 lần đặt hẹn. Trong trường hợp bạn thực hiện đến số lần hẹn tối đa, vui lòng liên hệ Hotline 028 3910 4545",
    patient_inforamtion: "THÔNG TIN BỆNH NHÂN",
    patient_Cell_phone_no: "Số điện thoại di động",
    patient_home_phone_no: "Số điện thoại nhà",
    home_address: "Địa chỉ nhà",
    zip_code: "Zip Code",
    name_of_patient_guardian:
      "Họ & Tên người giám hộ (nếu người đăng ký khám bệnh là trẻ em dưới 15 tuổi)",
    relation_to_patient: "Mối quan hệ với bệnh nhân",
    patient_guardian_cell_phone_no:
      "Số điện thoại liên lạc trong trường hợp khẩn cấp",
    your_provided_inforamtion:
      "Thông tin Quý khách cung cấp sau đây sẽ được bảo mật và chỉ sử dụng cho mục đích khám chữa bệnh tại phòng khám.",
    fill_in_capital_letters: "Vui lòng điền đầy đủ thông tin bằng chữ IN HOA.",
    subscrie_your_private_email_account:
      "Cung cấp địa chỉ E-mail và số điện thoại cá nhân để đăng ký sử dụng tài khoản BỆNH ÁN ĐIỆN TỬ miễn phí và nhận thông báo dịch vụ khác từ Phòng khám.",
    email_address: "Địa chỉ E-mail cá nhân",
    rewrite_email_address: "Xác nhận lại địa chỉ E-Mail",
    invoice_and_insurance_info:
      "THÔNG TIN CHỨNG TỪ THANH TOÁN VÀ HỒ SƠ BẢO HIỂM",
    consent: "CAM KẾT",
    i_hereby_declare:
      "Tôi đồng ý sử dụng tài khoản hộp thư điện tử (email address) và các thông tin cá nhân đã cung cấp trên đây làm cơ sở để Phòng khám Victoria Healthcare (Phòng khám) đăng ký cấp mã hồ sơ bệnh nhân, tài khoản bệnh án điện tử, gửi thông báo kết quả khám bệnh, tư vấn sức khoẻ, cung cấp thông tin sức khoẻ và chương trình chăm sóc khách hàng. Đồng thời, tôi hoàn toàn hiểu và chịu trách nhiệm bảo mật thông tin bệnh án của cá nhân tôi hoặc người phụ thuộc được uỷ quyền bằng cách thay đổi mật khẩu truy cập cổng thông tin bệnh án ngay sau khi được cung cấp bởi Phòng khám.",
    i_assure_all_info:
      "Tôi cam kết các thông tin nêu trên hoàn toàn xác thực và được cung cấp bởi cá nhân tôi.",
    form1a: "PHIẾU ĐĂNG KÝ HỒ SƠ KHÁM BỆNH",
    use_direct_billing: "Yêu cầu dịch vụ Bảo Lãnh Viện Phí",
    indicate_insurance_company: "Nếu có vui lòng cung cấp tên Công ty Bảo hiểm",
    request_for_insurance_claim: "Yêu cầu hồ sơ thanh toán Bảo hiểm",
    request_for_converting_traditional_vat:
      "Yêu cầu phát hành hóa đơn giấy chuyển đổi từ hóa đơn điện tử",
    request_for_additional_info_vat:
      "Yêu cầu bổ sung thông tin hiển thị trên hóa đơn tài chánh, ghi rõ thông tin bên dưới (nếu có)",
    request_for_social_certificate:
      "Yêu cầu cấp giấy nghỉ hưởng bảo hiểm xã hội, ghi rõ thông tin bên dưới (nếu có)",
    submit_form: "Gửi mẫu",
    form1b: "Form 1B",
    insurance_info: "insurance Information",
    pri_insurance_card_no: "Primary Insurance Card Number",
    memeber_under_18: "Bạn không thể thêm thành viên từ 18 tuổi trở lên. Vui lòng liên hệ hotline 028 3910 4545 để được hỗ trợ",
    survey_form: "Biểu mẫu khảo sát",
    survey_thank_you: "Bạn đã hoàn thành khảo sát này. Cảm ơn bạn",
  },
};
export default content;


