/* eslint-disable eqeqeq */
import moment from 'moment';
import axiosInstance from './utils/axiosBase'
import { CometChat } from "@cometchat-pro/chat";
import { getUserData, logout, SHOW_TOAST_MESSAGE, SHOW_TOAST_MESSAGE_SUCCESS } from './utils/utils';
import { AppIDCometChat, AuthKeyCometChat, RegionCometChat } from './static';
import { getToken } from './utils/utils';
import { useHistory } from 'react-router'
import Cookies from 'universal-cookie';


axiosInstance.interceptors.request.use(function (config) {
    const userId = getUserData('user_id');
    const token = getToken();
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    config.headers['Authorization'] = token
    return config;
}, (error) => {
    // Do something with request error
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(
  (response) => {
    //const url = response.config.url;

    //setLocalStorageToken(token);
    return response;
  },
  (error) => {
    console.log(error.response);
    if (error.response.status === 401) {
      console.log("error occured");

      const searchType = localStorage.getItem('searchType')
      const activeCityList = localStorage.getItem('activeCityList')
      localStorage.clear()
      localStorage.setItem('activeCityList', activeCityList)
      localStorage.setItem('searchType', searchType)
      localStorage.setItem('loginStatus', false)

      //(`unauthorized :)`);
      //localStorage.removeItem("persist:root");
      //removeLocalStorageToken
    //   if(window.location.href !=='#/login')
      window.location.href = "#/login";
    }
    return Promise.reject(error);
  }
);
/**
 * ! This API gives the Featured Doctor list which we are showing on Home page
 * @param {*} setIsLoading  :=> To set the loading state
 * @param {*} setDocotorsList :=> To set featured DoctorsList array we get from API Response
 */
export const getFeaturedDoctorsList = (setIsLoading, setDocotorsList, lang) => {
    axiosInstance.post(`/get_doctor_info`, {
        feature: '1', lang: lang, limit: '8', offset: '0'
    }
    )
        .then(function (response) {
            // handle success
            const responseData = response.data
            if (responseData.success) {
                setDocotorsList(responseData.data)
                setIsLoading(false)
            } else {
                setIsLoading(false)
                setDocotorsList(responseData.data)
            }
        })
        .catch(function (error) {
            // handle error
            setIsLoading(false)
            return error
        })
}

//this api saves user device info into db

export const saveUserDetails = (setIsLoading,userID,lang) => {
    axiosInstance.post(`/save_user_details`, {
        lang: lang,
        user_id: userID,
        device_uuid:localStorage.getItem('device_uuid'),
        device_model:localStorage.getItem('device_model'),
        device_version:localStorage.getItem('device_version'),
        device_manufacturer:localStorage.getItem('device_manufacturer'),
        device_serial:localStorage.getItem('device_serial'),
        device_platform:localStorage.getItem('device_type'),
        push_token:localStorage.getItem('push_token'),
    }
    )
        .then(function (response) {
            // handle success
            const responseData = response.data
            if (responseData.success) {
                setIsLoading(false)
            } else {
                setIsLoading(false)
            }
        })
        .catch(function (error) {
            // handle error
            setIsLoading(false)
            return error
        })
}
/**
 * ! This API Returns the array of Featured Hospitals which we show on Home page
 * @param {*} setIsLoading :=> To set the loading state
 * @param {*} setFeaturedHospitalsList :=> To set featured Hospital List  array we get from API Response
 */
export const getFeaturedHospitalsList = (setIsLoading, setFeaturedHospitalsList, lang) => {
    axiosInstance.post(`/get_alldata_info`, {
        "lang": lang, offset: "1", limit: "8", city: "", district: "", "searchvalue": ''
    }
    )
        .then(function (response) {
            // handle success
            const responseData = response.data
            if (responseData.status) {
                setFeaturedHospitalsList(responseData.Hospitaldata)
                setIsLoading(false)
            } else {
                setIsLoading(false)
                setFeaturedHospitalsList([])
            }
        })
        .catch(function (error) {
            // handle error
            setIsLoading(false)
            return error
        })
}

export const getUserNotifications = (setIsLoading, setNotificationsList, lang,user_id,setNotificationCount) => {
    axiosInstance.post(`/get_user_notifications`, {
        "lang": lang,user_id:user_id
    }
    )
        .then(function (response) {
            // handle success
            const responseData = response.data
            if (responseData.status) {
                setNotificationsList(responseData.data)
                setIsLoading(false)
                updateReadNotificationsStatus(lang,user_id,setNotificationCount,setIsLoading)
            } else {
                setIsLoading(false)
                setNotificationsList([])
            }
        })
        .catch(function (error) {
            // handle error
            setIsLoading(false)
            return error
        })
}

export const updateReadNotificationsStatus = (lang,user_id,setNotificationCount,setIsLoading) => {
    axiosInstance.post(`/update_read_user_notifications`, {
        "lang": lang,user_id:user_id
    }
    )
        .then(function (response) {
            // handle success
            const responseData = response.data
            getUserNotificationCount(lang,user_id,setIsLoading,setNotificationCount)

        })
        .catch(function (error) {
            // handle error
            return error
        })
}
export const getUserNotificationCount = (lang,user_id,setIsLoading,setNotificationCount) => {
    axiosInstance.post(`/get_user_notification_count`, {
        "lang": lang,user_id:user_id
    }
    )
        .then(function (response) {
            // handle success
            const responseData = response.data
            if (responseData.status) {
                setIsLoading(false)
                setNotificationCount(responseData.count)
                window.set_badge_count(responseData.count)
            } else {
                setIsLoading(false)
            }
        })
        .catch(function (error) {
            // handle error
            setIsLoading(false)
            return error
        })
}
/**
 * ! This API returns the array of Featured Packages we show on Home Page
 * @param {*} setIsLoading :=> To set the loading state
 * @param {*} setFeaturedPackageList :=> To set featured Package List  array we get from API Response
 */
export const getFeaturedPackageList = (setIsLoading, setFeaturedPackageList, lang) => {
    axiosInstance.post(`/get_labtest_feature_list`, {
        lang: lang, offset: "1", limit: "8", category_id: '1'
    }
    )
        .then(function (response) {
            // handle success
            const responseData = response.data
            if (responseData.status) {
                setFeaturedPackageList(responseData.lab_services)
                setIsLoading(false)
            } else {
                setIsLoading(false)
                setFeaturedPackageList([])
            }
        })
        .catch(function (error) {
            // handle error
            setIsLoading(false)
            return error
        })
}
/**
 * ! This API Returns the list(Array) of blogs Which we show on the Home Page
 * @param {*} setIsLoading :=> To set the loading state
 * @param {*} setBlogList :=> To set Blog List array we get from API Response
 */
export const getBlogList = (setIsLoading, setBlogList, lang, limit, offset) => {
    setIsLoading(true)
    axiosInstance.post('/get_blogslist',
        { lang: lang, limit: limit, offset: offset }
    )
        .then(function (response) {
            // handle success
            const blogData = response.data
            if (blogData && blogData.data) {
                setBlogList(blogData.data)
            } else {
                setBlogList([])
            }
            setIsLoading(false)
        }).catch(function (error) {
            // handle error
            setIsLoading(false)
        })
}
/**
 * ! This API Returns the Object(Information) of a particular blog
 * @param {*} setIsLoading :=> To set the loading state
 * @setBlog :=> setState of view Blog data
 * @blogId :=> Id of the blog we want to see
 * @lang :=> language of the user
 */
export const getBlog = (setIsLoading, setBlog, blogId, lang) => {
    setIsLoading(true)
    axiosInstance.post('/get_blogs',
        { "lang": lang, blog_id: blogId }
    )
        .then(function (response) {
            // handle success
            const blogData = response.data
            if (blogData && blogData.data) {
                setBlog((blogData.data)[0])
            } else {
                setBlog([])
            }
            setIsLoading(false)
        }).catch(function (error) {
            // handle error
            setIsLoading(false)
        })
}
/**
 * ! Save Token Function,  to save FSM (For Push Notification) token in DB
 * *********************************************************
 */
const saveToken = () => {
    const fsmToken = localStorage.getItem('FSMTokenValue') ? localStorage.getItem('FSMTokenValue') : null
    let cordova = ""
    let model = ""
    let platform = ""
    let uuid = ""
    let version = ""
    let manufacturer = ""
    let is_virtual = ""
    let serial = ""
    cordova = localStorage.getItem('cordova') ? localStorage.getItem('cordova') : ""
    model = localStorage.getItem('model') ? localStorage.getItem('model') : ""
    platform = localStorage.getItem('platform') ? localStorage.getItem('platform') : ""
    uuid = localStorage.getItem('uuid') ? localStorage.getItem('uuid') : ""
    version = localStorage.getItem('cordava_version') ? localStorage.getItem('cordava_version') : ""
    manufacturer = localStorage.getItem('manufacturer') ? localStorage.getItem('manufacturer') : ""
    is_virtual = localStorage.getItem('is_virtual') ? localStorage.getItem('is_virtual') : ""
    serial = localStorage.getItem('cordova_serial') ? localStorage.getItem('cordova_serial') : ""
    const user_id = getUserData('user_id') ? getUserData('user_id') : ''
    axiosInstance.post('/browser_pushtoken', {
        browser_id: fsmToken,
        user_id: user_id,
        "browser_type": "", "browser_id_date": "", "id_type": "", "ip_address": "", "device_type": "android", "reg_url": "", device: "",
        "cordova": cordova, "model": model, "platform": platform, "uuid": uuid, "version": version, "manufacturer": manufacturer, "is_virtual": is_virtual, "serial": serial
    }
    ).then(response => {
        const queryResponse = (response.data);
        if (queryResponse.status) {
        } else {
        }
    }).catch(function (error) {
    })
}
/**
 * ! ************** !!!!!! AUTH API CALLS !!!!!! ************************
 * *******************************************************
 * ! Login Function For User's Login
 * @param {
 * } data
 * @param {*} e :=> This is On Click Event
 * @param {*} setIsLoggingIn :=> This sets the state that user has sent the request to login and api is being called
 * @param {*} history :=> Holds the history stack ie, pages user has visited or can visit
 */
export const onUserLogin = (data, e, setIsLoggingIn, history, langContent, lang) => {
    let withoutZero
    const mob = data.user_mobile
    if (mob[0] === 0) {
        const length = mob.length + 1
        withoutZero = mob.substring(1, length)
    }
    else {
        withoutZero = mob
    }
    const mobile = withoutZero
    const password = data.password
    setIsLoggingIn(true)
    localStorage.setItem('userMobile', mobile);
    if (password !== '') {
        /**
         *  ! This is Called When user is Logging In Using Password
         */
        axiosInstance.post('/user_login_email_mobile', {
            "user_detail": mobile, "lang": "en", 'user_password': password
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }).then(function (response) {
            const data = (response.data)
            if (data.status) {
                setIsLoggingIn(false)
                const user = data.data[0];
                localStorage.setItem('userDetails', JSON.stringify(user))
                localStorage.setItem('accessToken', data.token)
                localStorage.setItem('loginStatus', true)


                //temp
                // localStorage.setItem("device_type", "android");
                // localStorage.setItem("device_uuid", "sjfojsdjfjsd");
                // localStorage.setItem("device_model", "vivo");
                // localStorage.setItem("device_version", "14");
                // localStorage.setItem("device_manufacturer", "vivo");
                // localStorage.setItem("device_serial", "abcdef");
                // localStorage.setItem("push_token", "xyzzswsfsf");
                // saveToken()
                // checkCartItems(user.user_id)
                // history.goBack()
                if (localStorage.getItem('userPath')) {
                    history.push(localStorage.getItem('userPath'))
                    localStorage.removeItem('userPath')
                } else {
                    history.push('/myaccount')
                }
            } else {
                setIsLoggingIn(false)
                if(data.account_status==0){
                    SHOW_TOAST_MESSAGE(langContent.your_account_has_been_deactivated);

                }else{
                    SHOW_TOAST_MESSAGE(langContent.incorrect_password);

                }
            }
        }).catch(function (error) {
            setIsLoggingIn(false)
            SHOW_TOAST_MESSAGE(langContent.something_went_wrong);
        })
    }
    /**
     * ! This is Called When user is logging In using OTP
     */
    else {
        axiosInstance.post('/user_signup', {
            "user_mobile": mobile, "lang": lang, "latitude": "latitude", "longitude": "longitude"
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(function (response) {
            const data = (response.data)
            if (data.status) {
                setIsLoggingIn(false)
                history.push('/verify_otp');
            } else {
                setIsLoggingIn(false)
                if(data.account_status==0){
                    SHOW_TOAST_MESSAGE(langContent.your_account_has_been_deactivated);

                }else{
                    SHOW_TOAST_MESSAGE(langContent.you_have_reached_login_otp)
                }
            }
        }).catch(function (error) {
            // handle error
            setIsLoggingIn(false)
            SHOW_TOAST_MESSAGE(langContent.invalid_number)
        })
    }
};

//send OTP
export const sendOTP = (data, e, setIsLoggingIn, history, langContent, lang) => {
    let withoutZero
    const mob = data.user_mobile
    if (mob[0] === 0) {
        const length = mob.length + 1
        withoutZero = mob.substring(1, length)
    }
    else {
        withoutZero = mob
    }
    const mobile = withoutZero
    const password = data.password
    setIsLoggingIn(true)
    localStorage.setItem('userMobile', mobile);

    /**
     * ! This is Called When user is logging In using OTP
     */
        axiosInstance.post('/send_otp', {
            "user_mobile": mobile, "lang": lang, "latitude": "latitude", "longitude": "longitude"
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(function (response) {
            const data = (response.data)
            if (data.status) {
                setIsLoggingIn(false)
                SHOW_TOAST_MESSAGE_SUCCESS(langContent.otp_sent_successfuly)
                history.push('/verify_forgot_password_otp');
            } else {
                setIsLoggingIn(false)

                if(data.account_status==0){
                    SHOW_TOAST_MESSAGE(langContent.your_account_has_been_deactivated)
                }else{
                    SHOW_TOAST_MESSAGE(langContent.invalid_number)
                }
            }
        }).catch(function (error) {
            // handle error
            setIsLoggingIn(false)
            SHOW_TOAST_MESSAGE(langContent.invalid_number)
        })

};
/**
 * ! Get Hospital Details API, sets the State "setClinicData" And returns Hospital's Information
 * @lang :=> User's language
 * @clinicId :=> Selected Hospital's Id
 * @setClinicData :=> This state is set as the response of the API
 * @setIsLoading :=> This sets the loading state of the API
 */
export const getHospitalDetails = (lang, clinicId, setHospitalData, setIsLoading) => {
    axiosInstance.post(`/getclinic`,
        {
            lang: lang,
            dr_clinic_nice_name: clinicId
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }).then(async function (response) {
            const data = response.data
            if (data.status) {
                localStorage.setItem('selected_hospital', JSON.stringify(data.Hospitaldata))
                setHospitalData(data.Hospitaldata)
            }
            setIsLoading(false)
        })
}
/**
 * ! This API returns and set state for package Details
 * @labtestId :=> HealthPackage Id
 * @lang  :=> User's Language
 * @setHealthPackageDetails :=> Function to set health package Details
 * @setIsLoading :=> set API's Loading State
 */
export const getHealthPackageDetails = (labtestId, lang, setHealthPackageDetails, setIsLoading, setTimeSlots, type, setHospitalInfo, setResponseError) => {
    axiosInstance.post('/get_labdetails', { "nice_name": labtestId, "lang": lang },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                const packageDetails = (data.data)
                setHealthPackageDetails(packageDetails)
                setHospitalInfo(packageDetails.hospital[0])
                getHealthPackageTimeSlots(setTimeSlots, type, packageDetails.dr_clinic_id)
            }
            else {
                // error goes here from response
                setResponseError(true)
            }
            setIsLoading(false)
        }).catch(function (error) {
            setIsLoading(false)
            setResponseError(true)
        })
}
/**
 *  ! Returns and sets state of package List of particular hospital
 * @lang  :=> User's Language
 * @clinicId :=> Selected Hospital's Id
 * @searchKey :=> If user does In-Package list search
 * @setPackageList :=> function to set package list
 * @setFilterPackageList :=> function to set filtered Packages
 */
export const getHospitalPackageList = (lang, clinicId, searchValue, setPackageList, setIsLoading, setHospital, setResponseError, page, setHasMore, setPackageListLoading, setLoader) => {
    axiosInstance.post('/get_labtest_list_pagination',
        { "lang": lang, "dr_clinic_id": clinicId, "search_value": searchValue, "latitude": "latitude", "longitude": "longitude", "page_no": page,"packageType":"paid" }
    )
        .then(function (response) {
            const data = response.data
            // handle success
            if (data.status) {
                const packageData = (response.data.lab_services.test_list);
                // setPackageList(packageData)
                setHospital(response.data.lab_services.hospitalList)
                setPackageListLoading(false)
                setPackageList(prevBooks => {
                    return [...new Set([...prevBooks, ...packageData])]
                })
                // setIsLoading(false)
                setHasMore(packageData.length > 0)
                setIsLoading(false)
                setLoader(false)
            } else {
                setPackageList([])
                setResponseError(false)
                setHasMore(false)
                setPackageListLoading(false)
                setIsLoading(false)
                setLoader(false)
            }
        })
        .catch(function (error) {
            // handle error
            setResponseError(true)
            setIsLoading(false)
            setLoader(false)
            setPackageListLoading(false)

        })
}
/**
 * ! Get List of City where doctors and hospitals are Active
 */
export const getActiveCityList = () => {
    axiosInstance.post('/enable_city_list',
    ).then(response => {
        const cityObject = response.data
        localStorage.setItem("activeCityList", JSON.stringify(cityObject.data))
    }).catch(function (error) {
    })
}
/**
 * ! Get Doctor's Details and hospital he/she is assosciated with
 * @lang :=> User's language
 * @doctorId :=> Doctor's Id for whom we want the details
 * @clinicId :=> Clinic Id of the doctor's hospital
 * @setDoctorDetails :=> Function to set the doctor's details state
 * @setAddToCartActive :=> Set's the state if the add to cart button should be active or not if fees of doctor is not given then can't be added to the cart
 * @setHospitalCity :=> Function to set hospital's city
 * @setHospitalDistrict :=> Function to set Hospital's Dictrict
 *
 */
export const getDoctorDetails = (lang, setIsLoading, doctorId, clinicId, setHospitalData, setDoctorDetails, setAddToCartActive, setHospitalCity, setHospitalDistrict, setResponseError) => {
    axiosInstance.post('/get_doctor_details',
        { "dr_nice_name": doctorId, "lang": lang, dr_clinic_nice_name: clinicId }
    ).then(function (response) {
        const data = response.data
        if (data.status) {
            const details = data.data
            setHospitalData(data.Hospitaldata)
            setDoctorDetails(details)
            // setAddToCartActive(details.c_fees === '0' || details.c_fees == '' ? false : true)
            setAddToCartActive(true)
            if (lang === 'en') {
                setHospitalCity(data.Hospitaldata.city_name_en)
                setHospitalDistrict(data.Hospitaldata.district_name_en)
            } else {
                setHospitalCity(data.Hospitaldata.city_name_vn)
                setHospitalDistrict(data.Hospitaldata.district_name_vn)
            }
            setIsLoading(false)
        } else {
            setResponseError(true)
            setIsLoading(false)
        }
    }).catch(function (error) {
        // handle error
        setResponseError(true)
        setIsLoading(false)
    })
}
/**
 * ! This API will return the get the In-clinic package filter list ie, type of packages available upon which we can apply filter,
 * ! this is being used as "chips" in packageList
 *
 */
export const getClinicPackageTypes = (setPackageTypes) => {
    axiosInstance.post('/get_labservices',
    ).then(response => {
        if (response.data.status) {
            const labServicesTypes = response.data
            setPackageTypes(labServicesTypes.lab_services)
        } else {
            setPackageTypes([])
        }
    }).catch(function (error) {
    })
}
/**
 * ! This API will return the In-Clinic Filtered Package List
 */
export const getFilteredPackageList = (setPackageList, lang, searchValue, clinicId, orderBy, labServiceId, setIsLoading) => {
    axiosInstance.post('/healthpackages_by_services',
        { "lang": lang, "search_key": searchValue, "dr_clinic_id": clinicId, "order_by": orderBy, "lab_service_id": labServiceId }
    ).then(response => {
        if (response.data.status) {
            const labServicesTypes = response.data
            setPackageList(labServicesTypes.data)
        } else {
            setPackageList([])
        }
        setIsLoading(false)
    }).catch(function (error) {
        setIsLoading(false)
    })
}
/**
 * ! This API returns the array of members of any particular user
 * @param {userId}:=> this is user id of user , saved when user logs in
 * @param {setIsLoading}:=> sets the state if API is Loading or loading is done
 * @param {token}:=> this is token(Access Token) of user , saved when user logs in
 * @param {setMembersList}:=> this is function to set the state of membersList
 * @param {userMobile}:=> this is the mobile number of the user
 *
 */
export const getMembersList = (lang, userId, setIsLoading, token, setMembersList, userMobile) => {
    axiosInstance.post('/getmemberdata',
        {
            user_mobile: userMobile,
            lang: lang,
            user_id: userId
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                const members = data.memberdata
                setMembersList(members)
            }
            else {
                setMembersList([])
            }
            setIsLoading(false)
        }).catch(function (error) {
            setIsLoading(false)
        })
}
/**
 * ! This api returns member's Details Object
 * @param {userId}:=> this is user id of user , saved when user logs in
 * @param {setIsLoading}:=> sets the state if API is Loading or loading is done
 * @param {token}:=> this is token(Access Token) of user , saved when user logs in
 * @param {setMembersDetails}:=> this is function to set the details of the member
 * @param {userMobile}:=> this is the mobile number of the user
 */
export const getMemberDetails = (userId, lang, memberId, token, setMemberDetails, setIsLoading, setResponseError) => {
    axiosInstance.post('/get_memberdetailslist', {
        "user_id": userId, "lang": lang, "member_id": memberId
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then((response) => {
            const data = response.data
            if (data.status) {
                const memberData = data.data;
                setMemberDetails(memberData);
            }
            else {
                setMemberDetails([])
                setResponseError(true)
            }
            setIsLoading(false)
        }).catch(function (error) {
            setIsLoading(false)
            setResponseError(true)
        })
}


export const getForm1AUserInfo = (userId, lang, memberId, token, setMemberDetails, setIsLoading, setResponseError) => {
    axiosInstance.post('/get_user_form1a_info', {
        "user_id": userId, "lang": lang, "member_id": memberId
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then((response) => {
            const data = response.data
            if (data.status==true) {
                const memberData = data.data;
                console.log(memberData?.member_dob.split('/'));
                const dobArray = memberData?.member_dob.split('/')
                const month = dobArray[0]
                const day = dobArray[1]
                const year = dobArray[2]
                memberData.member_dob_day = day
                memberData.member_dob_month = month
                memberData.member_dob_year = year
                if(memberData?.consent_date){
                    const consentDate = memberData?.consent_date.split('-')
                    console.log(consentDate?.length);
                    const consent_year = consentDate[0]
                    const consent_month = consentDate[1]
                    const consent_day = consentDate[2]
                    memberData.consent_day = consent_day
                    memberData.consent_month = consent_month
                    memberData.consent_year = consent_year
                }
                setMemberDetails(memberData);
            }
            else {
                setMemberDetails([])
                setResponseError(true)
            }
            setIsLoading(false)
        }).catch(function (error) {
            setIsLoading(false)
            setResponseError(true)
        })
}

export const getForm1BUserInfo = (userId, lang, memberId, token, setMemberDetails, setIsLoading, setResponseError) => {
    axiosInstance.post('/get_user_form1b_info', {
        "user_id": userId, "lang": lang, "member_id": memberId
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then((response) => {
            const data = response.data
            if (data.status==true) {
                const memberData = data.data;
                const dobArray = memberData.member_dob.split('/')
                const month = dobArray[0]
                const day = dobArray[1]
                const year = dobArray[2]
                memberData.member_dob_day = day
                memberData.member_dob_month = month
                memberData.member_dob_year = year
                setMemberDetails(memberData);
            }
            else {
                setMemberDetails([])
                setResponseError(true)
            }
            setIsLoading(false)
        }).catch(function (error) {
            setIsLoading(false)
            setResponseError(true)
        })
}

export const getMedicalHistoryFormData = (userId, lang, memberId, token, setMemberDetails, setIsLoading, setResponseError) => {
    axiosInstance.post('/get_medical_history_data', {
        "user_id": userId, "lang": lang, "member_id": memberId
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then((response) => {
            const data = response.data
            if (data.status==true) {
                const memberData = data.data;
                const dobArray = memberData.member_dob.split('/')
                const month = dobArray[0]
                const day = dobArray[1]
                const year = dobArray[2]
                memberData.member_dob_day = day
                memberData.member_dob_month = month
                memberData.member_dob_year = year
                setMemberDetails(memberData);
            }
            else {
                setMemberDetails([])
                setResponseError(true)
            }
            setIsLoading(false)
        }).catch(function (error) {
            setIsLoading(false)
            setResponseError(true)
        })
}


/**
 * @param {userId}:=> this is user id of user , saved when user logs in
 * @param {memberId}:=> this is member Id of particular member
 * @param {token}:=> this is token(Access Token) of user , saved when user logs in
 * @param {setMembersReport}:=> this is function to set the Report data of particular member
 * @param {userMobile}:=> this is the mobile number of the user
 */
export const getMemberMedicalHistory = (userId, memberId, lang, setMemberReport, token) => {
    axiosInstance.post('/get_medicalhistory_list', {
        "user_id": userId, "member_id": memberId, "lang": lang
    },
        {
            headers: {
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                const getdata = data.data
                setMemberReport(getdata)
            }
        })
}

export const removeMemberDocument = (userId, memberId, lang,langContent, token,doctype,setMemberDetails,setIsLoading,setResponseError,setDeleteModelOpen) => {
    axiosInstance.post('/remove_member_document', {
        "user_id": userId, "member_id": memberId, "lang": lang,"doctype":doctype
    },
        {
            headers: {
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                const getdata = data.data
                SHOW_TOAST_MESSAGE_SUCCESS(langContent.document_deleted_succesfully)

                setDeleteModelOpen(false)
                getMemberDetails(userId, lang, memberId, token, setMemberDetails, setIsLoading, setResponseError)
                // setMemberReport(getdata)
            }
        })
}
/**
 * ! This API performs Id Proof (CMND proof and Id Proof) file upload of member
 * @param {fd} :=> this is form data(Form Data) object because it contains a file which is to be uploaded as id proof
 * @param {setFileUploadDialogOpen} :=> This will set the state to close the dialog when file upload is done
 */
export const memberIdProofUpload = (fd, token, setIsLoading, setFileUploadDialogOpen, lang, langContent,userId,memberId,setMemberDetails,setResponseError) => {
    axiosInstance.post('/upload_memberdocument', fd,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setIsLoading(false)
                // getMemberDetailsToResetForm(userId, lang, memberId, token, setIsLoading, reset,setMemberData)
                // getMemberDetails(userId, lang, memberId, token, setMemberDetails, setIsLoading, setResponseError)
            }
            else {
                setIsLoading(false)
            }
            setFileUploadDialogOpen(false)
            SHOW_TOAST_MESSAGE_SUCCESS("Document uploaded successfully!")
            setIsLoading(false)
        }).catch(function (error) {
            // handle error
            setIsLoading(false)
            console.log(error);
        })
}
/**
 * ! This API returns array of available appointment slots of doctor's
 */
export const getAvailableAppointmentSlots = (doctorId, clinicId, lang, setFreeSlots, setSelectedBookingDate, setIsLoading, setDoctorDetails, setErrorResponse) => {
    axiosInstance.post('/doctor_schedule_info',
        { "doctor_id": doctorId, "dr_clinic_id": clinicId, "lang": lang },
    ).then(function (response) {
        const data = response.data
        let slotsAvailable = []
        if (data.status==true) {

            if (Object.keys(data.doc_details).length > 0) {
                if(data.slots.length>0){
                    setFreeSlots(data.slots)
                    setSelectedBookingDate((data.slots)[0].date)
                }
                setDoctorDetails(data.doc_details)
            } else {
                setErrorResponse(true)
            }
        }
        else {
            setErrorResponse(true)
        }
        setIsLoading(false)
    }).catch(function (error) {
        setIsLoading(false)
        setErrorResponse(true)
    })
}
/**
 * ! This API is reponsible for booking the appointment
 * @param {fd}:=> This contains medical report and user details object
 * @param {token}:=> This is access token stored when user logs in
 */
export const bookDoctorAppointment = (fd, history, token, langContent, setIsLoading, setRequestPending,memberID) => {

    axiosInstance.post('/new_book_appointment', fd,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setIsLoading(false)
                SHOW_TOAST_MESSAGE_SUCCESS(langContent.appointment_booked)
                setRequestPending(false)
                if(data.form1a_filled==0){
                    history.push('/form1a?memberID='+memberID+'&redirected=yes');
                }else{
                    history.push('/cart')
                }
            }
            else {
                setIsLoading(false)
                if(data.statusCode==300){
                    SHOW_TOAST_MESSAGE(langContent.only_one_booking_allowed_per_day)
                }else if(data.statusCode ==900){
                    SHOW_TOAST_MESSAGE(langContent.appointment_details_already_added_to_cart)
                }else if(data.statusCode==400){
                    SHOW_TOAST_MESSAGE(langContent.maxmimum_limit_reached)

                }
                setRequestPending(false)
            }
            // history.push('/dashboard');
        }).catch(function (error) {
            // handle error
            setIsLoading(false)
            setRequestPending(false)
        })
}
/**
 * ! This API returns the items in the cart
 * @param {setAppointmentList}: => This sets the total appointments present(booked) in the cart
 * @param {setPackageList}:=> This sets the total packages booked by the user
 * @param {setPackageList}:=> This sets the total packages booked by the user
 * @param {token}:=> User's Access Token when logged In
 */
export const getMyCartData = (userId, lang, setAppointmentList, setPackageList, setCartPriceDetails, setIsLoading, token, setCartData, setShowRemoveCoupan, setPromoCodeInputValue, setIsCartEmpty, langContent, setIsPaymentAllowed,userSession,setProductList) => {
    setIsLoading(true)
    console.log(userSession);
    axiosInstance.post('/get_cartitem', { user_id: userId, lang,userSession:userSession },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setIsCartEmpty(false)
                setAppointmentList(data.apdata)
                setPackageList(data.labtest)
                setProductList(data.data)
                setCartPriceDetails(data.cartt)
                if (!data.paymentstatus) {
                    SHOW_TOAST_MESSAGE(langContent.some_appointment_slots_not_available)
                    setIsPaymentAllowed(false)
                } else {
                    setIsPaymentAllowed(true)
                }
                setCartData(data)
                if (parseInt(data.cartt.totaldiscount) > 0) {
                    setShowRemoveCoupan(true)
                    setPromoCodeInputValue(data.cartt.discountcode)
                }
                setIsLoading(false)
            }
            else {
                setAppointmentList([])
                setPackageList([])
                setIsCartEmpty(true)
                setIsLoading(false)
                setProductList([])
            }
            // setIsCartItemRemoved(false)

        }).catch(function (error) {
            setIsLoading(false)
            SHOW_TOAST_MESSAGE(langContent.something_went_wrong)
        })
}
/**
 * ! This API is reponsible for removing items from the User's Cart
 * @param {userId}:=> This is user's Id
 *
 */
export const deleteCartItem = async (userId, lang, itemDetails, token, setCartCount,userSession) => {
    await axiosInstance.post('/cart_item_remove', {
        user_id: userId, lang: lang, item_type: itemDetails.item_type, item_id: itemDetails.item_id, cart_id: itemDetails.cart_id,
        userSession:userSession
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                // setIsCartItemRemoved(true)
                getCartCount(token, setCartCount)
            }
            else {
                // setIsCartItemRemoved(false)
            }

        }).catch(function (error) {
            // handle error
            console.log(error);
        })
}
/**
 * ! Apply Promo Code API on Cart Item
 * @param {setShowRemoveCoupan}:=> This function sets state, whether remove coupan button should be visible or not
 * @param {promoCode}:=> This is the promo code entered by the user
 * @param {token}:=> Access token of logged In User
 */
export const applyPromoCode = (setShowRemoveCoupan, setIsLoading, userId, promoCode, userEmail, lang, token, langContent, setCartPriceDetails) => {
    axiosInstance.post('/add_coupon', { user_id: userId, discount_code: promoCode, user_email: userEmail, lang },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setShowRemoveCoupan(true)
                setCartPriceDetails(data.cartt)
                setIsLoading(false)
                const message = langContent.coupan_applied_successfully
                SHOW_TOAST_MESSAGE_SUCCESS(message)
            }
            else {
                SHOW_TOAST_MESSAGE(data.message)
                setIsLoading(false)
            }
        }).catch(function (error) {
            setIsLoading(false)
        })
}
/**
 * ! This API removes the "APPLIED" Promo Code
 * @param {setShowRemoveCoupan}:=> This function sets state, whether remove coupan button should be visible or not
 */
export const removeAppliedPromoCode = (userId, userEmail, lang, token, setShowRemoveCoupan, setIsLoading, setCartPriceDetails) => {
    axiosInstance.post('/remove_coupon', { user_id: userId, user_email: userEmail, lang: lang },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setShowRemoveCoupan(false)
                setCartPriceDetails(data.cartt)
                setIsLoading(false)
                SHOW_TOAST_MESSAGE_SUCCESS(data.message)
            }
            else {
                SHOW_TOAST_MESSAGE(data.message)
                setIsLoading(false)
            }
        }).catch(function (error) {
            setIsLoading(false)
        })
}
/**
 * ! This API Add the health package to user's cart
 */
export const addHealthPackage = (clinicId, lang, labtestId, userId, sample_collect_time, date, memberName, memberId, token, history, langContent, setIsAddPackageDialogueOpen) => {
    axiosInstance.post('/lab_cart', {
        "dr_clinic_id": clinicId, "lang": lang, "item_id": labtestId, "item_type": "labtest", "user_id": userId, "sample_collect_time": sample_collect_time, "sample_collection_date": date, "members_name": memberName, "member_id": memberId
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                const message = langContent.added_to_cart_successfully
                SHOW_TOAST_MESSAGE_SUCCESS(message)
                history.push('/cart')
            }
            else {
                const message = data.message.replace(/\b\w/g, l => l.toUpperCase())
                SHOW_TOAST_MESSAGE_SUCCESS(message)
            }
            setIsAddPackageDialogueOpen()
        }).catch(function (error) {
            // handle error
            console.log(error);
        })
}
/**
 * ! API to get the time slots available for health package booking
 * @param {type:=> If type is '1' then fetches slots for health packages, if type is'2' then fetches slots for covid test}
 */
export const getHealthPackageTimeSlots = (setTimeSlots, type, clinicId) => {
    axiosInstance.post('/slotsmaster_clinicwise', {
        type: type,
        clinic_id: clinicId
    }, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    }).then((response) => {
        let slots = []
        if (response.data.status) {
            response.data.slot.map((s) => {
                let obj = {}
                obj.key = s.slot_id
                obj.label = s.slot_name
                slots.push(obj)
            })
        }
        setTimeSlots(slots)
    })
}
/**
 * ! This API Returns City List for user's address
 */
export const getAddressDisctrictList = (setDistrictList) => {
    axiosInstance.post('/getdistrictdata', {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    }).then(async function (response) {
        const data = await response.data;
        if (data.status) {
            setDistrictList(data.districtlist);
        } else {
        }
    }).catch(function (error) {
        console.log(error);
    })
}
/**
 * ! This API Returns city list which can be used for user's address
 */
export const getAddressCityList = (setCityList,lang) => {
    axiosInstance.post('/getcitydata', {
            lang:lang
        },{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }

    ).then(async function (response) {
        const data = await response.data;
        if (data.status) {
            setCityList((data.citylist));
        } else {
        }
    }).catch(function (error) {
        // handle error
    })
}

/**
 * ! This API Returns city list which can be used for user's address
 */
export const checkUserDetails = (lang,userId,history) => {
    axiosInstance.post('/check_user_details', {
            lang:lang,
            userid:userId
        },{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }

    ).then(async function (response) {
        const data = await response.data;
        if (data.status) {
            SHOW_TOAST_MESSAGE(data.message)
            history.push('/myaccount')
        } else {
        }
    }).catch(function (error) {
        // handle error
    })
}

/**
 * ! This API returns user's profile data
 */
export const getUserProfile = (userId, userMobile, token, setIsLoading, setUserProfile, setUserName, setUserEmail, setUserCity, lang) => {
    axiosInstance.post('/user_profiledata',
        { "user_id": userId, "lang": lang, "user_mobile": userMobile },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setUserProfile(data.data)
                setUserName(data.data.user_fname + ' ' + (data.data.user_lname?data.data.user_lname:""))
                setUserEmail(data.data.user_email)
                setUserCity(data.data.user_city)
            }
            else {
            }
            setIsLoading(false)
            // history.push('/dashboard');
        }).catch(function (error) {
            // handle error
            setIsLoading(false)
            console.log(error);
        })
}

// delete order /reschedule order
export const rescheduleOrder = async (token,lang,orderId, userID,history,api_key) => {
        axiosInstance.post('/delete_order_for_user', {
        order_id: orderId, user_id: userID, lang: lang, API_KEY: api_key
    },{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status === false)
            {
                SHOW_TOAST_MESSAGE(data.message)
            }else
            {
                SHOW_TOAST_MESSAGE_SUCCESS(data.message)
                history.push("/corporate/packages")
            }
        }).catch(function (error) {
             console.error("Error deleting order:", error);
        throw error;
        })
};

/**
 * ! This API sets the user's shipping Address
 */
export const saveUserShippingAddress = (data, userId, userCity, lang, cityNameVn, distNameVn, token, history, setIsLoading) => {
    const fd = new FormData()
    fd.append('user_id', userId)
    fd.append('customer_name', data.customer_name)
    fd.append('company_name', data.company_name)
    fd.append('order_id', "")
    fd.append('tax_code', data.tax_code)
    fd.append('address_name', data.user_fname)
    fd.append('address_email', data.user_email)
    fd.append('address_address1', data.user_address)
    fd.append('address_city', userCity)
    fd.append('address_country', "+84")
    fd.append('order_address_id', '')
    fd.append('lang', lang)
    fd.append('company_address', data.company_address)
    fd.append('shipping_address_city_name', cityNameVn)
    fd.append('shipping_address_state_name', distNameVn)
    fd.append('shipping_address_flag', '1')
    fd.append('shipping_address_address1', data.user_address)
    fd.append('shipping_address_state', data.user_state)
    fd.append('shipping_address_city', data.user_city)
    axiosInstance.post('/order_checkout', fd,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                localStorage.setItem('view_orderId', data.detail_order_id)
                localStorage.setItem('orderEnc', data.order_id)
                setIsLoading(false)
                history.push('/order_confirmation')
            }
            else {
                setIsLoading(false)
                SHOW_TOAST_MESSAGE(data.message)
            }
        }).catch(function (error) {
            setIsLoading(false)
        })
}
/**
 * ! This API Gives user's Order Details
 */
export const getOrderDetails = (token, userId, lang, orderId, setOrderList, setKitList, setTotalOrderAmount, setIsLoading, setOrderDetails) => {
    let session_id=''
    console.log(token);
    if(userId===undefined){
        const cookies = new Cookies(null, { path: '/' });
         session_id = cookies.get('session_id')
    }else{
        session_id =''
    }
    axiosInstance.post('/view_myorderdetails', {
        user_id: userId, lang: lang, order_id: orderId,session_id:session_id
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            const itemData = data.data
            if (data.status) {
                setOrderList(itemData.item_details)
                setOrderDetails(itemData)
                setTotalOrderAmount(itemData.order_amount)
                setKitList(data.data.item_details[1] ? data.data.item_details[1] : [])
                setIsLoading(false)
            }
            else {
                setIsLoading(false)
            }
        }).catch(function (error) {
            setIsLoading(false)
        })
}
/**
 * ! This API Returns the complete(paid) orders made by user
 * @param {userId}:=> user's Id, stored when logged in
 * @param {setOrdersList} :=>  This sets the list of paid orders done by the user
 */
export const getMyOrdersList = (userId, lang, token, setOrdersList, setIsLoading) => {
    axiosInstance.post('/getorders_list', {
        user_id: userId, lang: lang
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setOrdersList(data.data)
                setIsLoading(false)
            }
            else {
                setOrdersList([])
                setIsLoading(false)
            }
        }).catch(function (error) {
            setIsLoading(false)
        })
}
/**
 * ! This API returns the array of lab reports of User
 * @param {setReportList}:=> This sets the list of the lab test reports of the user
 */
export const getLabReports = (userId, lang, token, setReportList, setIsLoading) => {
    axiosInstance.post('/mylabreport_list', {
        user_id: userId, lang: lang
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                const orderData = data.data.reverse();
                const labData = data.mdata;
                const transformArray = orderData.map((el, idx) => ({
                    ...el, ...labData[idx]
                }));
                setReportList(transformArray)
                setIsLoading(false)
            }
            else {
                setReportList([])
                setIsLoading(false)
            }
        }).catch(function (error) {
            setIsLoading(false)
        })
}
/**
 * ! This API gives prescription list which is uploaded by the doctor after consultation
 * @param {setPrescriptionsList} :=> This sets the doctor's prescriptions list  available
 */
export const getMyPrescriptions = (userId, lang, token, setPrescriptionsList, setIsLoading) => {
    axiosInstance.post('/myprescription_list', {
        user_id: userId, lang: lang
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setPrescriptionsList(data.mdata)
                setIsLoading(false)
            }
            else {
                setPrescriptionsList([])
                setIsLoading(false)
            }
        }).catch(function (error) {
            setIsLoading(false)
        })
}
/**
 * ! This API returns list of the hospitals
 * @param {offset}:=> This is record number starting from where we want to fetch the data
 * @param {setTotalRecords}:=> This sets total number of available records which decide whether we have loaded all the records or not which decides we need to re-fetch or not
 */
export const getHospitalsList = (offSet, lang, setTotalRecords, setHospitalList, setIsLoading, searchValue, hospitalList, page, setHasMore, setLoader) => {
    axiosInstance.post('/get_alldata_info_new_pagination',
        { "lang": lang, "offset": 0, "city": "", "district": "", "searchvalue": searchValue, "page_no": page },
    )
        .then(function (response) {
            const data = response.data
            if (data.status) {
                setTotalRecords(data.no_records)
                const hospitalDataList = data.Hospitaldata;
                // setHospitalList(prev => prev.concat(hospitalDataList));
                // setHospitalList(prevHospital => {
                //     return [...new Set([...prevHospital, ...hospitalDataList])]
                // })
                setHospitalList(hospitalDataList)
                setIsLoading(false)
                setHasMore(hospitalDataList.length > 0)
                setLoader(false)
            } else {
                setIsLoading(false)
                setHasMore(false)
                setLoader(false)
            }

        }).catch(function (error) {
            setIsLoading(false)
            setLoader(false)
        })
}
/**
 * ! This API gives upcoming Doctor Appointments
 * @param {appointmentType}:=> Appointment type is either upcoming or past depending upon which we fetchpast appointment or upcoming appointment
 * @param {setAppointmentList}:=> This sets the list of appointments
 */
export const getUpcomingAppointments = (userMobile, userId, lang,langContent, token, appointmentType, setAppointmentList, setIsLoading) => {
    axiosInstance.post('/getuser_appointments',
        { "user_mobile": userMobile, "user_id": userId, "lang": lang, tense: appointmentType },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                const appointments = data.data
                setAppointmentList(appointments)
                setIsLoading(false)
            }
            else {
                SHOW_TOAST_MESSAGE(langContent.no_appointments)
                setIsLoading(false)
            }
        }).catch(function (error) {
            // handle error
            console.log(error);
            setIsLoading(false)
        })
}
/**
 * ! This API makes a new request when someone wants to partner with IQI Team
 */
export const partnerWithUsApi = (data, lang, setIsLoading, langContent, history) => {
    axiosInstance.post('/partner_withus',
        { "name": data.name, "mobile": data.mobile, "mail": data.email, "description": data.description, "lang": lang },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(function (response) {
            const data = (response.data)
            if (data.status) {
                setIsLoading(false)
                SHOW_TOAST_MESSAGE_SUCCESS(langContent.we_will_connect_soon)
                history.push('/')
            } else {
                SHOW_TOAST_MESSAGE(langContent.something_went_wrong)
            }
        }).catch(function (error) {
            console.log(error)
            setIsLoading(false)
            SHOW_TOAST_MESSAGE(langContent.something_went_wrong)
        })
}
/**
 * ! This API gives ABout us (About IQI)
 */
export const getAboutUs = (lang, setAbout, setContactUs, setAboutUsTitle, setContactUsTitle, setIsLoading) => {
    axiosInstance.post('/get_contactus', { "lang": lang }
    ).then(response => {
        const queryResponse = (response.data);
        if (queryResponse.status) {
            const config_aboutUs = lang === 'en' ? queryResponse.aboutus[0].config_value : queryResponse.aboutus[0].config_value_vn
            const config_contactUs = lang === 'en' ? queryResponse.contactus[0].config_value : queryResponse.contactus[0].config_value_vn
            const aboutUsName = lang === 'en' ? queryResponse.aboutus[0].config_name : queryResponse.aboutus[0].config_name_vn
            const contactUsName = lang === 'en' ? queryResponse.contactus[0].config_name : queryResponse.contactus[0].config_name_vn
            setAbout(config_aboutUs)
            setContactUs(config_contactUs)
            setAboutUsTitle(aboutUsName)
            setContactUsTitle(contactUsName)
            setIsLoading(false)
        } else {
            setIsLoading(false)
        }
    }).catch(function (error) {
        setIsLoading(false)
    })
}
/**
 * ! This API gives Survey questions
 */
export const getSurveyQuestions = (lang,userID, setSurveyQuestions, setIsLoading,history) => {
    axiosInstance.post('/get_survey_questions', { "form_id":1,"lang": lang,userID:userID }
    ).then(response => {
        const queryResponse = (response.data);
        if (response.status==200) {
            if(queryResponse.completed ==1){
                history.push('/user_home_care_survey_completed/1')
            }
            setSurveyQuestions(queryResponse.data)
            setIsLoading(false)
        } else {
            setIsLoading(false)
        }
    }).catch(function (error) {
        setIsLoading(false)
    })
}


/**
 * ! This API gives array of hospitals which provides covid packages
 */
export const getHospitalsWithCovidPackages = (lang, setHospitalsList, setIsLoading) => {
    axiosInstance.post('/covidpackage_hospitalwise',
        { "lang": lang }
    )
        .then(function (response) {
            const hospitalDataList = response.data.Hospitaldata;
            setHospitalsList(hospitalDataList || []);
            setIsLoading(false)
        }).catch(function (error) {
            setIsLoading(false)
        })
}
/**
 * ! This API gives Slots available for covid package booking
 */
export const getCovidPackageAvailableSlots = (selectedHospitalId, packageDate, lang, setAvailableSlots, selectedPackage) => {
    axiosInstance.post('/check_slot_status',
        {
            dr_clinic_id: selectedHospitalId,
            appointment_date: packageDate,
            labtest_id: selectedPackage,
            lang: lang
        }
    ).then((response) => {
        let slots = []
        if (response.data.status) {
            response.data.slot.map((s) => {
                let obj = {}
                obj.key = s.slot_id
                obj.label = s.slot_name
                slots.push(obj)
            })
        }
        setAvailableSlots(slots)
    })
}
/**
 * ! This API books the covid testing package
 *
 */
export const bookCovidTest = (selectedHospitalId, lang, selectedPackage, userId, sample_collect_time, date, memberName, memberId, token, langContent, history, setRequestPending) => {
    axiosInstance.post('/covidtest_addcart',
        { "price": "", "dr_clinic_id": selectedHospitalId, "lang": lang, "item_id": selectedPackage, "item_type": "labtest", "user_id": userId, "sample_collect_time": sample_collect_time, "sample_collection_date": date, "members_name": memberName, "doctor_id": "1", "member_id": memberId, "address1": "", "ward": "", "city": "", "district": "", "sample_collection_address_flag": 1 }
        ,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                const message = langContent.added_to_cart_successfully
                SHOW_TOAST_MESSAGE_SUCCESS(message)
                setRequestPending(false)
                history.push('/cart')
            }
            else {
                const message = data.message.replace(/\b\w/g, l => l.toUpperCase())
                SHOW_TOAST_MESSAGE_SUCCESS(message)
                setRequestPending(false)
            }
        }).catch(function (error) {
            setRequestPending(false)
        })
}
/**
 * ! This API gives Doctors list available for covid consultation
 */
export const getDocotorsForCovidConsultation = (token, setDoctorList, setIsLoading, lang) => {
    axiosInstance.post('/list_covid_consultant',
        { "offset": "0", "limit": "6", "lang": lang },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then((response) => {
            if (response.data.status) {
                setDoctorList(response.data.data)
            } else {
                setDoctorList([])
            }
            setIsLoading(false)
        }).catch((error) => {
            setIsLoading(false)
        })
}
/**
 * ! This API gives doctor's of a particular hospital as per user's search
 */
export const getHospitalsSpecializedDoctorsPaging = (lang, hospitalId, speciality, setDoctorsList, setServiceCategory, setIsLoading, setHospitalImage, page, setHasMore, setLoder,selectType) => {
    axiosInstance.post('/get_specializedoctor_list_pagination',
        { "lang": lang, "offset": "0", "limit": "8", "dr_clinic_nice_name": hospitalId, "search_value": speciality, "page_no": page,"selecType":selectType },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',

            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                const doctors = data.data;
                const category = data.drservice;
                // setDoctorsList(doctors)
                setDoctorsList(prevDoc => {
                    return [...new Set([...prevDoc, ...doctors])]
                })
                setServiceCategory(category)
                setIsLoading(false)
                setHospitalImage(doctors.length ? doctors[0].hospital_image : '')
                setHasMore(data.data.length > 0)
                setLoder(false)
            } else {
                setDoctorsList([])
                setHasMore(false)
                setIsLoading(false)
                setLoder(false)
            }
        }).catch(function (error) {
            setIsLoading(false)
            setLoder(false)
        })
}
/**
 * ! This API returns the count of the items in the user's cart
 */
export const getCartCount = (token, setCartCount, setIsNotificationInvisible) => {
    const userId = getUserData('user_id')
    let session_id=''
    console.log(token);
    if(userId===undefined){
        const cookies = new Cookies(null, { path: '/' });
         session_id = cookies.get('session_id')
    }else{
        session_id =''
    }
    axiosInstance.post('/get_cartcount', {
        user_id: userId,
        session_id :session_id
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setCartCount(data.data.count)
                if (data.data.recommendation_count != 0) {
                    setIsNotificationInvisible(false)
                }
            }
            else {
            }
        }).catch(function (error) {
        })
}
/**
 * ! This API gives relationship array which is used while adding member to say the relation with user
 */
export const getRelationShipData = (setRelationshipArray) => {
    axiosInstance.post('/getrealtionshipdata').then(function (response) {
        const data = response.data
        if (data.status) {
            const relations = []
            const lang = localStorage.getItem('lang')
            data.relationship.map((relation) => {
                if (lang === 'en') {
                    let obj = {}
                    obj.key = relation.rel_id
                    obj.label = relation.name_en
                    relations.push(obj)
                } else {
                    let obj = {}
                    obj.key = relation.rel_id
                    obj.label = relation.name_vn
                    relations.push(obj)
                }
            })
            setRelationshipArray(relations)
        }
        else {
            setRelationshipArray([])
        }
    }).catch(function (error) {
        setRelationshipArray([])
    })
}
/**
 * ! This API is responsible for Adding  New Member (Add New Member)
 * @param {fd}:=> This fd is form Data object which contains member details,and member profile image
 * @param {token}:=> User's Access Token when logged In
 */
export const addNewMember = (fd, token, langContent, history, setIsLoading) => {
    axiosInstance.post('/add_member_user', fd,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                SHOW_TOAST_MESSAGE_SUCCESS(langContent.member_added_successfully)
                history.goBack()
                setIsLoading(false)
            }
            else {
                SHOW_TOAST_MESSAGE(langContent.something_went_wrong)
                history.goBack()
                setIsLoading(false)
            }
        }).catch(function (error) {
            SHOW_TOAST_MESSAGE(langContent.something_went_wrong)
            setIsLoading(false)
            history.goBack()
        })
}
/**
 * ! This api returns member's Details Object
 * ! THIS IS USED TO RESET THE FORM FIELDS FOR MEMBER DETAILS UPDATION
 * @param {userId}:=> this is user id of user , saved when user logs in
 * @param {setIsLoading}:=> sets the state if API is Loading or loading is done
 * @param {token}:=> this is token(Access Token) of user , saved when user logs in
 * @param {setMembersDetails}:=> this is function to set the details of the member
 * @param {userMobile}:=> this is the mobile number of the user
 */
export const getMemberDetailsToResetForm = (userId, lang, memberId, token, setIsLoading, reset,setMemberData) => {
    axiosInstance.post('/get_memberdetailslist', {
        "user_id": userId, "lang": lang, "member_id": memberId
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then((response) => {
            const data = response.data
            if (data.status) {
                const memberData = data.data;
                memberData.member_dob = moment(memberData.member_dob).format('DD/MM/yyyy')
                const dobArray = memberData.member_dob.split('/');
                console.log(dobArray);
                const month = dobArray[1]
                const day = dobArray[0]
                const year = dobArray[2]
                memberData.member_dob_day = day
                memberData.member_dob_month = month
                memberData.member_dob_year = year
                memberData.address_ward = memberData.member_ward
                reset(memberData)
                setMemberData(memberData)
            }
            else {
            }
            setIsLoading(false)
        }).catch(function (error) {
            setIsLoading(false)
        })
}
/**
 * ! USED TO RESET THE FORM FIELDS FOR USER PROFILE DETAILS UPDATION
 */
export const updateMemberDetails = (fd, token, setIsLoading, langContent, history, setRequestPending) => {
    axiosInstance.post('/update_member', fd,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setIsLoading(false)
                SHOW_TOAST_MESSAGE_SUCCESS(langContent.member_updated)
                setRequestPending(false)
                history.goBack()
            }
            else {
                setIsLoading(false)
                SHOW_TOAST_MESSAGE(langContent.something_went_wrong)
                setRequestPending(false)
                history.goBack()
            }
        }).catch(function (error) {
            setIsLoading(false)
            setRequestPending(false)
            history.goBack()
        })
}
/**
 * ! USED TO add form1 details
 */
export const updateForm1ADetails = (fd, token, setIsLoading, langContent, history, setRequestPending,memberID,redirected,nextform) => {
    axiosInstance.post('/add_form1_details', fd,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setIsLoading(false)
                SHOW_TOAST_MESSAGE_SUCCESS(langContent.form1a_submitted_successfully)
                setRequestPending(false)
                console.log(redirected);
                console.log(data.use_direct_billing_service);
                if(nextform=='medical_history'){
                    history.push('/medical_history_form?memberID='+memberID+'&redirected=yes')
                }else{
                    if(data.use_direct_billing_service==='yes' && redirected==='yes'){
                        history.push('/upload_documents?member='+memberID+'&redirected='+redirected+'&direct_billing='+data.use_direct_billing_service+'&docType=idproof')
                    }else if(data.use_direct_billing_service=='no' || redirected=='yes'){
                        history.push('/upload_documents?member='+memberID+'&redirected='+redirected+'&direct_billing='+data.use_direct_billing_service+'&docType=idproof')
                    }else if(data.use_direct_billing_service=='no' || redirected=='no'){
                        history.push('/my_forms');
                    }else{
                        history.push('/cart');
                    }
                }
                
                // history.goBack()
                sendForm1AFTP(token,memberID)
            }
            else {
                setIsLoading(false)
                SHOW_TOAST_MESSAGE(langContent.something_went_wrong)
                setRequestPending(false)
                // history.goBack()
            }
        }).catch(function (error) {
            setIsLoading(false)
            setRequestPending(false)
            // history.goBack()
        })
}

export const sendForm1AFTP = (token,memberID) => {
    axiosInstance.post('/generate_form1a_pdf_ftp', {
            token:token,
            memberID:memberID
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                // setIsLoading(false)
            }
            else {
                // setIsLoading(false)
            }
        }).catch(function (error) {
            // setIsLoading(false)
        })
}
export const sendForm1BFTP = (token,memberId) => {
    axiosInstance.post('/generate_form1b_pdf_ftp', {
            token:token,
            memberID:memberId
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                // setIsLoading(false)
            }
            else {
                // setIsLoading(false)
            }
        }).catch(function (error) {
            // setIsLoading(false)
        })
}
/**
 * ! USED TO add medical Hiastory details
 */
export const updateMedicalHistoryForm = (fd, token, setIsLoading, langContent, history, setRequestPending,memberID,redirected) => {
    axiosInstance.post('/add_medical_history_details', fd,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setIsLoading(false)
                SHOW_TOAST_MESSAGE_SUCCESS(langContent.medical_history_form_submitted)
                setRequestPending(false)
                if(data.use_direct_billing_service=='yes'){
                    history.push('/form1b?memberID=' + memberID+'&redirected='+redirected);
                }else if(data.use_direct_billing_service=='no' || redirected=='no'){
                    history.push('/my_forms');
                }else{
                    history.push('/cart');
                }
                // history.goBack()
                sendMedicalHistoryFTP(token,memberID)
            }
            else {
                setIsLoading(false)
                SHOW_TOAST_MESSAGE(langContent.something_went_wrong)
                setRequestPending(false)
                // history.goBack()
            }
        }).catch(function (error) {
            setIsLoading(false)
            setRequestPending(false)
            // history.goBack()
        })
}

export const sendMedicalHistoryFTP = (token,memberId) => {
    axiosInstance.post('/generate_medical_history_form_pdf_ftp', {
            token:token,
            memberID:memberId
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                // setIsLoading(false)
            }
            else {
                // setIsLoading(false)
            }
        }).catch(function (error) {
            // setIsLoading(false)
        })
}


/**
 * ! USED TO add form1b details
 */
export const updateForm1BDetails = (fd, token, setIsLoading, langContent, history, setRequestPending,redirected,memberId) => {
    axiosInstance.post('/add_form1b_details', fd,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setIsLoading(false)
                SHOW_TOAST_MESSAGE_SUCCESS(langContent?.form_1b_submitted)
                setRequestPending(false)
                if(redirected=='yes') {
                    history.push('/upload_documents?member='+memberId+'&redirected='+redirected+'&direct_billing=no&docType=insurance_card')
                }else if(redirected=='no'){
                    history.push('/my_forms')
                }
                sendForm1BFTP(token,memberId)
                // history.goBack()
            }
            else {
                setIsLoading(false)
                SHOW_TOAST_MESSAGE(langContent.something_went_wrong)
                setRequestPending(false)
                // history.goBack()
            }
        }).catch(function (error) {
            setIsLoading(false)
            setRequestPending(false)
            // history.goBack()
        })
}

/**
 * ! USED TO  save user home care survey form
 */
export const saveHomeCareSurveyForm = (fd, token, setIsLoading, langContent, history, setRequestPending) => {
    axiosInstance.post('/save_home_care_survey_feedback', fd,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setIsLoading(false)
                SHOW_TOAST_MESSAGE_SUCCESS(data.message)
                setRequestPending(false)
                // history.goBack()
                history.push('/user_home_care_survey_completed/0');
            }
            else {
                setIsLoading(false)
                SHOW_TOAST_MESSAGE(langContent.something_went_wrong)
                setRequestPending(false)
                // history.goBack()
            }
        }).catch(function (error) {
            setIsLoading(false)
            setRequestPending(false)
            // history.goBack()
        })
}



/**
 * ! This API is used user's profile data
 */
export const getUserProfileToResetForm = (userId, userMobile, token, setIsLoading, lang, reset, setSelectedDOB, setSelectedDistrict,setselectedAddressType,setUserData) => {
    axiosInstance.post('/user_profiledata',
        { "user_id": userId, "lang": lang, "user_mobile": userMobile },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                const userData = data.data
                const dobArray = userData.user_dob.split('/')
                const month = dobArray[0]
                const day = dobArray[1]
                const year = dobArray[2]
                userData.user_dob_day = day
                userData.user_dob_month = month
                userData.user_dob_year = year
                reset(userData)
                setUserData(userData)
                // setSelectedDistrict(561)
                if (userData.user_dob && userData.user_dob.length) {
                    setselectedAddressType(userData.address_type_id)
                    setSelectedDOB(userData.user_dob)
                } else {
                    setSelectedDOB(null)
                }
            }
            else {
            }
            setIsLoading(false)
        }).catch(function (error) {
            setIsLoading(false)
        })
}
/**
 * ! This API will update the user's details (Profile)
 */
export const updateUserProfile = (fd, token, setIsLoading, history, langContent, setRequestPending) => {
    axiosInstance.post('/user_profileupdate', fd,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setIsLoading(false)
                SHOW_TOAST_MESSAGE_SUCCESS(langContent.user_updated)
                setRequestPending(false)
                history.goBack()
            }
            else {
                setIsLoading(false)
                SHOW_TOAST_MESSAGE(langContent.something_went_wrong)
                setRequestPending(false)
                history.goBack()
            }
        }).catch(function (error) {
            setIsLoading(false)
            setRequestPending(false)
            history.goBack()
        })
}
/**
 * ! *********************** This Section API's are for Search Result***********************************
 */

/**
 *
 */
export const getSearchByCity = (cityId, searchVal, setIsLoading, lang, limit, setHospitalList, setDoctorsList, setPackageList) => {
    setIsLoading(true)
    axiosInstance.post('/search_by_location',
        { "lang": lang, "limit": limit, "city_id": cityId, "searchvalue": searchVal || '' }
    )
        .then(function (response) {
            const queryResponse = response.data.data;
            if (queryResponse) {
                setHospitalList(queryResponse.hospital)
                localStorage.setItem('resultantHospitals', JSON.stringify(queryResponse.hospital))
                setDoctorsList(queryResponse.consultant)
                setPackageList(queryResponse.labtest ? queryResponse.labtest : [])
                setIsLoading(false)
            } else {
                setIsLoading(false)
            }
        }).catch(function (error) {
            setIsLoading(false)
        })
}





/**
 *
 * @param {*} lang
 * @param {*} searchValue
 * @param {*} selectedCityForSearch
 * @param {*} searchType
 * @param {*} setSearchResponse
 * @param {*} setIsLoading
 */

export const getDataByLocation = (lat, lng, searchVal, setIsLoading, lang, limit, searchByCity, setHospitalList, setDoctorsList, setPackageList) => {
    setIsLoading(true)
    axiosInstance.post('/search_by_geolocation',
        {
            "lang": lang, "limit": limit, "city": searchByCity, "searchvalue": searchVal || '',
            latitude: lat, longitude: lng, miles: "10"
        }
    )
        .then(function (response) {
            const queryResponse = response.data.data;
            if (queryResponse) {
                setHospitalList(queryResponse.hospital)
                localStorage.setItem('resultantHospitals', JSON.stringify(queryResponse.hospital))
                setDoctorsList(queryResponse.consultant)
                setPackageList(queryResponse.healthpackages ? queryResponse.healthpackages : [])
                setIsLoading(false)
            } else {
                setIsLoading(false)
            }
        }).catch(function (error) {
            setIsLoading(false)
        })
}


/**
 *
 */
export const getAllFromType = (lang, searchValue, selectedCityForSearch, searchType, setSearchResponse, setIsLoading) => {
    axiosInstance.post('/search_request_seemore',
        { "lang": lang, "Searchvalue": searchValue || '', city: selectedCityForSearch, searchtype: searchType, searchvalue: searchValue || '' }
    )
        .then(function (response) {
            // handle success
            const queryResponse = response.data.data;
            if (queryResponse) {
                setSearchResponse(queryResponse)
                setIsLoading(false)
            } else {
                setSearchResponse([])
                setIsLoading(false)
            }
        }).catch(function (error) {
            // handle error
            setIsLoading(false)
        })
}

/**
 *
 */
export const getAllByGeolocation = (lang, searchValue, selectedCityForSearch, searchType, setSearchResponse, setIsLoading) => {
    axiosInstance.post('/search_by_geolocation_seemore',
        {
            "lang": lang, "searchvalue": searchValue || '', city: selectedCityForSearch, searchtype: searchType, latitude: localStorage.getItem('lat'), longitude: localStorage.getItem('lng'), miles: '10', "Searchvalue": searchValue || ''
        }
    )
        .then(function (response) {
            // handle success
            const queryResponse = response.data.data;
            if (queryResponse) {
                setSearchResponse(queryResponse)
                setIsLoading(false)
            } else {
                setSearchResponse([])
                setIsLoading(false)
            }
        }).catch(function (error) {
            // handle error
            setIsLoading(false)
        })
}

/**
 *
 */
export const getAllByCity = (lang, selectedCityForSearch, searchType, searchValue, setSearchResponse, setIsLoading) => {
    axiosInstance.post('/search_by_location_seemore',
        {
            "lang": lang, "searchvalue": "" || '', city_id: selectedCityForSearch,

            searchtype: searchType, "Searchvalue": searchValue || ''
        }
    )
        .then(function (response) {
            // handle success
            const queryResponse = response.data.data;

            if (queryResponse) {
                setSearchResponse(queryResponse)
                setIsLoading(false)
            } else {
                setSearchResponse([])
                setIsLoading(false)
            }
        }).catch(function (error) {
            // handle error
            setIsLoading(false)
        })
}

/**
 * ! This API is Responsible for uploading member's medical History
 */
export const uploadMedicalHistoryAPI = (fd, token, lang, setIsLoading, langContent, history) => {
    axiosInstance.post('/upload_medical_history_document', fd, {
        headers: {
            'Authorization': token,
            'lang': lang
        }
    }).then(function (response) {
        const data = response.data

        if (data.status) {
            SHOW_TOAST_MESSAGE_SUCCESS(langContent.member_medical_history_added_successfully)
            setIsLoading(false)
            history.push('/medical_history_details')
            document.getElementById("clear-form").reset();

        }
    }).catch(function (error) {
        setIsLoading(false)
    })
}
/**
 * ! This API is Responsible for uploading member's medical History
 */
export const uploadUserDocs = ( token, lang, setIsLoading, langContent, history,memberId,fileBase64,Backfilebase64,docType,direct_billing,redirected,expiryDate,cardNo) => {
    axiosInstance.post('/upload_user_docs', {
        memberId:memberId,
        fileBase64:fileBase64,
        docType:docType,
        Backfilebase64:Backfilebase64,
        expiryDate:expiryDate,
        cardNo:cardNo
    }, {
        headers: {
            'Authorization': token,
            'lang': lang
        }
    }).then(function (response) {
        const data = response.data

        if (data.status) {
            SHOW_TOAST_MESSAGE_SUCCESS(langContent.member_medical_history_added_successfully)
            setIsLoading(false)
            if(direct_billing=='yes' && redirected=='yes' && docType=='idproof'){
                history.push('/form1b?memberID='+memberId+'&redirected='+redirected);
            }else if(direct_billing=='no' && redirected=='yes' && docType=='insurance_card'){
                history.push('/cart');
            }else{
                history.push('/member_details');
            }
            document.getElementById("clear-form").reset();

        }
    }).catch(function (error) {
        setIsLoading(false)
    })
}

/**
 * ! This API performs OTP Validation when user is signing up or logging in using otp
 */
export const validateOTP = (data, history, langContent, setIsLoading, lang,setRequestPending) => {
    const mobile = localStorage.getItem('userMobile') ? localStorage.getItem('userMobile') : ''
    const otp = data.otp_login
    axiosInstance.post('/userlogin_otp_verify', {
        "user_mobile": mobile, "lang": lang, "mobile_otp": otp
    }, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(function (response) {
        const data = response.data
        if (data.status) {
            const user = data.data[0];
            createUserInChat(user.user_id, user.user_fname, data.token, user.image_url)
            setIsLoading(false)
            setRequestPending(false)
            localStorage.setItem('userDetails', JSON.stringify(user))
            localStorage.setItem('accessToken', data.token)
            localStorage.setItem('loginStatus', true)
            if (data.profile_flag === '0') {
                history.push('/change_password?profile_flag='+data.profile_flag)
            }else if(data.profile_flag==='1'){
                history.push('/edit_profile')
            } else if(data.profile_flag==='2'){
                history.push('/myaccount')
            }else {
                history.goBack()
            }

        }

        else {
            setIsLoading(false)
            setRequestPending(false)
            SHOW_TOAST_MESSAGE(langContent.invalid_otp);
        }

    }).catch(function (error) {
        // handle error
        setIsLoading(false)
        SHOW_TOAST_MESSAGE(langContent.something_went_wrong);
    })
}
/**
 * ! This API performs OTP Validation when user is signing up or logging in using otp
 */
export const validateForgotPasswordOTP = (data, history, langContent, setIsLoading, lang,setRequestPending) => {
    const mobile = localStorage.getItem('userMobile') ? localStorage.getItem('userMobile') : ''
    const otp = data.otp_login
    axiosInstance.post('/userlogin_otp_verify', {
        "user_mobile": mobile, "lang": lang, "mobile_otp": otp
    }, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }).then(function (response) {
        const data = response.data
        if (data.status) {
            const user = data.data[0];
            localStorage.setItem('userDetails', JSON.stringify(user))
            // localStorage.setItem('accessToken', data.token)
            setRequestPending(false)
            setIsLoading(false)
            history.push('/reset_password')
        }

        else {
            setIsLoading(false)
            setRequestPending(false)
            SHOW_TOAST_MESSAGE(langContent.invalid_otp);
        }

    }).catch(function (error) {
        // handle error
        setIsLoading(false)
        SHOW_TOAST_MESSAGE(langContent.something_went_wrong);
    })
}

/**
 * ! This API will be used to check if appointments time slots are available and thus if the user can be redirected to Payment Gateway or not.
 * @param {paymentstatus} :=> if true, user can will be redirected to payment gateway otherwise redirect to cart page.
 */
export const checkIfPaymentIsAllowed = (userId, lang, token, langContent, setIsPaymentAllowed) => {
    axiosInstance.post('/get_cartitem', { user_id: userId, lang },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {

                if (!data.paymentstatus) {
                    SHOW_TOAST_MESSAGE(langContent.some_appointment_slots_not_available)
                    setIsPaymentAllowed(false)
                } else {
                    setIsPaymentAllowed(true)
                }
            }
            else {

            }
        }).catch(function (error) {

        })
}
/**
 * ! This will directly redirect to thankyou page when order total is 0.
 */
export const redirectToThankyou = (userId, orderEnc, lang, token, history) => {
    axiosInstance.post('/cash_on_payment', {
        "user_id": userId,
        "order_id": orderEnc,
        "lang": lang
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }
    ).then(function (response) {
        if (response.status == 200) {
            if (response.status) {
                history.push({
                    pathname: '/thankyou',
                    search: `?order_id=${response.data.view_order_id}`
                })
            }
        }
    }).catch(function (error) {
        // handle error
        console.log(error);
    })
}
/**
 * ! This API will create user in cometchat
 */
const createUserInChat = (memberId, memberName, token, imageUrl) => {
    const appID = AppIDCometChat;
    const region = RegionCometChat;
    const appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).autoEstablishSocketConnection(true).build();
    CometChat.init(appID, appSetting).then(
        () => {
            // You can now call login function.
            let authKey = AuthKeyCometChat;
            var memberUID = `member_${memberId}`;
            var name = memberName;
            let user = new CometChat.User(memberUID);
            user.setName(name);
            user.setAvatar(imageUrl)
            CometChat.createUser(user, authKey).then(
                user => {
                    updateMemberUID(memberId, memberUID, token)
                }, error => {
                    if (error.code === 'ERR_UID_ALREADY_EXISTS') {

                    }
                }
            )
        },
        error => {
            // console.log("Initialization failed with error:", error);
            // Check the reason for error and take appropriate action.
        }
    );
}

/**
 * ! This Api will update the user's UID (CometChat UID for user) in user's table
 */
const updateMemberUID = (userId, memberUID, token) => {
    axiosInstance.post('/update_user_authid', { user_id: userId, user_auth_id: memberUID },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
            }
            else {

            }
        }).catch(function (error) {

        })
}
/**
 * ! This returns list of address types(home,office,and others)
 *
 */
export const getAddressTypes = (token, lang, setAddressTypes, setIsLoading, langContent) => {
    axiosInstance.post('/get_address_type',
        {
            lang
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setAddressTypes(data.data)
            }
            else {
                setAddressTypes([])
            }
            setIsLoading(false)

        }).catch(function (error) {
            setIsLoading(false)
            SHOW_TOAST_MESSAGE(langContent.something_went_wrong);
        })
}
/**
 * ! This API will add the user's delivery address to his address book.
 */
export const AddNewDeliveryAddress = (token, lang, userId, address, setIsLoading, langContent, history,userSession) => {
    axiosInstance.post('/add_user_address',
        {
            user_id: userId,
            address_type_id: address.address_type_id,
            address_email: address.address_email,
            address_line_1: address.address_line_1,
            address_line_2: "",
            address_district_id: address.address_district_id,
            address_city_id: address.address_city_id,
            address_ward: address.address_ward,
            address_country: address.address_country,
            address_phone1: address.address_phone1,
            customer_name: address.customer_name,
            lang:lang,
            userSession:userSession
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status==200) {
                console.log(userId,"userID");
                if(userId?.length>0){
                    history.goBack()
                }
                else{
                    saveUserShippingAddressById(data.user_address_id, data.user_id, lang, token, history, setIsLoading, langContent)

                }
            }
            else {
                history.goBack()
            }
            setIsLoading(false)

        }).catch(function (error) {
            setIsLoading(false)
            SHOW_TOAST_MESSAGE(langContent.something_went_wrong);
        })
}
/**
 * ! This API will fetch the address book of user
 */
export const getUsersAddressbook = (token, lang, setAddressBook, langContent, setIsLoading) => {
    axiosInstance.post('/get_user_address',
        {
            lang
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setAddressBook(data.data)
            }
            else {
                setAddressBook([])
            }
            setIsLoading(false)

        }).catch(function (error) {
            setIsLoading(false)
            SHOW_TOAST_MESSAGE(langContent.something_went_wrong);
        })
}
/**
 * ! This API will place new order
 */
export const placeNewOrder = (token, lang, langContent, setIsLoading,userId,history) => {
    axiosInstance.post('/place_new_order',
        {
            lang,
            user_id: userId,
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            // console.log(data);
            if (data.status) {
                // console.log("data status",data.status);
                //setAddressBook(data.data)
                // window.location.href = `https://www.iqihealth.net/admin/paymentcheckout?order_id=${orderViewId}`
                history.push({
                    pathname: '/thankyou',
                    search: `?order_id=${data.view_order_id}`,
                });
            }
            else {
               // setAddressBook([])
            }
            setIsLoading(false)

        }).catch(function (error) {
            console.log(error);
            setIsLoading(false)
            SHOW_TOAST_MESSAGE(langContent.something_went_wrong);
        })
}

/**
 * ! Get User Address by address id
 */
export const getUserAddressById = (token, lang, addressId, userId, setIsLoading, langContent, reset) => {
    axiosInstance.post('/get_useraddress_by_address_id',
        {
            lang,
            user_id: userId,
            user_address_id: addressId
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                const address = (data.data[0])
                reset(address)

            }
            else {
                SHOW_TOAST_MESSAGE(langContent.something_went_wrong);
            }
            setIsLoading(false)

        }).catch(function (error) {
            setIsLoading(false)
            SHOW_TOAST_MESSAGE(langContent.something_went_wrong);
        })
}
/**
 * ! This APi will edit the user's address by address Id
 */

export const updateUserAddressbyId = (token, lang, userId, address, setIsLoading, langContent, addressId, history) => {
    axiosInstance.post('/update_user_address',
        {
            user_id: userId,
            address_type_id: address.address_type_id,
            address_email: address.address_email,
            address_line_1: address.address_line_1,
            address_line_2: "",
            address_district_id: address.address_district_id,
            address_city_id: address.address_city_id,
            address_ward: address.address_ward,
            address_country: address.address_country,
            address_phone1: address.address_phone1,
            customer_name: address.customer_name,
            user_address_id: addressId,
            lang
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                SHOW_TOAST_MESSAGE_SUCCESS(langContent.address_updated)
                history.goBack()

            }
            else {
                SHOW_TOAST_MESSAGE(langContent.something_went_wrong);
                history.goBack()
            }
            setIsLoading(false)


        }).catch(function (error) {
            setIsLoading(false)
            SHOW_TOAST_MESSAGE(langContent.something_went_wrong);
        })
}
/**
 *
 * @param {*} token
 * @param {*} lang
 * @param {*} userId
 * @param {*} address
 * @param {*} setIsLoading
 * @param {*} langContent
 * @param {*} addressId
 * ! This removes user address from address book by addressId
 */


export const removeUserAddressById = (token, lang, userId, addressId, setIsLoading, langContent) => {
    axiosInstance.post('/remove_user_address',
        {
            user_id: userId,
            user_address_id: addressId,
            lang
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {

            }
            else {
                SHOW_TOAST_MESSAGE(langContent.something_went_wrong);
            }
            setIsLoading(false)

        }).catch(function (error) {
            setIsLoading(false)
            SHOW_TOAST_MESSAGE(langContent.something_went_wrong);
        })
}
/**
 * ! This API Saves user's shipping address by address ID
 */
export const saveUserShippingAddressById = (addressId, userId, lang, token, history, setIsLoading, langContent) => {
    const fd = new FormData()
    fd.append('user_id', userId)
    fd.append('user_address_id', addressId)
    fd.append('order_id', "")
    fd.append('lang', lang)
    axiosInstance.post('/order_checkout', fd,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                localStorage.setItem('view_orderId', data.detail_order_id)
                localStorage.setItem('orderEnc', data.order_id)
                setIsLoading(false)
                history.push('/order_confirmation')
            }
            else {
                setIsLoading(false)
                SHOW_TOAST_MESSAGE(langContent.please_enter_valid_email)
                history.push('/edit_profile')

            }
        }).catch(function (error) {
            setIsLoading(false)
            SHOW_TOAST_MESSAGE(langContent.something_went_wrong)
        })
}
/***
 * ! This API gives all the blogs
 */
export const getAllBlogs = (lang, langContent, setIsLoading, setBlogList) => {
    axiosInstance.post('/get_allblogs_list', {
        lang
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',

            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setBlogList(data.data)

            }
            else {
                SHOW_TOAST_MESSAGE(langContent.something_went_wrong);
            }
            setIsLoading(false)
        }).catch(function (error) {
            setIsLoading(false)
            SHOW_TOAST_MESSAGE(langContent.something_went_wrong);
        })
}
/**
 * ! This API gives the list of all the user testimonials
 */
export const getAllTestimonials = (lang, setTestimonials) => {
    axiosInstance.post('/get_alltesimonials', {
        lang
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',

            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setTestimonials(data.data)

            }
            else {

            }

        }).catch(function (error) {

        })
}
/**
 * ! This API will delete user's account from db
 */
export const deleteUserAccount = (userId, token, lang) => {
    axiosInstance.post('/deactivate_account', {
        lang,
        user_id: userId
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token

            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                logout()

            }
            else {


            }

        }).catch(function (error) {

        }

        )
}

//deactivate member api
export const activateDeactivateMember = (userId,memberId, token, lang,status,history,langContent,setFetchMemberDetails) => {
    axiosInstance.post('/activate_deactivate_member', {
        lang,
        user_id: userId,
        member_id:memberId,
        status:status
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token

            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                // logout()
                if(status=='1'){
                    SHOW_TOAST_MESSAGE_SUCCESS(langContent.member_activated_msg)
                    setFetchMemberDetails(true)
                }else{
                    SHOW_TOAST_MESSAGE_SUCCESS(langContent.member_deactivated_msg)
                     history.push('/members?view=members')
                }

            }
            else {


            }

        }).catch(function (error) {

        }

        )
}
export const contactUs = (data, setIsLoading, lang, langContent) => {
    axiosInstance.post('/user_contactus',
        {
            name: data.name,
            mobile: data.mobile,
            mail: data.mail,
            description: data.description,
            lang: lang
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',

            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setIsLoading(false)
                SHOW_TOAST_MESSAGE_SUCCESS(langContent.we_will_connect_soon)
            }
            else {
                setIsLoading(false)
                SHOW_TOAST_MESSAGE_SUCCESS(langContent.something_went_wrong)
            }

        }).catch(function (error) {
            setIsLoading(false)
        })
}
export const getClinicDetailsWithFeaturedDoctors = (hospital, setHospitalInfo, setDoctorsList, lang, setIsLoading) => {
    axiosInstance.post('/clinics_with_doctors',
        {
            lang: lang,
            dr_clinic_nice_name: hospital,

        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',

            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                const responseData = data.data
                if (responseData.clinics.doctorsdetails != 'NULL') {
                    setDoctorsList(responseData.clinics.doctorsdetails)
                } else {
                    setDoctorsList([])
                }
                setHospitalInfo(responseData.clinics)
                let hospitalInfo = responseData.clinics
                delete hospitalInfo.doctorsdetails
                hospitalInfo.latitude = hospitalInfo.lat
                hospitalInfo.longitude = hospitalInfo.long
                localStorage.setItem('map_view', JSON.stringify(hospitalInfo))
                setIsLoading(false)
            }
            else {
                setIsLoading(false)

            }

        }).catch(function (error) {
            setIsLoading(false)
        })
}

/**
 * ! This API is to submit review of any appointment or labtest once it's fulfilled
 */
export const submitReview = (fd, token, langContent, setIsLoading) => {


    axiosInstance.post('/user_feedback', fd,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token


            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {

                SHOW_TOAST_MESSAGE_SUCCESS(langContent.thanyou_for_feedback)
            }
            else {

            }
            setIsLoading(false)

        }).catch(function (error) {
            setIsLoading(false)
        })
}

/**
 * ! This API gives info about the labtest/appointment which is to be reviewed
 */
export const getItemInfoForReview = (lang, userId, itemId, itemType, clinicId, orderId, setOverallRating, reset, setIsLoading, setItemInfo, setItemRating, setAppRating) => {

    axiosInstance.post('/users_reviews_info',
        {
            "user_id": userId,
            "lang": lang,
            "item_id": itemId,
            "item_type": itemType,
            "dr_clinic_id": clinicId,
            "order_id": orderId
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',

            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setItemInfo(data.data)
                setOverallRating(data.data.overall_rating)
                setItemRating(data.data.item_rating)
                setAppRating(data.data.app_rating)
                reset(data.data)
            }
            else {

            }
            setIsLoading(false)

        }).catch(function (error) {

        })
}

/**
 * ! This API is to update review of any appointment or labtest once it's fulfilled
 */
export const updateReview = (fd, token, langContent, setIsLoading) => {


    axiosInstance.post('/update_users_reviews_info', fd,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token


            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                logout()
                SHOW_TOAST_MESSAGE_SUCCESS(langContent.thanyou_for_feedback)

            }
            else {

            }
            setIsLoading(false)
        }).catch(function (error) {
            setIsLoading(false)
        })
}
/**
 * ! This API Gives featured DIAG Health Packages
 */
export const getDiagFeaturedPackages = (lang, setDiagPackages) => {


    axiosInstance.post('/features_labs_healthpackages', {
        lang: lang
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'


            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setDiagPackages(data.data)

            }
            else {

            }

        }).catch(function (error) {

        })
}
/**
 * ! This API gives the list of packages of Diag Clinic
 */
export const getDiagPackages = (lang, setHospitalsList, setIsLoading) => {
    axiosInstance.post('/labs_healthpackages',
        { "lang": lang }
    )
        .then(function (response) {
            const hospitalDataList = response.data.Hospitaldata;
            setHospitalsList(hospitalDataList || []);
            setIsLoading(false)
        }).catch(function (error) {
            setIsLoading(false)
        })
}
/**
 * ! This API is to add diag health packages to cart
 */
export const addDiagHealthPackage = (clinicId, lang, labtestId, userId, sample_collect_time, date, memberId, token, history, langContent, setRequestPending,corporateId) => {
    axiosInstance.post('/lab_cart', {
        "dr_clinic_id": clinicId, "lang": lang, "item_id": labtestId, "item_type": "labtest", "user_id": userId, "sample_collect_time": sample_collect_time, "sample_collection_date": date, "member_id": memberId
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                const message = langContent.added_to_cart_successfully
                SHOW_TOAST_MESSAGE_SUCCESS(message)
                history.push('/form1a?memberID='+memberId+'&redirected=yes&next=medical_history')
                setRequestPending(false)
            }
            else {
                setRequestPending(false)
                const message = data.message.replace(/\b\w/g, l => l.toUpperCase())
                SHOW_TOAST_MESSAGE_SUCCESS(message)
            }
        }).catch(function (error) {
            // handle error
            console.log(error);
        })
}
/**
 * ! This API gives the list of district provided the city ID
 */
export const getCityDistricts = (cityId, lang, setDistrictList) => {
    axiosInstance.post('/get_district_citywise', {
        city_id: cityId,
        lang: lang
    }, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    }).then(async function (response) {
        const data = await response.data;
        if (data.status) {
            setDistrictList(data.districtlist);
        } else {
        }
    }).catch(function (error) {
        console.log(error);
    })
}
/**
 * ! This API gives the available slots bases on selected date
 * */
export const getAvailableSlots = (date, lang,clinicId,packageId,corporateId,token, setSlotArray) => {
    axiosInstance.post('/get_available_slots_date_wise', {
        date: date,
        lang: lang,
        clinicId: clinicId,
        packageId: packageId,
        corporateId:corporateId
    }, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': token
        }
    }).then(async function (response) {
        const data = await response.data;
        if (data.status) {
            setSlotArray(data.data);
        } else {
        }
    }).catch(function (error) {
        console.log(error);
    })
}
/**
 * ! Get user's wallet transation
 */

export const getUserWallet = (userId, lang, token, setWalletTransaction, setWalletBalance, setIsLoading) => {
    axiosInstance.post('/get_userwallet_transactions', {
        user_id: userId, lang: lang
    },


        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setWalletTransaction(data.data)
                setWalletBalance(data.wallet_balance.user_balance_amount)
                setIsLoading(false)

            }

            else {
                setIsLoading(false)
            }




        }).catch(function (error) {
            // handle error
            setIsLoading(false)
        })
}

/**
 * ! This API gives wallet balance
 */

export const getUserWalletBalance = (userId, lang, token, setWalletBalance, setIsLoading) => {
    axiosInstance.post('/get_userwallet_transactions', {
        user_id: userId, lang: lang
    },


        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data


            if (data.status) {
                setWalletBalance(data.wallet_balance.user_balance_amount)
                setIsLoading(false)

            }

            else {
                setIsLoading(false)
            }




        }).catch(function (error) {
            // handle error
            setIsLoading(false)
        })
}

/**
 * ! This will be called when order is placed using wallet amount
 */

export const walletPayment = (userId, orderEnc, lang, token, setIsLoading, history, orderViewId) => {
    axiosInstance.post('/walletpayment',
        { "user_id": userId, "order_id": orderEnc, "lang": lang },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                // setWalletBalance(data.walletdata.wallet_remaining_bal)
                setIsLoading(false)
                if (data.data === 0) {
                    history.push({
                        pathname: '/thankyou',
                        search: `?order_id=${data.view_order_id}`,
                    });
                }
                else {
                    window.location.href = `https://www.iqihealth.net/admin/paymentcheckout?order_id=${orderViewId}`
                }
            }
            else {

                setIsLoading(false)
            }

        }).catch(function (error) {
            // handle error
            console.log(error);
        })
}

/**
 * ! THis API Gives the memberslist,packagelist ,clinic info,collection time slot for packages
 */

export const getClinicSlotsPackages = (token, lang, clinicId,corporateId, userMobile, type, setMembersList,setDisableSlots, setHospital, setPackageList, setIsLoading,setAvailableSlots, setResponseError,packageId) => {
    axiosInstance.post('/members_slots_packages',
        {
            feature: 1,
            lang: lang,
            limit: 8,
            offset: 0,
            user_mobile: userMobile,
            dr_clinic_id: clinicId,
            corporate_id: corporateId,
            type: type,
            packageID:packageId
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(async function (response) {
            const data = await response.data;
            if (data.status) {
                setMembersList(data.data.members_data)
                setHospital(data.data?.hospitalList)
                setPackageList(data.data?.test_list)
                setAvailableSlots(data.data?.slots)
                setDisableSlots(data?.disableDates)
            } else {
                setResponseError(true)
            }
            setIsLoading(false)
        }).catch(function (error) {
            setResponseError(true)
            setIsLoading(false)
        })
}
/**
 * ! This API returns the data for home page
 */


export const homePageData = (lang, setDoctorList, setFeaturedPackageList, setFeaturedHospitalsList, setBlogList, setDiagPackages, setIsLoading, setResponseError) => {
    axiosInstance.post('/homepage_api',
        {
            feature: 1,
            lang: lang,
            limit: 8,
            offset: 0,

        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }).then(async function (response) {
            const data = await response.data;
            if (data.status) {
                setDoctorList(data.data?.featured_doctor)
                setFeaturedPackageList(data.data?.labtest_feature)
                setFeaturedHospitalsList(data.data?.feature_data?.hospitals)
                setBlogList(data.data?.blogs_data)
                setDiagPackages(data.data?.labs_data)
            } else {
                setResponseError(true)
            }
            setIsLoading(false)
        }).catch(function (error) {
            setResponseError(true)
            setIsLoading(false)
        })
}
/**
 * ! This APi will return the members list for user with NO LOADING STATE
 */
export const getMembers = (lang, userId, token, setMembersList, userMobile) => {
    axiosInstance.post('/getmemberdata',
        {
            user_mobile: userMobile,
            lang: lang,
            user_id: userId
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                const members = data.memberdata
                setMembersList(members)
            }
            else {
                setMembersList([])
            }

        }).catch(function (error) {

        })
}
export const getRecommendations = (lang, userId, token, setRecommendations, setIsLoading) => {
    axiosInstance.post(`https://www.iqihealth.net/admin/patient_labtest_recommendation?userid=${userId}&lang=${lang}`,
        {

            lang: lang,
            user_id: userId
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                const labtestRecommendations = data.data
                const appointmentRecommendations = data.data_doctor
                setRecommendations({
                    labtestRecommendations: labtestRecommendations,
                    appointmentRecommendations: appointmentRecommendations
                })
                setIsLoading(false)

            }
            else {
                setRecommendations([])
                setIsLoading(false)
            }

        }).catch(function (error) {
            setIsLoading(false)
        })
}

export const getCorporatePackageList = (userId, setCorporatePackageslist, setHospital, setIsLoading,setOrderBookings, setResponseError) => {
    axiosInstance.post('/get_package_list_for_user',
        { "user_id": userId,'package_type':'' }
    )
        .then(function (response) {
            const data = response.data
            // handle success
            if (data.status) {
                setHospital(data.data.clinic_detail[0])
                setCorporatePackageslist(data.data.labtest_data);
                setOrderBookings(data.data.total_bookings);
                setIsLoading(false)
                setResponseError(false)
            } else {
                setCorporatePackageslist([])
                setIsLoading(false)
                setResponseError(false)
            }
        })
        .catch(function (error) {
            // handle error
            setIsLoading(false)
            setResponseError(true)

        })
}

export const getMembersListPagination = (lang, userId, setIsLoading, token, setMembersList, userMobile, page, setHasMore, setLoader) => {
    axiosInstance.post('/getmember_data_pagination',
        {
            user_mobile: userMobile,
            lang: lang,
            user_id: userId,
            page_no: page,
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                const members = data.memberdata
                setMembersList(prevMembers => {
                    return [...new Set([...prevMembers, ...members])]
                })
                setHasMore(members.length > 0)
                setLoader(false)
            }
            else {
                setMembersList([])
                setHasMore(false)
                setLoader(false)
            }
            setIsLoading(false)
        }).catch(function (error) {
            setIsLoading(false)
            setLoader(false)
        })
}

export const getHospitalsSpecializedDoctors = (lang, hospitalId, speciality, setDoctorsList, setServiceCategory, setIsLoading, setHospitalImage) => {
    axiosInstance.post('/get_specializedoctor_list',
        { "lang": lang, "offset": "0", "limit": "8", "dr_clinic_nice_name": hospitalId, "search_value": speciality },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',

            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                const doctors = data.data;
                const category = data.drservice;
                setDoctorsList(doctors)
                setServiceCategory(category)
                setIsLoading(false)
                setHospitalImage(doctors.length ? doctors[0].hospital_image : '')
            } else {
                setDoctorsList([])
                setIsLoading(false)
            }
        }).catch(function (error) {
            setIsLoading(false)
        })
}

export const getMyPrescriptionsPagination = (userId, lang, token, setPrescriptionsList, setIsLoading, page, setHasMore, setLoader) => {
    axiosInstance.post('/myprescription_list_pagination', {
        user_id: userId, lang: lang, page_no: page
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                const cunsultList = data.mdata
                setPrescriptionsList(prevMembers => {
                    return [...new Set([...prevMembers, ...cunsultList])]
                })
                setHasMore(cunsultList.length > 0)
                setIsLoading(false)
                setLoader(false)
            }
            else {
                setPrescriptionsList([])
                setIsLoading(false)
                setHasMore(false)
                setLoader(false)
            }
        }).catch(function (error) {
            setIsLoading(false)
            setHasMore(false)
            setLoader(false)
        })
}



export const getFilteredPackageListPagination = (setPackageList, lang, searchValue, clinicId, orderBy, labServiceId, setIsLoading, page, setHasMore, setLoader, setPackageListLoading) => {
    axiosInstance.post('/healthpackages_by_services_pagination',
        { "lang": lang, "search_key": searchValue, "dr_clinic_id": clinicId, "order_by": orderBy, "lab_service_id": labServiceId, "page_no": page }
    ).then(response => {
        if (response.data.status) {
            const labServicesTypes = response.data
            // setPackageList(labServicesTypes)
            setPackageList(prevMembers => {
                return [...new Set([...prevMembers, ...labServicesTypes.data])]
            })
            setHasMore(labServicesTypes.data.length > 0)
            setLoader(false)
            setIsLoading(false)
            setPackageListLoading(false)
        } else {
            setPackageList([])
            setIsLoading(false)
            setHasMore(false)
            setPackageListLoading(false)
            setLoader(false)
        }
    }).catch(function (error) {
        setIsLoading(false)
        setHasMore(false)
        setLoader(false)
        setPackageListLoading(false)
    })
}

export const getFilteredProductListPagination = (setProductList, lang, setIsLoading, page, setHasMore) => {
    axiosInstance.post('/get_all_products',
        { "lang": lang}
    ).then(response => {
        if (response.data.status) {
            const labServicesTypes = response.data
            // setPackageList(labServicesTypes)
            setProductList(prevMembers => {
                return [...new Set([...prevMembers, ...labServicesTypes.data])]
            })
            setHasMore(labServicesTypes.data.length > 0)
            setIsLoading(false)
        } else {
            setProductList([])
            setIsLoading(false)
            setHasMore(false)
        }
    }).catch(function (error) {
        setIsLoading(false)
        setHasMore(false)
    })
}

export const getProductDetails = (setProductDetail, lang, setIsLoading,productId,setResponseError) => {
    axiosInstance.post('/get_product_details',
        { "lang": lang,productId:productId}
    ).then(response => {
        if (response.data.status) {
            const labServicesTypes = response.data
            // setPackageList(labServicesTypes)
            setProductDetail(labServicesTypes.data)
            setIsLoading(false)
        } else {
            setProductDetail([])
            setIsLoading(false)
        }
    }).catch(function (error) {
        setIsLoading(false)
        setResponseError(true)
    })
}

export const addProductToCartApi = (lang,langContent, setIsLoading,product,userSession,setCartCount,userId) => {
    axiosInstance.post('/add_product_to_cart',
        { "lang": lang,product:product,userSession:userSession,userId:userId}
    ).then(response => {
        if (response.data.status) {
            // setPackageList(labServicesTypes)
            setIsLoading(false)
            SHOW_TOAST_MESSAGE_SUCCESS(langContent.added_to_cart_successfully)
            getCartCount('', setCartCount, true)
        } else {
            setIsLoading(false)
            SHOW_TOAST_MESSAGE(langContent.product_already_in_cart)

        }
    }).catch(function (error) {
        setIsLoading(false)
    })
}

export const getClinicLists = (lang, token, setClinicList, setIsLoading) => {
    axiosInstance.post('/get_clinic_lists',
        { "lang": lang, "token": token }
    ).then(response => {
        if (response.data.status) {
            const labServicesTypes = response.data.data
            setClinicList(labServicesTypes)
            setIsLoading(false)
        } else {
            setClinicList([])
            setIsLoading(false)
        }
    }).catch(function (error) {
        setIsLoading(false)
    })
}

export const getServiceMasterData = (lang, token, setServiceMaster, setIsLoading) => {
    axiosInstance.post('/get_service_master_data',
        { "lang": lang, "token": token }
    ).then(response => {
        if (response.data.status) {
            const labServicesTypes = response.data.data
            setServiceMaster(labServicesTypes)
            setIsLoading(false)
        } else {
            setServiceMaster([])
            setIsLoading(false)
        }
    }).catch(function (error) {
        setIsLoading(false)
    })
}

export const getSharedClinicLists = (lang, token, setSharedClinicList, setIsLoading,historyId) => {
    axiosInstance.post('/get_shared_clinic_lists',
        { "lang": lang, "token": token, "historyId":historyId }
    ).then(response => {
        if (response.data.status) {
            const labServicesTypes = response.data.data
            setSharedClinicList(labServicesTypes)
            setIsLoading(false)
        } else {
            setSharedClinicList([])
            setIsLoading(false)
        }
    }).catch(function (error) {
        setIsLoading(false)
    })
}

export const deleteUserMedicalHistory = (lang, token, setIsLoading,historyId,setDeleteModelOpen,setMemberReport,userId,memberId,langContent) => {
    axiosInstance.post('/delete_user_medical_history',
        { "lang": lang, "token": token, "historyId":historyId }
    ).then(response => {
        if (response.data.status) {
            setIsLoading(false)
            setDeleteModelOpen(false)
            SHOW_TOAST_MESSAGE_SUCCESS(langContent.medical_history_removed)
            getMemberMedicalHistory(userId, memberId, lang, setMemberReport, token)
        } else {
            setIsLoading(false)
            setDeleteModelOpen(false)
            SHOW_TOAST_MESSAGE(langContent.error_occured)
        }
    }).catch(function (error) {
        setIsLoading(false)
        setDeleteModelOpen(false)
    })
}

export const shareUserMedicalHistoryToClinic = (lang, token, selectedClinic ,speciality,comment, historyId , setIsLoading,setOpen,langContent) => {
    axiosInstance.post('/save_user_medical_history_to_clinic',
        { "lang": lang, "token": token, "clinicId": selectedClinic, "historyId": historyId,"speciality":speciality,"comment":comment }
    ).then(response => {
        if (response.data.status) {
            setIsLoading(false)
            SHOW_TOAST_MESSAGE_SUCCESS(langContent?.you_shared_this_medical_history_to_clinic)
            setOpen(false)
        } else {
            setIsLoading(false)
            SHOW_TOAST_MESSAGE("Error occured")
        }
    }).catch(function (error) {
        setIsLoading(false)
    })
}

export const removeUserMedicalHistoryFromClinic = (lang, token, selectedClinic , historyId , setIsLoading,setOpen,langContent) => {
    axiosInstance.post('/remove_user_medical_history_from_clinic',
        { "lang": lang, "token": token, "clinicId": selectedClinic, "historyId": historyId }
    ).then(response => {
        if (response.data.status) {
            setIsLoading(false)
            SHOW_TOAST_MESSAGE_SUCCESS(langContent.medical_history_removed_from_shared_list)
            setOpen(false)
        } else {
            setIsLoading(false)
            SHOW_TOAST_MESSAGE("Error occured")
        }
    }).catch(function (error) {
        setIsLoading(false)
    })
}
export const checkAccountStatus = (lang,userId,token,logout) => {
    axiosInstance.post('/check_account_status',
        { "lang": lang, "token": token, "userId": userId }
    ).then(response => {
        if (response.data.account_status==0) {
            SHOW_TOAST_MESSAGE_SUCCESS("");
            logout()
        } else {
            // SHOW_TOAST_MESSAGE("Error occured")
        }
    }).catch(function (error) {
    })
}

export const changeUserPassword = (lang,userId,token,setIsLoading,langContent,setRequestPending,currentPassword,newPassword) => {
    axiosInstance.post('/change_user_password',
        {
            "lang": lang,
            "token": token,
            "user_id": userId,
            'current_password':currentPassword,
            'new_password':newPassword
     }
    ).then(response => {
        if (response.data.status==true) {
            setIsLoading(false)
            setRequestPending(false)
            SHOW_TOAST_MESSAGE_SUCCESS(langContent.your_password_has_been_changed);
            logout()
        } else {
            setIsLoading(false)
            setRequestPending(false)
            SHOW_TOAST_MESSAGE(langContent.you_have_entered_wrong_current_password);
            // SHOW_TOAST_MESSAGE("Error occured")
        }
    }).catch(function (error) {
    })
}
export const setNewUserPassword = (lang,userId,token,setIsLoading,langContent,setRequestPending,newPassword,history) => {
    axiosInstance.post('/set_new_user_password',
        {
            "lang": lang,
            "token": token,
            "user_id": userId,
            'new_password':newPassword
     }
    ).then(response => {
        if (response.data.status==true) {
            setIsLoading(false)
            setRequestPending(false)
            SHOW_TOAST_MESSAGE_SUCCESS(langContent.your_password_has_been_changed);
            history.push('/myaccount')
        } else {
            setIsLoading(false)
            setRequestPending(false)
            SHOW_TOAST_MESSAGE(langContent.you_have_entered_wrong_current_password);
            // SHOW_TOAST_MESSAGE("Error occured")
        }
    }).catch(function (error) {
    })
}
export const ResetNewPassword = (lang,userId,token,setIsLoading,langContent,setRequestPending,newPassword,history) => {
    axiosInstance.post('/set_new_user_password',
        {
            "lang": lang,
            "token": token,
            "user_id": userId,
            'new_password':newPassword
     }
    ).then(response => {
        if (response.data.status==true) {
            setIsLoading(false)
            setRequestPending(false)
            SHOW_TOAST_MESSAGE_SUCCESS(langContent.your_password_has_been_changed);
            history.push('/login')
        } else {
            setIsLoading(false)
            setRequestPending(false)
            SHOW_TOAST_MESSAGE(langContent.you_have_entered_wrong_current_password);
            // SHOW_TOAST_MESSAGE("Error occured")
        }
    }).catch(function (error) {
    })
}
export const GetCoverImages = (setIsLoading,setCoverImagesDesktop,setCoverImagesMobile) => {
    axiosInstance.post('/get_cover_images',
        {
     }
    ).then(response => {
        if (response.data.status==true) {
            console.log(response.data.desktopImages);
            setIsLoading(false)
            setCoverImagesDesktop(response.data.desktopImages)
            setCoverImagesMobile(response.data.mobileImages)
        } else {
            setIsLoading(false)
            setCoverImagesDesktop([])
            setCoverImagesMobile([])
        }
    }).catch(function (error) {
    })
}
export const checkAppVersion = (setIsLoading, setLatestAppVersion) => {
    axiosInstance.get("/check_app_version").then(response => {
      if (response.data.status === true) {
        // console.log(response.data);
        setIsLoading(false);
        setLatestAppVersion(response.data)
      }
      return response.data.data;
    }).catch(error => {
      console.log(error);
    });
  };
  export const getSurveyQuestions2 = (lang, userID, setSurveyQuestions, setIsLoading,setRequestPending) => {
    setRequestPending(true)
    axiosInstance.post('/get_survey_questions', { form_id: 2, lang: lang, userID: userID })
    .then(response => {
        if (response.data.status === 200) {
             setSurveyQuestions(response.data);
        } else {
            console.warn("Unexpected status code:", response.data.status);
        }
    })
    .catch(error => {
        console.log(error);
    })
    .finally(() => {
        setRequestPending(false)
        setIsLoading(false);
    });
};

export const insertSurveyAnswers = (fd, token, setIsLoading, langContent, history, setRequestPending) => {
    console.warn('Form Data:##########',fd)
    axiosInstance.post('/insert_survey_answers', fd,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            if (data.status) {
                setIsLoading(false)
                SHOW_TOAST_MESSAGE_SUCCESS(data.message)
                setRequestPending(false)
                history.push('/user_survey_completed');
            }
            else {
                setIsLoading(false)
                SHOW_TOAST_MESSAGE(langContent.something_went_wrong)
                setRequestPending(false)
            }
        }).catch(function (error) {
            setIsLoading(false)
            setRequestPending(false)
        })
}

export const SearchUserByMobile = (user_mobile, token, setIsLoading, langContent, history,setUserData) => {
    axiosInstance.post('/search_user_by_phone_no', {
        user_mobile:user_mobile,
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
            const data = response.data
            setUserData(data.data)
            setIsLoading(false)
        }).catch(function (error) {
            setIsLoading(false)
        })
}

export const ShareUserDetails = (userdata, token, setIsLoading, langContent, history,userId,userMember,setIsSahreModalOpen,setPhoneNo,setUserData,setSelectedUser) => {
    axiosInstance.post('/share_user_web_portal', {
        ...userdata,
        userId:userId,
        user_member_id:userMember.member_id
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
           if(response.data.status==false){
            SHOW_TOAST_MESSAGE(response.data.message)
           }else{
            SHOW_TOAST_MESSAGE_SUCCESS(response.data.message)
            setIsSahreModalOpen(false)
            setPhoneNo('')
            setSelectedUser(null)
            setUserData(null)
           }
            setIsLoading(false)
        }).catch(function (error) {
            setIsLoading(false)
        })
}
export const sendOTPForSurveyForm = (token, setIsLoading, langContent, history,userId,setIsModalOpen,setRequestPending) => {
    axiosInstance.post('/send_survey_form_otp', {
        user_id:userId,
    },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': token
            }
        }).then(function (response) {
           if(response.data.status==false){
            SHOW_TOAST_MESSAGE(response.data.message)
           }else{
            SHOW_TOAST_MESSAGE_SUCCESS(response.data.message)
            setIsModalOpen(true)
           }
           setRequestPending(false)
            setIsLoading(false)
        }).catch(function (error) {
            setIsLoading(false)
        })
}
export const verifySurveyFormOTP = (token, setIsLoading, langContent, history,userId,setIsModalOpen,setRequestPending,surveyOTP,setisVerifyingOTP,finalAnswersData) => {
    axiosInstance.post('/verify_survey_form_otp', {
        user_id:userId,
        survey_otp:surveyOTP
    },
    {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': token
        }
    }).then(function (response) {
        if(response.data.status==false){
            SHOW_TOAST_MESSAGE(response.data.message)
        }else if(response.data.status==true){
            SHOW_TOAST_MESSAGE_SUCCESS(response.data.message)
            setIsModalOpen(false)
            insertSurveyAnswers(
                finalAnswersData,
                token,
                setIsLoading,
                langContent,
                history,
                setRequestPending,
            );
        }else{
            SHOW_TOAST_MESSAGE_SUCCESS(response.data.message)
            setIsModalOpen(false) 
        }
        setRequestPending(false)
        setisVerifyingOTP(false)
        setIsLoading(false)
    }).catch(function (error) {
        setIsLoading(false)
    })
}
